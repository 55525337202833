export const SET_DETAILED_INVOICE = '@indigo/searchResults/SET_DETAILED_INVOICE'
export const setDetailedInvoice = (detailedInvoice) => {
  return {
    type: SET_DETAILED_INVOICE,
    payload: {
      detailedInvoice
    }
  }
}

export const CLEAR_DETAILED_INVOICE = '@indigo/searchResults/CLEAR_DETAILED_INVOICE'
export const clearDetailedInvoice = () => {
  return {
    type: CLEAR_DETAILED_INVOICE
  }
}

export const UPDATE_DETAILED_INVOICE = '@indigo/searchResults/UPDATE_DETAILED_INVOICE'
export const updateDetailedInvoice = (property, value) => {
  return {
    type: UPDATE_DETAILED_INVOICE,
    payload: {
      property,
      value
    }
  }
}