/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #f6c018;
`

const XWithCircle = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8.26,16.19a8,8,0,1,1,8-8A8,8,0,0,1,8.26,16.19Zm0-14.63a6.63,6.63,0,1,0,6.62,6.63A6.63,6.63,0,0,0,8.26,1.56Z" transform="translate(-0.26 -0.19)"/>
    <path d="M10.53,11.14a.67.67,0,0,1-.49-.2L5.5,6.4a.68.68,0,0,1,0-1,.7.7,0,0,1,1,0L11,10a.68.68,0,0,1,0,1A.65.65,0,0,1,10.53,11.14Z" transform="translate(-0.26 -0.19)"/>
    <path d="M6,11.14a.67.67,0,0,1-.49-.2.68.68,0,0,1,0-1L10,5.43a.7.7,0,0,1,1,0,.68.68,0,0,1,0,1L6.47,10.94A.65.65,0,0,1,6,11.14Z" transform="translate(-0.26 -0.19)"/>
  </SVG>
)

export default XWithCircle





