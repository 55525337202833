import { apiRequest } from '../shared/apiRequest'
import { fetchStarredCustomers } from './customers.actions'

export const ON_SET_USER = '@indigo/users/ON_REQUEST'
export const ADD_CUSTOMER_TO_FAVORITE = '@indigo/users/ADD_CUSTOMER_TO_FAVORITE'
export const REMOVE_CUSTOMER_FROM_FAVORITE = '@indigo/users/REMOVE_CUSTOMER_FROM_FAVORITE'
export const ON_REQUEST = '@indigo/users/ON_REQUEST'
export const ON_REQUEST_SUCCESS = '@indigo/users/ON_REQUEST_SUCCESS'
export const ON_REQUEST_FAILURE = '@indigo/users/ON_REQUEST_FAILURE'

export const onRequest = () => {
  return {
    type: ON_REQUEST
  }
}

export const onRequestSuccess = () => {
  return {
    type: ON_REQUEST_SUCCESS
  }
}

export const onRequestFailure = () => {
  return {
    type: ON_REQUEST_FAILURE
  }
}

export const onSetUser = user => (dispatch) => {
  
  dispatch( {
    type: ON_SET_USER,
    payload: {
      user
    }
  })
  dispatch(fetchStarredCustomers(user.favorites))
}

export const addCustomerToFavorite = customerId => {
  return {
    type: ADD_CUSTOMER_TO_FAVORITE,
    payload: {
      customerId
    }
  }
}

export const removeCustomerFromFavorite = customerId => {
  return {
    type: REMOVE_CUSTOMER_FROM_FAVORITE,
    payload: {
      customerId
    }
  }
}

export const onAddCustomerToFavorite = customerId => (dispatch, getState) => {
  const state = getState()
  const data = {
    email : state.usersReducer.user.user_id,
    customerId
  }
  dispatch(onRequest)
  return apiRequest('post', 'api/v1/favorites/', data)
    .then(() => {
      dispatch(onRequestSuccess)
      dispatch(addCustomerToFavorite(customerId))
    })
    .catch(() => {
      dispatch(onRequestFailure)
    })
}

export const onRemoveCustomerFromFavorite = customerId => (dispatch, getState) => {
  const state = getState()
  const data = {
    email : state.usersReducer.user.user_id,
    customerId
  }
  dispatch(onRequest)
  return apiRequest('delete', 'api/v1/favorites/', data)
    .then(() => {
      dispatch(onRequestSuccess)
      dispatch(removeCustomerFromFavorite(customerId))
    })
    .catch(() => {
      dispatch(onRequestFailure)
    })
}