import React, { Component } from 'react'
import Switch from 'react-switch'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const StyledSwitch = styled(Switch)`
  div.react-switch-handle {
    height: 12px;
    width: 12px;
    top: 2px;
  }
`

class SimpleSwitch extends Component {
  constructor() {
    super()
    this.state = { checked: false }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(checked) {
    this.props.handleChange()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.active !== prevState.checked) {
      return {
        checked: !prevState.checked
      }
    }

    return null
  }

  render() {
    return (
      <label htmlFor="normal-switch">
        <StyledSwitch
          onChange={this.handleChange}
          checked={this.state.checked}
          id="normal-switch"
          offColor="#cccccc"
          onColor="#f8c013"
          disabled={this.props.isDisabled}
          handleDiameter={12}
          checkedIcon={false}
          uncheckedIcon={false}
          height={16}
          width={32}
        />
      </label>
    )
  }
}

SimpleSwitch.propTypes = {
  handleChange: PropTypes.func
}

export default SimpleSwitch