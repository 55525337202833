import React from 'react'
import { AuthConsumer } from '../AuthContext'

export function Logout (props) {
  props.logout()
  return null
}

export default props => (
  <AuthConsumer>
    {
      ({logout}) => <Logout {...props} logout={logout} />
    }
  </AuthConsumer>
)
