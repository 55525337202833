import {
  TOGGLE_DELIVERY_METHOD_MODAL,
  DISPLAY_DELIVERY_METHOD,
  CHANGE_DELIVERY_METHOD_LOCAL,
  CANCEL_CHANGE_DELIVERY_METHOD_LOCAL
} from '../../../actions/deliveryMethod.action'

export const initialState = {
  isDeliveryMethodModalOpen: false,
  deliveryMethod: '',
  paymentTerms: 0,
  deliveryMethodHasChanged: false

}

export const deliveryMethodReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case TOGGLE_DELIVERY_METHOD_MODAL:
    return {
      ...state,
      isDeliveryMethodModalOpen: !state.isDeliveryMethodModalOpen,
      deliveryMethodHasChanged: false
    }

  case DISPLAY_DELIVERY_METHOD:
    return {
      ...state,
      deliveryMethod: payload.deliveryMethod.Delivery_Method,
      paymentTerms: 15
    }
  case CHANGE_DELIVERY_METHOD_LOCAL:
    return {
      ...state,
      Delivery_Method: payload.value.deliveryMethod,
      deliveryMethodHasChanged: !state.deliveryMethodHasChanged
    }
  case CANCEL_CHANGE_DELIVERY_METHOD_LOCAL:
    return {
      ...state,
      deliveryMethod: state.deliveryMethod === 'Print' ? 'Email': 'Print',
      deliveryMethodHasChanged: !state.deliveryMethodHasChanged
    }
  default:
    return state
  }
}