/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#888'};
`

const CaseStackFilled = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 9.22" color={color}>
    <path d="M3.44,9c0,.08-.06.14-.12.09-.69-.55-2.71-2.43-1-4.23,0,0,1.5-2.19,7.19-1.18A18.78,18.78,0,0,1,16,6.38a.08.08,0,0,1-.05.15C14.31,6.19,8.72,5.06,6.2,5.2,6.2,5.2,2.26,5.39,3.44,9Z" transform="translate(0 -3.39)" /><path d="M12.57,7a.08.08,0,0,1,.12-.09c.67.54,2.73,2.43,1.05,4.24,0,0-1.49,2.19-7.18,1.18A18.7,18.7,0,0,1,0,9.6a.07.07,0,0,1,0-.13c1.57.33,7.2,1.47,9.72,1.33C9.81,10.8,13.77,10.61,12.57,7Z" transform="translate(0 -3.39)" />
  </SVG>
)

CaseStackFilled.propTypes = {
  color: PropTypes.string
}

export default CaseStackFilled


