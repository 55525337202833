import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../../../shared/Typography'
import styled from 'styled-components/macro'
import { components } from 'react-select'
import LabelToTextArea from '../../../shared/LabelToTextArea'
import TextArea from 'react-textarea-autosize'
import Button from '../../../shared/Button'
import FlatButton from '../../../shared/FlatButton'
import PrimaryButton from '../../../shared/PrimaryButton'
import Creatable from 'react-select/lib/Creatable'
import ExitIcon from '../../../../assets/svgs/ExitIcon'
import { selectStyles } from './SelectStyles'
import { Option } from './CustomComponents/CustomOptions'
import Plane from '../../../../assets/svgs/Plane'

const StyledSelect = styled(Creatable)`
  width:357px;
  min-height:38px;
  margin-top:-9px;
  margin-bottom: 5px;
`
const StyledTextArea = styled(TextArea)`
  width: 400px; 
  max-width: 400px;
  min-height: 17px;
  overflow: hidden;
  border: none;
  resize: none;
  margin-top: -2px;
  margin-left: 0px;
  &::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: 0.3px;
    color: #888888;
  }
  
`
const EmailInputWrapper = styled.div`
  display: flex;
  margin-top: 48px;
  width: 398px;
  border-bottom: 1px solid #CBCBCB;
  p{
    margin: 0;
  }

`
const Wrapper = styled.div`
  width: 410px;
  margin-bottom: 40px;
  max-height: 295px;
  overflow-y: auto;
  overflow-x: hidden;
  >div:nth-child(2){
    margin-top: 18px;
  }
  >div:nth-child(3){
    margin-top:16px;
    p{
      min-height: 20px;
    }
    div{
      textarea{
      }
    }
  }
`
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  div {
    float:none;    
    display: flex;  
    &:not(:first-child) {
      margin-left: 32px;
    }
    input.secondary{
      width: 60px;
      margin-right: 32px;
    }

    svg{
      width: 16px;
      height: 16px;
    }
  }
`
const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <ExitIcon color='#888888' />
    </components.MultiValueRemove>
  )
}

const EmailFormView = props => {
  const IndicatorSeparator = () => null
  const DropdownIndicator = () => null
  return (
    <React.Fragment>
      <Typography
        element="h3"
        weight="600px">
        {props.countSelectedInvoices > 0 ?
          `Send ${props.countSelectedInvoices} invoices` :
          'Send 1 invoice'}
      </Typography>
      <Wrapper>

        <EmailInputWrapper>
          <Typography element="p" color="#888888">
            To:
          </Typography>
          <StyledSelect
            defaultValue={props.values}
            onChange={(e) => props.onHandleChange(e, 'to')}
            onInputChange={(e) => props.onHandleInput(e, 'to')}
            options={props.contacts}
            value={props.values.to ? props.values.to : []}
            placeholder=""
            components={{
              DropdownIndicator,
              IndicatorSeparator,
              MultiValueRemove,
              Option
            }}
            isMulti
            menuIsOpen={!!props.isMenuOpen.to}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            styles={selectStyles}
          />
        </EmailInputWrapper>
        <EmailInputWrapper>
          <Typography element="p" color="#888888">
            Cc:
          </Typography>
          <StyledSelect
            defaultValue={props.values}
            onChange={(e) => props.onHandleChange(e, 'cc')}
            onInputChange={(e) => props.onHandleInput(e, 'cc')}
            options={props.contacts}
            value={props.values.cc ? props.values.cc : []}
            placeholder=""
            components={{
              DropdownIndicator,
              IndicatorSeparator,
              MultiValueRemove,
              Option
            }}
            isMulti
            menuIsOpen={!!props.isMenuOpen.cc}
            openMenuOnClick={false}
            openMenuOnFocus={false}
            styles={selectStyles}
          />
        </EmailInputWrapper>
        <div >
          <LabelToTextArea
            textColor="#888888"
            textWeight="regular"
            placeholder="Add a custom message"
            content={props.message}
            isEditing={props.isEditing}
            handleEditing={props.handleEditing}

          >
            <StyledTextArea
              value={props.message}
              minRows={1}
              onChange={props.onHandleTextArea}
              placeholder="Add a custom message"
              autoFocus />
          </LabelToTextArea>
        </div>
      </Wrapper>
      <ButtonsContainer>
        <FlatButton
          className="cancel-button"          
          id="cancel"
          onClick={() => props.toggleCancel()}>
          Cancel
        </FlatButton>
        <PrimaryButton
          disabled={!(props.isEmailValid)}          
          id='Send'
          onClick={() => props.sendEmail()}
          left ={<Plane color="white" />}>          
          Send
        </PrimaryButton>
      </ButtonsContainer>
    </React.Fragment>
  )
}

EmailFormView.propTypes = {
  sendEmail: PropTypes.func,
  isEmailValid: PropTypes.bool,
  toggleCancel: PropTypes.func,
  onHandleTextArea: PropTypes.func,
  message: PropTypes.string,
  handleEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  isMenuOpen: PropTypes.object,
  values: PropTypes.array,
  contacts: PropTypes.array,
  onHandleInput: PropTypes.func,
  onHandleChange: PropTypes.func,
  countSelectedInvoices: PropTypes.number
}

export default EmailFormView