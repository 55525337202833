/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import PropTypes from 'prop-types'
import Card from '../shared/Card'
import Typography from '../shared/Typography'
import BaseLayout from '../../layouts/BaseLayout'
import getMoney from '../../shared/utils/getMoney'
import Placeholder from '../shared/Placeholder'

const HomeView = ({ handleClick, starredCustomers, handleStarClick, isFetching, currentUser }) => {
  return (
    <BaseLayout
      avatarName={currentUser.currentUser && currentUser.currentUser.data.user_name}
      title="Customers"
    >
      {(!isFetching && starredCustomers) ? (
        starredCustomers.length === 0 ?
          (<React.Fragment>
            <Typography
              element="h3">
              Starred
            </Typography>

            <Typography
              element="p"
              color="#888"
              spacing="24px 0 0 0">
              When you star customers, they'll be here for quick access
            </Typography>
          </React.Fragment>) :
          (<React.Fragment>
            <Typography
              element="h3">
              Starred
            </Typography>
            <div className="customer-container">
              {starredCustomers.map(customer => {
                return (
                  <div id="card" key={customer.id} onClick={() => handleClick(customer)}>
                    <Card
                      customer={customer}
                      customerId={customer.Accounting_Code}
                      title={customer.Name}
                      subtitle={`$${getMoney(customer.Amount_Due)} due`}
                      extraInfo={customer.Open_Invoices}
                      handleStarClick={handleStarClick}
                      isStarred={true}
                    />
                  </div>
                )
              })}
            </div>
          </React.Fragment>)
      ) :
        (<Placeholder cardsNumber={4} />)
      }
    </BaseLayout>
  )
}

HomeView.propTypes = {
  handleClick: PropTypes.func,
  starredCustomers: PropTypes.array,
  handleStarClick: PropTypes.func,
  isFetching: PropTypes.bool,
  currentUser: PropTypes.object
}

export default HomeView

{/* Uncomment these lines once we want to show all the customers

<Typography
  element="h3"
  spacing="56px 0 0 0">
  Open & assigned to you
</Typography>

<div className="customer-container">
  {customers.length === 0 ? (<Loader />) : (
    customers.map(customer => {
      return (
        <div id="cardCustomer" key={customer.id} onClick={() => handleClick(customer)}>
          <Card
            customer={customer}
            customerId={customer.Miranda_ID}
            title={customer.name}
            subtitle={`$${getMoney(customer.Amount_Due)} due`}
            extraInfo={customer.Open_Invoices}
            handleStarClick={handleStarClick}
            isStarred={favorites.includes(customer.Miranda_ID)}
          />
        </div>
      )
    })
  )}
</div> 

*/}