import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Container from '../../components/shared/Container'
import NavbarContainer from '../../containers/components/Navbar.container'
import NavBarTitle from '../../components/shared/NavBarTitle'

class BaseLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <NavbarContainer 
          title={<NavBarTitle title={this.props.title}/>}
          avatarName={this.props.avatarName || ''}          
        />
        <Container>
          {this.props.children}
        </Container>
      </React.Fragment>
    )
  }
}

BaseLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  avatarName: PropTypes.string
}

export default BaseLayout
