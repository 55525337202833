import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import SearchResponse from './SearchResponse'
import Typography from '../Typography'

const Wrapper = styled.div`
  margin: 24px 32px 8px 24px;
  &>p{
    margin-left: 9px;
  }
`

const SearchResponseList = ({ searchText,responseList, notifyFocus, cursor, handleSelectItem, notifyMouseLeave, dropdownValue }) => {
  return (
    <Wrapper>
      {responseList && responseList.length > 0 && responseList.map((response, i) => (
        <SearchResponse
          notifyMouseLeave={notifyMouseLeave}
          handleSelectItem={handleSelectItem}
          notifyFocus={notifyFocus}
          response={response}
          key={i}
          pos={i}
          cursor={cursor} 
          dropdownValue={dropdownValue} />
      ))}

      {responseList.length === 0 && searchText.length >= 4 && (
        <Typography element='p'>
          {'No customers found :('}
        </Typography>
      )}

      {dropdownValue !== 'invoice' && searchText.length >= 1 && searchText.length < 4 && (
        <Typography element='p'>
          {'Please type 4 or more characters'}
        </Typography>
      )}
    </Wrapper>
  )
}

SearchResponseList.propTypes = {
  searchText: PropTypes.string,
  responseList: PropTypes.array,
  cursor: PropTypes.number,
  notifyFocus: PropTypes.func,
  handleSelectItem: PropTypes.func,
  notifyMouseLeave: PropTypes.func,
  dropdownValue: PropTypes.string
}

export default SearchResponseList