import {
  SET_DETAILED_INVOICE,
  CLEAR_DETAILED_INVOICE,
  UPDATE_DETAILED_INVOICE
} from '../../../actions/components/searchResults/searchResults.actions'

const defaultState = {
  isNotFoundModalOpen: false,
  detailedInvoice: null,
}

const searchResultsReducer = (state = defaultState, action) => {
  switch (action.type) { 

  case SET_DETAILED_INVOICE: 
    return {
      ...state,
      detailedInvoice: Object.assign(action.payload.detailedInvoice)
    }

  case CLEAR_DETAILED_INVOICE:
    return {
      ...state,
      detailedInvoice: null
    }  
  case UPDATE_DETAILED_INVOICE:
    return {
      ...state,
      detailedInvoice: {
        ...state.detailedInvoice,
        [action.payload.property]: action.payload.value
      }
    }    
  default:
    return state
  }
}

export default searchResultsReducer