import { SET_OPEN_SEARCH_MODAL, SELECT_OPTION_IN_SEARCH_MODAL } from '../actions/route'

export const initialState = {
  openSearchModal: false,
  selectOptionInSearchModal:{}
}

export const routeReducer = (state = initialState, { type, value }) => {
  switch (type) {
  case SET_OPEN_SEARCH_MODAL:
    return {
      ...state,
      openSearchModal: !state.openSearchModal
    }
  case SELECT_OPTION_IN_SEARCH_MODAL:
    return {
      ...state,
      selectOptionInSearchModal: value
    }
  default:
    return state
  }
}
