/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
`

const Account = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,1.37A6.63,6.63,0,1,0,14.63,8h0A6.64,6.64,0,0,0,8,1.37Z" />
    <path d="M8,11H7.86A2.64,2.64,0,0,1,6,10.24a.69.69,0,0,1,.86-1.08A1,1,0,0,1,7,9.31a1.29,1.29,0,0,0,.87.36,1.21,1.21,0,0,0,1-.37.69.69,0,0,1,.94-.25.7.7,0,0,1,.25.95.64.64,0,0,1-.1.13A2.53,2.53,0,0,1,8,11Z" />
    <circle cx="4.75" cy="7.64" r="0.82" /><circle cx="11.25" cy="7.64" r="0.82" />
  </SVG>
)

Account.propTypes = {
  color: PropTypes.string
}

export default Account