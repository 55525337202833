import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchStarredCustomers, setSelectedCustomer } from '../../actions/customers.actions'
import { onAddCustomerToFavorite, onRemoveCustomerFromFavorite } from '../../actions/users.actions'
import { addRemoveStarredCustomer } from '../../actions/customers.actions'
import Home from '../../components/home'
import Snackbar from '../../components/shared/Snackbar'
import { AuthConsumer } from '../../auth/AuthContext'

export class HomeContainer extends Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleStarClick = this.handleStarClick.bind(this)
  }

  componentDidMount() {
    this.props.fetchStarredCustomers(this.props.favorites)
  }

  handleClick(customer) {
    const customerID = customer.Accounting_Code
    this.props.history.push(`customer/${customerID}/invoices`)
  }

  handleStarClick(e, isStarred, customerId, customer) {
    e.stopPropagation()
    const { onAddCustomerToFavorite, onRemoveCustomerFromFavorite, addRemoveStarredCustomer } = this.props
    if (isStarred) {
      onRemoveCustomerFromFavorite(customer.Miranda_ID)
      addRemoveStarredCustomer(customer, 'remove')
    } else {
      onAddCustomerToFavorite(customer.Miranda_ID)
      addRemoveStarredCustomer(customer, 'add')
    }
  }
  render() {
    const { customers,
      isFetching,
      isError,
      favorites,
      starredCustomers
    } = this.props
    return (
      <React.Fragment>
        <Snackbar />
        <Home
          currentUser={this.props.currentUser}
          customers={customers}
          isFetching={isFetching}
          isError={isError}
          handleClick={this.handleClick}
          handleStarClick={this.handleStarClick}
          starredCustomers={starredCustomers}
          favorites={favorites}
        />
      </React.Fragment>
    )
  }
}

HomeContainer.propTypes = {
  setSelectedCustomer: PropTypes.func,
  fetchCustomersByCollector: PropTypes.func,
  customers: PropTypes.array,
  isFetching: PropTypes.bool,
  isError: PropTypes.bool
}

const mapStateToProps = ({ customersReducer, usersReducer }) => {
  return {
    customers: customersReducer.customers,
    starredCustomers: customersReducer.starredCustomers,
    isFetching: customersReducer.isFetching,
    isError: customersReducer.isError,
    favorites: usersReducer.user.favorites
  }
}

const mapDispatchToProps = {
  fetchStarredCustomers,
  setSelectedCustomer,
  onRemoveCustomerFromFavorite,
  onAddCustomerToFavorite,
  addRemoveStarredCustomer
}

HomeContainer.propTypes = {
  fetchStarredCustomers: PropTypes.func,
  addRemoveStarredCustomer: PropTypes.func,
  starredCustomers: PropTypes.array,
  history: PropTypes.object,
  onAddCustomerToFavorite: PropTypes.func,
  onRemoveCustomerFromFavorite: PropTypes.func,
  favorites: PropTypes.array
}

const HomeContainerWithUser = props => (
  <AuthConsumer>
    {currentUser => (
      <HomeContainer {...props} 
        currentUser={currentUser}/>
    )}
  </AuthConsumer>
)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeContainerWithUser))
