import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EmailFormView from './EmailFormView'
import { validateEmail } from '../../../../shared/utils/validateEmail'

class EmailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contacts: [],
      isEmailValid: false,
      optionsSelected: {
        to: [],
        cc: []
      },
      isMenuOpen: {},
      isEditing: false,
      message: ''
    }
    this.onHandleChange = this.onHandleChange.bind(this)
    this.onHandleInput = this.onHandleInput.bind(this)
    this.handleEditing = this.handleEditing.bind(this)
    this.onHandleTextArea = this.onHandleTextArea.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
  }

  componentDidMount() {
    const { contacts } = this.props
    this.setState({
      contacts: contacts.map(contact => {
        if(contact.First_Name == null){
          contact.First_Name = ''
        }
        if(contact.Last_Name == null){
          contact.Last_Name = ''
        }
        return {
          value: contact.Email,
          label: (contact.First_Name === '' && contact.Last_Name === '') ? contact.Email : `${contact.First_Name} ${contact.Last_Name}`,
          name: (contact.First_Name === '' && contact.Last_Name === '') ? contact.Email : `${contact.First_Name} ${contact.Last_Name}`
        }
      })
    })
  }

  checkForInvalidEmail(updatedOptions){
    let isEmailValid = true
    let contactsArray = updatedOptions.optionsSelected.cc.concat(updatedOptions.optionsSelected.to)
    contactsArray.forEach(element => {
      if (!element.isValid) isEmailValid = false
    })
    if(contactsArray.length === 0) isEmailValid = false

    return isEmailValid
  }
  
  sendEmail() {
    const { optionsSelected, message } = this.state

    const data = {
      to: optionsSelected.to.map(contact => contact.email ? contact.email : contact.value),
      cc: optionsSelected.cc ? optionsSelected.cc.map(contact => contact.email ? contact.email : contact.value) : [],
      message: message
    }
    this.props.sendEmail(data)
    this.props.toggleCancel()
  }

  onHandleChange(event, name) {
    const optionsSelected = event.map(element => {
      if (element.__isNew__ === true && !validateEmail(element.value)) {
        element['isValid'] = false
      } else {
        element['isValid'] = true
      }
      return element
    })
    let updatedOptions = {
      optionsSelected: {
        ...this.state.optionsSelected,
        [name]: optionsSelected
      }
    }
    this.setState({
      optionsSelected: {
        ...this.state.optionsSelected,
        [name]: optionsSelected
      },
      isEmailValid: this.checkForInvalidEmail(updatedOptions)
    })
  }

  handleEditing() {
    this.setState({
      isEditing: !this.state.isEditing
    })
  }

  onHandleTextArea(e) {
    this.setState({
      message: e.target.value
    })
  }
  
  onHandleInput(event, name) {
    let isMenuOpen = false
    if (event.length > 0) {
      isMenuOpen = true
    }
    this.setState({ isMenuOpen: { ...this.state.isMenuOpen, [name]: isMenuOpen } })
  }
  
  render() {
    const { message, isEditing, isMenuOpen, contacts, isEmailValid } = this.state
    const { toggleCancel, countSelectedInvoices } = this.props

    return (
      <EmailFormView
        toggleCancel={toggleCancel}
        values={this.state.optionsSelected}
        onHandleChange={this.onHandleChange}
        contacts={contacts}
        isEmailValid={!!isEmailValid}
        isMenuOpen={isMenuOpen}
        onHandleInput={this.onHandleInput}
        message={message}
        onHandleTextArea={this.onHandleTextArea}
        handleEditing={this.handleEditing}
        isEditing={isEditing}
        countSelectedInvoices={countSelectedInvoices}
        sendEmail={this.sendEmail}
      />
    )
  }
}

EmailForm.propTypes = {
  countSelectedInvoices: PropTypes.number,
  sendEmail: PropTypes.func,
  toggleCancel: PropTypes.func,
  contacts: PropTypes.array
}
export default EmailForm