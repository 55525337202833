import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Typography from '../shared/Typography'
import Truck from '../../assets/svgs/Truck'
import Star from '../../assets/svgs/Star'
import StarFilled from '../../assets/svgs/StarFilled'
import Ellipsis from '../../assets/svgs/More'
import Menu from '../shared/NewMenu'
import Tooltip from '../shared/Tooltip'

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 180px;
  min-height: 180px;
  min-width: 288px;
  box-shadow: 0 1px 4px 0 #9b9b9b80;
  border-radius: 4px;
  cursor: pointer;
  
  &:hover {
    h4 {
      text-decoration: underline;
    }

    .menu {
      svg {
        opacity: 1;
      }
    }

  }
`

export const CardDetails = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  word-break: break-all;

  .icon {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: rgb(104, 135, 180, 0.2);
    display: flex;
    justify-content: center;
  }
  svg {
    width: 16px;
    fill: #6887b4;
  }

  h4 {
    margin-top: 16px;
  }
`
const Text = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 8px 0 0 0;
  max-width: calc(288px - 48px);
`
const CardOptions = styled.div`
  position: absolute;
  right: 24px;
  display: flex;
  align-items: center;

  .action-comment {
    margin-right: 2px;
    /* fix for bootstrap tooltip being slightly misaligned */
    /* margin-right: 8px; */
      /* add once menu is needed  */
  }

  svg{
    opacity: ${props => props.starred ? 1 : 0};
    fill: #888;
    margin-right:-2px;
    /* fix for bootstrap tooltip being slightly misaligned */
  }
  .more-button {
    display: none;
    /* remove display none once menu is needed  */

    div div {
      width: 32px;
    }
    svg {
      opacity: 1;
      margin: 0;
    }
    .mainMenu {
      margin-top: 8px;;
      left: auto;
      right: 0;
      width: auto;

      li {
        padding: 0 8px;
        white-space: nowrap;
      }
    }
  }

`

const Card = ({ disableOptions, isStarred, handleStarClick, customer, customerId, title, subtitle, extraInfo }) => {

  return (
    <Wrapper>
      <CardDetails>
        <div className="icon">
          <Truck />
        </div>
        <Text
          element="h4"
        >
          {title}
        </Text>
        <Text
          element="p"
          color="#888"
        >
          {subtitle}
        </Text>
        <Text
          element="span"
          color="#888"
        >
          {extraInfo} open invoices
        </Text>
        <CardOptions className="menu" starred={isStarred}>
          <div onClick={(e) => handleStarClick(e, isStarred, customerId, customer)}>
            <Tooltip toolTipText={isStarred ? "Unstar Customer" : "Star Customer"} >
              <div className='action-comment'>
                {
                  isStarred ?
                    (<StarFilled />) :
                    (<Star />)
                }
              </div>
            </Tooltip>
          </div>
          <div className="more-button">
            <Menu
              options={[
                {
                  id: 1,
                  text: 'Future Option 1'
                },
                {
                  id: 2,
                  text: 'Future Option 2'
                }
              ]}
              disabled={disableOptions}
            >
              <Ellipsis />
            </Menu>
          </div>
        </CardOptions>
      </CardDetails>
    </Wrapper>
  )
}



Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  extraInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableOptions: PropTypes.bool,
  handleStarClick: PropTypes.func,
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStarred: PropTypes.bool


}

export default Card