/* eslint-disable react/display-name */

import React from 'react'
import Menu from '../../shared/NewMenu'
import Typography from '../../shared/Typography'
import Avatar from '../../shared/Avatar'
import More from '../../../assets/svgs/More'
import EditPencil from '../../../assets/svgs/EditPencil'
import Trash from '../../../assets/svgs/Trash'
import styled from 'styled-components'

const NameWrapper = styled.div`
  align-items: center;
  display: flex;
  border-top:none;
  margin-left: 24px;
  align-items: center;
  div {
    margin: 8px 24px 8px 0px;
    h4 {
      width: 100%;
      text-align: center;
    }
  }

  p {
    margin-right: 80px;
  }
`

const EllipsisMenuWrapper = styled.div`
  div.reference {
    position: relative;
    border-top:none;
    width: 32px;
    height: 32px;
    ul.mainMenu {
      position: absolute;
      width: 149px;
      height: 80px;
      margin-top: 8px;
      right: calc(100% - 32px);
      li:nth-child(2){
        svg{
          fill:#d1495b 
        }
      }
    }
  }
`

const handleInitials = (contact) => {
  if (contact) {
    const firstName = contact.First_Name ? contact.First_Name : ''
    const lastName = contact.Last_Name ? contact.Last_Name : ''

    return (firstName.trim() + ' ' + lastName.trim()).trim()
  }
}

const contactsTableColumns = (handleEditContact, handleDeleteContact) => {
  return [
    {
      id: 'Contact_Name',
      label: 'Name',
      isSortable: false,
      className: [],
      hideWhen: null,
      renderItem: (item) => {
        const fullName = handleInitials(item)
        return (
          <NameWrapper>
            <Avatar name={fullName} />
            {fullName.length === 0 ? (
              <Typography element="p" color="#888888">
                no name
              </Typography>
            ) : (
              <Typography element="p" color="#252839">
                {fullName}
              </Typography>
            )}
          
          </NameWrapper>
        )
      }
    },
    {
      id: 'Contact_Email',
      label: 'Email',
      isSortable: false,
      className: [],
      hideWhen: null,
      renderItem: (item) => {
        return (
          <Typography element="p" color="#252839">
            {item.Email}
          </Typography>
        )
      }
    },
    {
      id: 'Menu',
      label: '',
      isSortable: false,
      className: ['ellipsis-menu'],
      hideWhen: null,
      renderItem: (item) => {
        return (
          <EllipsisMenuWrapper>          
            <Menu
              options={[
                {
                  id: 2,
                  text: 'Edit',
                  textColor: '#252839',
                  iconLeft: <EditPencil color='#252839' />,
                  onClick: () => handleEditContact(item)
                },
                {
                  id: 3,
                  text: 'Delete',
                  textColor: '#d1495b',
                  iconLeft: <Trash color="#d1495b" />,
                  onClick: () => handleDeleteContact(item)
                },
              ]}
            >
              <More />
            </Menu>          
          </EllipsisMenuWrapper>
        )
      }
    }
  ]
}

export default contactsTableColumns