/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
`

const PlusCircle = ({ color }) => (
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <title>Untitled-1</title>
    <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,1.37A6.63,6.63,0,1,0,14.63,8,6.64,6.64,0,0,0,8,1.37Z" />
    <path d="M10.74,8.69H5.26a.69.69,0,0,1,0-1.38h5.48a.69.69,0,0,1,0,1.38Z" />
    <path d="M8,11.43a.69.69,0,0,1-.69-.69V5.26a.69.69,0,0,1,1.38,0v5.48A.69.69,0,0,1,8,11.43Z" />
  </SVG>
)

PlusCircle.propTypes = {
  color: PropTypes.string
}

export default PlusCircle