import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Customer from '../../assets/svgs/Customer'
import styled from 'styled-components/macro'
import Typography from '../shared/Typography'
import getInitialLetters from '../../shared/utils/getInitialLetters'

const colors = ['#6887b4', '#c0518b', '#399269', '#C0CA33', '#607D8B', '#0277BD']

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.name.length > 1) ? colors[props.name.charCodeAt(0) % colors.length] : '#e2e2e2'};
  border-radius: 50%;
  height: 40px;
  width: 40px;

  svg {
    width: 16px;
    margin: 0;
    fill: ${props => (!props.name || props.name === '') && '#fff'};
  }
`

class Avatar extends Component {
  render() {    
    const initials = this.props.name ? getInitialLetters(this.props.name) : null

    return (
      <Wrapper name={this.props.name}>
        {initials ? (
          <Typography element="h4" color="white">
            {initials}
          </Typography>
        ) : (
          <Customer />
        )}
        
      </Wrapper>
    )
  }
}

Avatar.propTypes = {
  name: PropTypes.string
}

export default Avatar