import { apiRequest } from '../shared/apiRequest'

export const ON_REQUEST = '@indigo/contacts/ON_REQUEST'
export const ON_REQUEST_SUCCESS = '@indigo/contacts/ON_REQUEST_SUCCESS'
export const ON_REQUEST_FAILURE = '@indigo/contacts/ON_REQUEST_FAILURE'
export const CLEAR_REQUEST_PROCESS = '@indigo/contacts/CLEAR_REQUEST_PROCESS'
export const FETCH_CONTACT = '@indigo/contacts/FETCH_CONTACT'
export const FETCH_CONTACTS = '@indigo/contacts/FETCH_CONTACTS'
export const DISPLAY_CONTACTS = '@indigo/contacts/DISPLAY_CONTACTS'
export const ON_ADD_CONTACT = '@indigo/contacts/ON_ADD_CONTACT'
export const ADD_CONTACT = '@indigo/contacts/ADD_CONTACT'
export const HANDLE_EDIT_CONTACT = '@indigo/contacts/HANDLE_EDIT_CONTACT'
export const EDIT_CONTACT = '@indigo/contacts/EDIT_CONTACT'
export const HANDLE_DELETE_CONTACT = '@indigo/contacts/HANDLE_DELETE_CONTACT'
export const ON_DELETE_CONTACT = '@indigo/contacts/ON_DELETE_CONTACT'
export const DELETE_CONTACT = '@indigo/contacts/DELETE_CONTACT'
export const TOGGLE_DELETE_MODAL = '@indigo/contacts/TOGGLE_DELETE_MODAL'
export const TOGGLE_CONTACT_FORM_MODAL = '@indigo/contacts/TOGGLE_CONTACT_FORM_MODAL'
export const CLEAR_CONTACTS = '@indigo/contacts/CLEAR_CONTACTS'
export const SET_SELECTED_CONTACT = '@indigo/contacts/SET_SELECTED_CONTACT'
export const CLOSE_CONTACT_FORM_MODAL = '@indigo/contacts/CLOSE_CONTACT_FORM_MODAL'
export const CLOSE_DELETE_MODAL = '@indigo/contacts/TOGGLE_DELETE_MODAL'

export const onRequest = () => {
  return {
    type: ON_REQUEST
  }
}

export const onRequestSuccess = (message) => {
  return {
    type: ON_REQUEST_SUCCESS,
    payload: {
      message
    }
  }
}

export const onRequestFailure = () => {
  return {
    type: ON_REQUEST_FAILURE
  }
}

export const clearRequestProcess = () => {
  return {
    type: CLEAR_REQUEST_PROCESS
  }
}

export const clearContacts = () => {
  return {
    type: CLEAR_CONTACTS
  }
}

export const onFetchContacts = () => dispatch => {
  dispatch(onRequest)

  return apiRequest('get', 'api/v1/contacts')
    .then(response => {
      dispatch(displayContacts(response.data))
      dispatch(onRequestSuccess)
    })
    .catch(() => {
      dispatch(onRequestFailure)
    })
}

export const onFetchContactsByCustomer = (customerID) => dispatch => {
  dispatch(clearContacts())
  dispatch(onRequest)

  return apiRequest('get', `api/v1/customers/${customerID}/contacts`)
    .then(response => {
      dispatch(displayContacts(response.data))
      dispatch(onRequestSuccess)
    })
    .catch(() => {
      dispatch(onRequestFailure)
    })
}


export const handleDeleteContact = (contact) => {
  return {
    type: HANDLE_DELETE_CONTACT,
    payload: {
      contact
    }
  }
}



export const displayContacts = (contacts) => {
  return {
    type: DISPLAY_CONTACTS,
    payload: {
      contacts
    }
  }
}
export const closeContactModal = () => {
  return {
    type: CLOSE_CONTACT_FORM_MODAL,
  }
}
export const toggleContactsModal = () => { 
  return {
    type: TOGGLE_CONTACT_FORM_MODAL
  }
}

export const closeDeleteModal = () => {
  return {
    type: CLOSE_DELETE_MODAL,
  }
}

export const toggleDeleteModal = () => {
  return {
    type: TOGGLE_DELETE_MODAL
  }
}

export const handleEditContact = (contact) => {
  return {
    type: HANDLE_EDIT_CONTACT,
    payload: {
      contact
    }
  }
}
