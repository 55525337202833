import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Customer from '../../../../assets/svgs/Customer'
import Email from '../../../../assets/svgs/Email'
import Typography from '../../../shared/Typography'
import { InputWrap } from '../../../shared/InputWrap'
import Button from '../../../shared/Button'
import FlatButton from '../../../shared/FlatButton'
import PrimaryButton from '../../../shared/PrimaryButton'
import './styles.scss'

const CancelButton = styled(FlatButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;  
`

const ContactFormView = ({
  firstName,
  lastName,
  email,
  handleInput,
  isEmailValid,
  contactToEdit, 
  onSave, 
  onEdit, 
  onCancel, 
  wasEdited }) => {
  return (
    <div>
      <div className='field-container'>
        <Customer />

        <Typography element='p'>
          First Name
        </Typography>

        <InputWrap
          onChange={handleInput}
          value={firstName}
          name='firstName'
        />
      </div>
      <div className='field-container'>
        <Customer />

        <Typography element='p'>
          Last Name
        </Typography>

        <InputWrap
          onChange={handleInput}
          value={lastName}
          name='lastName'
        />
      </div>
      <div className='field-container'>
        <Email />

        <Typography element='p'>
          Email *
        </Typography>

        <InputWrap
          onChange={handleInput}
          value={email}
          name='email'
        />
      </div>

      <div className='buttons-container'>
        <PrimaryButton
          disabled={(isEmailValid && wasEdited) ? (false) : (true)}          
          id='save'
          onClick={contactToEdit ? onEdit : onSave}>
          Save
        </PrimaryButton>


        <CancelButton         
          id='cancel'
          onClick={onCancel}
        >
        Cancel
        </CancelButton>
      </div>
    </div>
  )
}

ContactFormView.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  handleInput: PropTypes.func,
  isEmailValid: PropTypes.bool,
  contactToEdit: PropTypes.string,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  wasEdited: PropTypes.bool
}

export default ContactFormView 