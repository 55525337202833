import {
  ON_REQUEST,
  ON_REQUEST_FAILURE,
  ON_REQUEST_SUCCESS,
  CLEAR_REQUEST_PROCESS,
  DISPLAY_CONTACTS,
  TOGGLE_CONTACT_FORM_MODAL,
  ADD_CONTACT,
  HANDLE_EDIT_CONTACT,
  HANDLE_DELETE_CONTACT,
  TOGGLE_DELETE_MODAL,
  DELETE_CONTACT,
  EDIT_CONTACT,
  CLEAR_CONTACTS,
  CLOSE_CONTACT_FORM_MODAL,
  CLOSE_DELETE_MODAL
} from '../actions/contacts.actions'

const initialState = {
  contacts: [],
  contactSelected: null,
  onRequest: false,
  isError: false,
  isSuccess: false,
  statusMessage: null,
  isDeleteModalOpen: false,
  isModalOpen: false
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case ON_REQUEST:
    return {
      ...state,
      onRequest: true
    }

  case ON_REQUEST_FAILURE:
    return {
      ...state,
      onRequest: false,
      isError: true,
      isSuccess: false
    }
  case CLOSE_CONTACT_FORM_MODAL: 
    return {
      ...state,
      isModalOpen: false,
      contactSelected: null
    }
  case ON_REQUEST_SUCCESS:
    return {
      ...state,
      onRequest: false,
      isError: false,
      isSuccess: true,
      statusMessage: action.payload.message
    }
  
  case CLEAR_REQUEST_PROCESS:
    return {
      ...state,
      onRequest: false,
      isError: false,
      isSuccess: false,
      contactSelected: null
    }

  case ADD_CONTACT:  
    return {
      ...state,
      contacts: [...state.contacts, action.payload.contact]
    }  
  
  case EDIT_CONTACT:  
    return {
      ...state,
      contacts: state.contacts.map(contact => {
        if (contact._id === action.payload.contact._id) {
          contact = action.payload.contact
        }
        
        return contact
      })
    }  
  
  case DISPLAY_CONTACTS:  
    return {
      ...state,
      contacts: [...action.payload.contacts.sort((a, b) => {
        if(a.First_Name === ''|| a.First_Name == null) return 1
        if(b.First_Name === ''|| b.First_Name == null) return -1
        if(a.First_Name < b.First_Name) return -1
        if(a.First_Name > b.First_Name) return 1
        return 0
      })],
      onRequest: false,
      isError: false
    }  
  
  case TOGGLE_CONTACT_FORM_MODAL:
    return {
      ...state,
      isModalOpen: !state.isModalOpen,
      contactSelected: null
    }
  
  case CLOSE_DELETE_MODAL:
    return {
      ...state,
      isDeleteModalOpen: false,
      contactSelected: null
    }

  case TOGGLE_DELETE_MODAL:
    return {
      ...state,
      isDeleteModalOpen: !state.isDeleteModalOpen,
      contactSelected: null
    }  
   
  case HANDLE_EDIT_CONTACT: 
    return {
      ...state,
      isModalOpen: !state.isModalOpen,
      contactSelected: action.payload.contact
    } 
  case HANDLE_DELETE_CONTACT: 
    return {
      ...state,
      isDeleteModalOpen: !state.isDeleteModalOpen,
      contactSelected: action.payload.contact
    }
  case DELETE_CONTACT:       
    return {
      ...state,
      contacts: state.contacts.filter(contact => contact._id !== action.payload.id),
      isDeleteModalOpen: !state.isDeleteModalOpen,
      contactSelected: null
    }
  case CLEAR_CONTACTS:
    return {
      ...state,
      contacts: []
    }
  default:
    return state
  }
}

