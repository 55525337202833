/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
  ${props => props.orientation === 'left' && 'transform: rotate(90deg);'}
  ${props => props.orientation === 'up' && 'transform: rotate(180deg);'}
  ${props => props.orientation === 'right' && 'transform: rotate(270deg);'}
`

const ArrowDownward = (props) => (
  <SVG orientation={props.orientation} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.99 8.02"><path d="M8,8a1.25,1.25,0,0,1-.84-.33L.24,1.32A.81.81,0,0,1,.16.26.66.66,0,0,1,1.07.1l.09.08L8,6.54,14.83.18a.65.65,0,0,1,.92,0,.34.34,0,0,1,.08.09.81.81,0,0,1-.08,1.06L8.84,7.67A1.25,1.25,0,0,1,8,8Z" transform="translate(0 0.02)" /></SVG>
)

ArrowDownward.propTypes = {
  orientation: PropTypes.string
}

export default ArrowDownward


