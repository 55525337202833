/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const LoadingNow = () => (
  <SVG className="loading-now" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,1.37A6.63,6.63,0,1,0,14.63,8,6.64,6.64,0,0,0,8,1.37Z" transform="translate(0 0)"/>
    <path d="M8.78,2.84a5.08,5.08,0,0,1,2.87,8.57.22.22,0,0,1-.31,0L8.07,8.13A.2.2,0,0,1,8,8V3.06a.3.3,0,0,1,.11-.23.3.3,0,0,1,.16,0Z" transform="translate(0 0)"/>
  </SVG>
)

export default LoadingNow