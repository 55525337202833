import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
  position: relative;
  top: 5px;
`

const More = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" id="Layer_1" viewBox="0 0 16 16" color={color}>
    <ellipse cx="1.98" cy="2"  rx="1.98" ry="1.99"/>
    <ellipse cx="8" cy="2"  rx="1.99" ry="2"/>
    <ellipse cx="14.01" cy="2"  rx="1.99" ry="2"/>
  </SVG>
)

More.propTypes = {
  color: PropTypes.string
}  

export default More