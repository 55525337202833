/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
`

const Print = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.05 16" color={color}>
    <path className="cls-1" d="M14.59,9.92H1.41V1.2A1.21,1.21,0,0,1,2.62,0H10a1.26,1.26,0,0,1,.82.32L11.55,1l2.69,2.6a1.21,1.21,0,0,1,.37.86ZM2.78,8.55H13.22v-4L10.58,2,9.91,1.35H2.78v7.2Z" transform="translate(0 0)" />
    <path className="cls-1" d="M13.38,16H2.62a1.21,1.21,0,0,1-1.21-1.2V12.48H14.59V14.8A1.21,1.21,0,0,1,13.38,16ZM2.78,14.63H13.22V13.85H2.78v0.78Z" transform="translate(0 0)" />
    <path className="cls-1" d="M15.16,13.85H0.83A0.83,0.83,0,0,1,0,13V9.38A0.83,0.83,0,0,1,.83,8.55H16l0,0.73V13a0.84,0.84,0,0,1-.83.85H15.16ZM1.37,12.48H14.63V9.92H1.37v2.56Z" transform="translate(0 0)" />
    <path className="cls-1" d="M13.91,5.32H10.22A1.07,1.07,0,0,1,9.15,4.25h0V0.69A0.68,0.68,0,0,1,9.82,0h0a0.69,0.69,0,0,1,.69.69V3.94h3.39A0.69,0.69,0,1,1,13.91,5.32Z" transform="translate(0 0)" />
    <line className="cls-2" x1="9.91" y1="6.87" x2="6.08" y2="6.87" />
    <path className="cls-1" d="M9.91,7.56H6.08A0.68,0.68,0,0,1,5.4,6.88h0a0.67,0.67,0,0,1,.66-0.68H9.91a0.68,0.68,0,0,1,.69.67h0A0.69,0.69,0,0,1,9.91,7.56Z" transform="translate(0 0)" />
  </SVG >
)

Print.propTypes = {
  color: PropTypes.string
}

export default Print