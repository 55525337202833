import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AutoDeliveryView from '../../../../components/customer/autoDelivery/AutoDeliveryView'

import {
  onFetchDeliveryMethod,
  onUpdateDeliveryMethod,
  changeDeliveryMethodLocal,
  onCancelDeliveryMethodChange
} from '../../../../actions/deliveryMethod.action'

class AutoDelivery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasChanged: false
    }
    this.hasChanged = this.hasChanged.bind(this)
  }

  async componentDidMount() {
    const { customer, onFetchDeliveryMethod } = this.props
    onFetchDeliveryMethod()
    if (customer.Delivery_Method === 'Email' && customer.Customer_Emails.length === 0) {
      this.props.changeDeliveryMethodLocal('Print')
      this.props.onUpdateDeliveryMethod(true)
    }
  }

  hasChanged() {
    this.setState({ hasChanged: (this.state.hasChanged === this.props.customer.Delivery_Method) ? (false) : (true) })
  }

  render() {
    const {
      customer,
      deliveryMethod,
      paymentTerms,
      changeDeliveryMethodLocal,
      onUpdateDeliveryMethod,
      deliveryMethodHasChanged,
      contacts,
      onCancelDeliveryMethodChange
    } = this.props
    return (
      <React.Fragment>
        <AutoDeliveryView
          hasChanged={this.hasChanged}
          hasChangedState={this.state.hasChanged}
          handleClose={onCancelDeliveryMethodChange}
          deliveryMethod={deliveryMethod}
          paymentTerms={paymentTerms}
          changeDeliveryMethodLocal={changeDeliveryMethodLocal}
          onUpdateDeliveryMethod={onUpdateDeliveryMethod}
          deliveryMethodHasChanged={deliveryMethodHasChanged}
          contacts={contacts}
          customer={customer}
        />
      </React.Fragment>
    )
  }
}

AutoDelivery.propTypes = {
  customer: PropTypes.object,
  onCancelDeliveryMethodChange: PropTypes.func,
  fetchDeliveryMethod: PropTypes.func,
  customerId: PropTypes.string,
  deliveryMethod: PropTypes.string,
  paymentTerms: PropTypes.number,
  changeDeliveryMethodLocal: PropTypes.func,
  onUpdateDeliveryMethod: PropTypes.func,
  deliveryMethodHasChanged: PropTypes.bool,
  contacts: PropTypes.array
}

const mapStateToProps = ({ customersReducer, deliveryMethodReducer, contactsReducer }) => ({
  deliveryMethod: deliveryMethodReducer.deliveryMethod,
  paymentTerms: deliveryMethodReducer.paymentTerms,
  deliveryMethodHasChanged: deliveryMethodReducer.deliveryMethodHasChanged,
  contacts: contactsReducer.contacts,
  customer: customersReducer.selectedCustomer
})

const mapDispatchProps = dispatch => ({
  onFetchDeliveryMethod: (customerId) => dispatch(onFetchDeliveryMethod(customerId)),
  onUpdateDeliveryMethod: (value) => dispatch(onUpdateDeliveryMethod(value)),
  changeDeliveryMethodLocal: (deliveryMethod) => dispatch(changeDeliveryMethodLocal(deliveryMethod)),
  onCancelDeliveryMethodChange: () => dispatch(onCancelDeliveryMethodChange())
})


export default connect(
  mapStateToProps,
  mapDispatchProps
)(AutoDelivery)


