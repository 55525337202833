import React from 'react'
import Invoice from '../../assets/svgs/Invoice'
import Settings from '../../assets/svgs/Settings'
import ArrowDownward from '../../assets/svgs/ArrowDownward'
import Customer from '../../assets/svgs/Customer'
import XWithCircle from '../../assets/svgs/XWithCircle'
import LoadingNow from '../../assets/svgs/LoadingNow'
import Money from '../../assets/svgs/Money'
import Notes from '../../assets/svgs/Notes'

const Icons = {
  'Invoice': <Invoice />,
  'Settings': <Settings />,
  'Customer': <Customer />,
  'ArrowBack': <ArrowDownward />,
  'XWithCircle': <XWithCircle />,
  'LoadingNow': <LoadingNow />,
  'Money': <Money />,
  'Notes': <Notes />,
}

export default Icons