import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { onFetchCustomer } from '../../../actions/customers.actions'
import { setSelectedCustomer, addRemoveStarredCustomer } from '../../../actions/customers.actions'
import { toggleDeliveryMethodModal } from '../../../actions/deliveryMethod.action'
import { setSelectedCustomer as selectCustomer } from '../../../actions/app.actions'
import {
  getInvoices,
  setFilter,
  setDetailedInvoice,
  clearDetailedInvoice,
  setSelectAllInvoices,
  setSelectOneInvoice,
  setInvoiceSelected,
  toggleCustomEmailModal,
  handleOrderInvoices,
  changeDisputingStatus,
  handleDisputing,
  clearCustomerDetails,
} from '../../../actions/components/customer/invoices'
import { updateDetailedInvoice } from '../../../actions/components/customer/customerDetails'
import { onAddCustomerToFavorite, onRemoveCustomerFromFavorite } from '../../../actions/users.actions'
import {
  handleSendEmail as sendEmail,
  sendToPrintQueue,
  handleDownloadDocument
} from './../../../actions/app.actions'
import { FILTER_BY, STATUS } from '../../../shared/constants/filters'
import CustomerDetailsView from '../../../components/customer/details/CustomerDetailsView'
import { AuthConsumer } from '../../../auth/AuthContext'
import { apiRequest } from '../../../shared/apiRequest'

class CustomerDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sort: false,
      currentUser: null,
      redirect: false
    }
    this.handleInfiniteScroll = this.handleInfiniteScroll.bind(this)
    this.countSelectedInvoices = this.countSelectedInvoices.bind(this)
    this.sendEmail = this.sendEmail.bind(this)
    this.sendToPrintQueue = this.sendToPrintQueue.bind(this)
    this.handleLeftMenu = this.handleLeftMenu.bind(this)
    this.handleStarClick = this.handleStarClick.bind(this)
    this.handleReturn = this.handleReturn.bind(this)
    this.changeDisputingStatus = this.changeDisputingStatus.bind(this)
    this.handleOrderInvoices = this.handleOrderInvoices.bind(this)

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.currentUser !== nextProps.currentUser) {
      return {
        currentUser: (nextProps.currentUser)
      }
    }
    return null
  }

  async componentDidMount() {
    const customerID = this.props.match.params.idcustomer

    try {
      let customer = await apiRequest('POST', 'api/v1/customers/get-by-key', {
        key: 'Accounting_Code',
        value: customerID
      })
        .then(response => response.data)

      
      this.props.setSelectedCustomer(customer)
    
      this.props.setFilter(FILTER_BY.STATUS, STATUS.OPEN)
      this.props.getInvoices(customerID, false, true)
      
    } catch (error) {
      if (error.response.status === 404) {
        this.setState({
          redirect: true
        })        
      }
    }
  }

  componentWillUnmount() {
    this.props.clearCustomerDetails()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer && prevProps.customer.Accounting_Code !== this.props.customer.Accounting_Code) {
      this.props.getInvoices(this.props.customer.Accounting_Code, false, true)
    }
    if (prevProps.filterValue !== this.props.filterValue) {
      const { invoices, getInvoices } = this.props
      if (invoices && !invoices.totalInvoices) {
        getInvoices(this.props.customer.Accounting_Code)
      }
    }
  }

  changeDisputingStatus(isDisputing) {
    this.props.handleDisputing(isDisputing)
    this.props.updateDetailedInvoice('Disputing', isDisputing)
  }

  handleStarClick(e, isStarred, customer) {
    e.stopPropagation()
    const { onAddCustomerToFavorite, onRemoveCustomerFromFavorite } = this.props
    if (isStarred) {
      onRemoveCustomerFromFavorite(customer.Miranda_ID)
      addRemoveStarredCustomer(customer, 'remove')
    } else {
      onAddCustomerToFavorite(customer.Miranda_ID)
      addRemoveStarredCustomer(customer, 'add')
    }
  }

  countInvoices() {
    const { filterBy, filterValue, invoices } = this.props
  
    if (filterBy !== FILTER_BY.CUSTOMER && filterValue && filterValue !== 'none' ) {
      return invoices.totalInvoices
    }

    return 0
  }

  handleReturn() {
    this.props.clearDetailedInvoice()
    this.props.clearCustomerDetails()
  }

  handleLeftMenu(filterBy, filterValue) {
    this.props.setFilter(filterBy, filterValue)
  }

  handleInfiniteScroll(order) {   
    const customerID = this.props.match.params.idcustomer
    const { getInvoices } = this.props
    getInvoices(customerID, order)
  }

  countSelectedInvoices() {
    const { invoices, filterValue } = this.props
    return invoices.data.filter(filteredInvoice => filteredInvoice.checked).length
  }



  sendEmail(data) {
    if (typeof (this.state.currentUser.currentUser) === 'object') {
      this.props.sendEmail(this.state.currentUser.currentUser.data.user_id, data)
    }
  }

  sendToPrintQueue(data) {
    if (typeof (this.state.currentUser.currentUser) === 'object') {
      this.props.sendToPrintQueue(this.state.currentUser.currentUser.data.user_id, data)
    }
  }

  handleOrderInvoices(order, field) {
    this.props.handleOrderInvoices(order, field)
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/404" />
    }

    return (
      <div>
        <CustomerDetailsView
          onRequest={this.props.onRequest}
          currentUser={this.props.currentUser}
          customer={this.props.customer}
          invoices={this.props.invoices}
          detailedInvoice={this.props.detailedInvoice}
          setDetailedInvoice={this.props.setDetailedInvoice}
          clearDetailedInvoice={this.props.clearDetailedInvoice}
          favorites={this.props.favorites}
          toggleDeliveryMethodModal={this.props.toggleDeliveryMethodModal}
          isDeliveryMethodModalOpen={this.props.isDeliveryMethodModalOpen}
          handleLeftMenu={this.handleLeftMenu}
          invoicesCounter={this.countInvoices()}
          selectedCustomer={this.props.selectedCustomer}
          clearCustomerDetails={this.handleReturn}
          handleStarClick={this.handleStarClick}
          currentURL={this.props.match.url}
          filterBy={this.props.filterBy}
          filterValue={this.props.filterValue}
          changeDisputingStatus={this.changeDisputingStatus}
          setSelectAllInvoices={this.props.setSelectAllInvoices}
          setSelectOneInvoice={this.props.setSelectOneInvoice}
          invoiceSelected={this.props.invoiceSelected}
          setInvoiceSelected={this.props.setInvoiceSelected}
          tableLength={this.props.tableLength}
          handleInfiniteScroll={this.handleInfiniteScroll}
          isCustomEmailModalOpen={this.props.isCustomEmailModalOpen}
          toggleCustomEmailModal={this.props.toggleCustomEmailModal}
          handleDownloadDocument={this.props.handleDownloadDocument}
          sendEmail={this.sendEmail}
          sendToPrintQueue={this.sendToPrintQueue}
          countSelectedInvoices={this.countSelectedInvoices}
          handleOrderInvoices={this.handleOrderInvoices}
          sort={this.state.sort}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const defaultFilter = state.invoicesReducer.filterValue || STATUS.OPEN
  return {
    onRequest: state.invoicesReducer.onRequest, 
    customer: state.customersReducer.selectedCustomer,
    invoices: state.invoicesReducer.invoices[defaultFilter],
    detailedInvoice: state.invoicesReducer.detailedInvoice,
    favorites: state.usersReducer.user.favorites,
    filterValue: defaultFilter,
    filterBy: state.invoicesReducer.filterBy,
    tableLength: state.invoicesReducer.tableLength,
    invoiceSelected: state.invoicesReducer.invoiceSelected,
    isCustomEmailModalOpen: state.invoicesReducer.isCustomEmailModalOpen,
    selectedCustomer: state.customersReducer.selectedCustomer,
    isDeliveryMethodModalOpen: state.deliveryMethodReducer.isDeliveryMethodModalOpen,
  }
}


const mapDispatchToProps = (dispatch) => ({
  onFetchCustomer: (customerID) => dispatch(onFetchCustomer(customerID)),
  setFilter: (filterBy, filterValue) => dispatch(setFilter(filterBy, filterValue)),
  getInvoices: (customerID, order, replace) => dispatch(getInvoices(customerID, order, replace)),
  setDetailedInvoice: (invoiceID) => dispatch(setDetailedInvoice(invoiceID)),
  clearDetailedInvoice: () => dispatch(clearDetailedInvoice()),
  changeDisputingStatus: (invoiceNumber) => dispatch(changeDisputingStatus(invoiceNumber)),
  updateDetailedInvoice: (property, isDisputing) => dispatch(updateDetailedInvoice(property, isDisputing)),
  selectCustomer: (customer) => dispatch(selectCustomer(customer)),
  setSelectedCustomer: (id) => dispatch(setSelectedCustomer(id)),
  toggleDeliveryMethodModal: () => dispatch(toggleDeliveryMethodModal()),
  clearCustomerDetails: () => dispatch(clearCustomerDetails()),
  onRemoveCustomerFromFavorite: (customerID) => dispatch(onRemoveCustomerFromFavorite(customerID)),
  onAddCustomerToFavorite: (customerID) => dispatch(onAddCustomerToFavorite(customerID)),
  handleDisputing: (isDisputing) => dispatch(handleDisputing(isDisputing)),
  addRemoveStarredCustomer: (customer, addRemove) => dispatch(customer, addRemove),
  setSelectOneInvoice: (item) => dispatch(setSelectOneInvoice(item)),
  setSelectAllInvoices: (isSelectAll) => dispatch(setSelectAllInvoices(isSelectAll)),
  setInvoiceSelected: (invoice) => dispatch(setInvoiceSelected(invoice)),
  toggleCustomEmailModal: () => dispatch(toggleCustomEmailModal()),
  sendEmail: (email, inData) => dispatch(sendEmail(email, inData)),
  sendToPrintQueue: () => dispatch(sendToPrintQueue()), 
  handleDownloadDocument: () => dispatch(handleDownloadDocument()),
  handleOrderInvoices: (order, field) => dispatch(handleOrderInvoices(order, field)),
})

const CustomerDetailsWithUser = props => (
  <AuthConsumer>
    {currentUser => (
      <CustomerDetails {...props} 
        currentUser={currentUser}/>
    )}
  </AuthConsumer>
)

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsWithUser)