import React from 'react'
import PropTypes from 'prop-types'

import Typography from './Typography'
import Button from './Button'
import styled from 'styled-components'

const PwhiteSpace = styled(Typography)`
  white-space: pre-wrap;
`
const InputNotesWrapper = styled.div`
  width: 100%;
  width: -moz-available;          
  width: -webkit-fill-available;
  width: fill-available;

  .secondary {
      width: min-content;
      margin-left: auto;

      &:disabled {
          cursor: no-drop;
      }
  }

  .input-container {
      textarea {            
          padding: 8px 8px 6px 8px;          
      }   
  }

`

const AddNotesButton = styled(Button)`
  width: 100px;
`

const LabelToTextArea = ({
  content,
  isEditing,
  handleEditing,
  handleSave,
  needSaveButton,
  icon,
  textColor,
  textWeight,
  placeholder,
  children,
  canBeSaved,
  className
}) => {
  return (
    <React.Fragment>
      {!icon ? icon : null}
      {!isEditing ? (
        !content ? (
          <Typography
            element="p"
            color={textColor}
            weight={textWeight}
            onClick={() => handleEditing()}>
            {placeholder}
          </Typography>
        ) : (<PwhiteSpace onClick={() => handleEditing()}>
          {content}
        </PwhiteSpace>)) : (
          <InputNotesWrapper>
            {children}
            {needSaveButton &&
              <AddNotesButton
                type="button"
                name="DONE"
                onClick={handleSave}
                value="DONE"
                btnType="secondary"
                className = {className}
                //disabled={!canBeSaved}
              />
          }
        </InputNotesWrapper>
      )}
    </React.Fragment>
  )
}
LabelToTextArea.propTypes = {
  isEditing: PropTypes.bool,
  handleEditing: PropTypes.func,
  handleSave: PropTypes.func,
  content: PropTypes.string,
  textColor: PropTypes.string,
  textWeight: PropTypes.string,
  placeholder: PropTypes.string,
  needSaveButton: PropTypes.bool
}
export default LabelToTextArea