import { FILTER_BY, STATUS } from '../../../shared/constants/filters'

function leftMenu(filterInvoices, toggleDeliveryMethodModal, onRequest){
  return[
    {
      title: 'INVOICES',
      items: [
        {
          id: 1,
          label: 'Open',
          filterBy: FILTER_BY.STATUS,
          filterValue: STATUS.OPEN,
          icon: 'LoadingNow',//icon should reference  src/assets/svgs/LoadingNow.jsx
          redirectTo: 'invoices',
          colorOnActive: 'rgba(209, 73, 91, 0.2)',
          colorOnHover: 'rgba(209, 73, 91, 0.2)',
          action: (filterBy, filterValue) => !onRequest ? filterInvoices(filterBy, filterValue) : null
        },
        {
          id: 2,
          label: 'Closed',
          filterBy: FILTER_BY.STATUS,
          filterValue: STATUS.CLOSED,
          icon: 'Money',//icon should reference  src/assets/svgs/Money.jsx
          redirectTo: 'invoices',
          colorOnActive: 'rgb(226, 226, 226)',
          colorOnHover: 'rgba(226, 226, 226, 0.5)',
          action: (filterBy, filterValue) => !onRequest ? filterInvoices(filterBy, filterValue) : null
        },
        {
          id: 3,
          label: 'All',
          filterBy: FILTER_BY.STATUS,
          filterValue: STATUS.ALL,
          icon: 'Notes',//icon should reference  src/assets/svgs/Notes.jsx
          redirectTo: 'invoices',
          colorOnActive: 'rgb(226, 226, 226)',
          colorOnHover: 'rgba(226, 226, 226, 0.5)',
          action: (filterBy, filterValue) => !onRequest ? filterInvoices(filterBy, filterValue) : null
        }
      ]
    },
    {
      title: 'SETTINGS',
      items: [
        {
          id: 4,
          label: 'Contacts',
          icon: 'Customer',
          redirectTo: 'contacts',
          filterValue: 'none',
          colorOnActive: 'rgb(226, 226, 226)',
          colorOnHover: 'rgba(226, 226, 226, 0.5)',
          action: (filterBy = '', filterValue = 0) => filterInvoices(filterBy, filterValue)
        },
        {
          id: 5,
          label: 'Invoicing',
          icon: 'Settings',                    
          colorOnHover: 'rgba(226, 226, 226, 0.5)',
          action: () => toggleDeliveryMethodModal()
        }
      ]
    }
  ]
}

export default leftMenu