
export const selectStyles = {

  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    width: '354px'
  }),
  clearIndicator: (base) => ({
    ...base,
    display: 'none'
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: '#FFF3D1',
    borderRadius: '12px',
  }),
  multiValueLabel: (styles, { data }) => {
    return {
      height: 'auto',
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.43',
      letterSpacing: '0.3px',
      color: data.isValid ? '#252839' : '#d1495b',
      padding: '1px 8px 2px 12px',
      wordBreak: 'break-all'
    }
  },
  multiValueRemove: () => ({
    marginRight: '12px',
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      cursor: 'pointer'
    },
    'svg': {
      height: '8px',
      width: '8px',
    }
  }),
  menu: (provided) => {
    return {
      ...provided,
      left: '10px',
      width: '372px',
      top: 'calc(100% - 7.5px)',
    }
  },
  menuList: () => ({
    border: 'none',
    overflowY: 'auto',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.3)',
    width: '372px',
    overflowX: 'hidden',
    position: 'fixed',
    backgroundColor: 'white',
    maxHeight: '150px',
  })
}
