/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#888'};
`

const Download = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <path d="M7.34,15.53a8,8,0,1,1,8-8A8,8,0,0,1,7.34,15.53ZM7.34.91A6.63,6.63,0,1,0,14,7.53,6.63,6.63,0,0,0,7.34.91Z" transform="translate(0.65 0.47)"/>
    <path d="M7.34,10.41a.69.69,0,0,1-.68-.69V4.79A.69.69,0,0,1,8,4.79V9.72A.69.69,0,0,1,7.34,10.41Z" transform="translate(0.65 0.47)"/>
    <path d="M7.28,11h0a1.08,1.08,0,0,1-.81-.38L4,7.59a.69.69,0,0,1,.09-1A.68.68,0,0,1,5,6.7L7.28,9.43,9.7,6.69a.69.69,0,0,1,1,.91l-2.65,3A1.09,1.09,0,0,1,7.28,11Z" transform="translate(0.65 0.47)"/>
  </SVG>
)

Download.propTypes = {
  color: PropTypes.string
}

export default Download