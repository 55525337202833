/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Search = (props) => (
  <SVG className={props.className} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <title>Search</title>
    <path d="M15.31,16a.66.66,0,0,1-.47-.19l-4.17-4a.68.68,0,0,1,0-1,.69.69,0,0,1,1,0l4.17,4a.69.69,0,0,1,0,1A.7.7,0,0,1,15.31,16Z" transform="translate(0)"/>
    <path d="M6.85,13.57A6.83,6.83,0,0,1,0,6.78a6.85,6.85,0,0,1,13.69,0A6.83,6.83,0,0,1,6.85,13.57Zm0-12.2A5.45,5.45,0,0,0,1.37,6.78a5.48,5.48,0,0,0,11,0A5.45,5.45,0,0,0,6.85,1.37Z" transform="translate(0)"/>
  </SVG>
)

Search.propTypes = {
  className: PropTypes.string
}

export default Search
