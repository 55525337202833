/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'
import * as color from './../../stylesheets/common/colors.scss'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${color.greyDark};
`

const Airplane = () => (
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M9.3,16a1.16,1.16,0,0,1-1-.6l-2.73-5L.6,7.71A1.16,1.16,0,0,1,.73,5.62L14.4.09A1.16,1.16,0,0,1,15.91,1.6L10.38,15.27a1.16,1.16,0,0,1-1,.73ZM1.65,6.72,6.28,9.27a1.15,1.15,0,0,1,.45.45l2.55,4.63L14.46,1.54Zm4,3.75Zm9-9.75h0Z" transform="translate(0 0)"/>
    <path d="M8,8.7a.71.71,0,0,1-.49-.2.7.7,0,0,1,0-1L8.56,6.47a.69.69,0,0,1,1,1L8.47,8.5A.69.69,0,0,1,8,8.7Z" transform="translate(0 0)"/>
  </SVG>
)

export default Airplane
