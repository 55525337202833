import { FILTER_BY, CUSTOMER } from '../../shared/constants/filters'

function leftMenu(customers, notFoundInvoices, filterByCustomer) {
  const customersList = customers.length > 0 ? customers.map(customer => {
    return (
      {
        id: customer.Accounting_Code,
        label: customer.Customer_Name,
        filterBy: FILTER_BY.CUSTOMER,
        filterValue: customer.Accounting_Code,        
        icon: 'Customer',            
        colorOnActive: 'rgba(57, 146, 105, 0.2)',
        colorOnHover: 'rgba(57, 146, 105, 0.2)',
        action: () => filterByCustomer(FILTER_BY.CUSTOMER, customer)
      }
    )
  }) : []

  notFoundInvoices.length > 0 && customersList.push({
    id: CUSTOMER.NOT_FOUND,
    label: 'Not found',
    icon: 'XWithCircle',
    filterValue: CUSTOMER.NOT_FOUND,
    colorOnActive: 'rgba(57, 146, 105, 0.2)',
    colorOnHover: 'rgba(57, 146, 105, 0.2)',
    action: () => filterByCustomer(FILTER_BY.CUSTOMER, CUSTOMER.NOT_FOUND)
  })

  return [
    {
      items: [
        ...customersList
      ]
    }
  ]
}

export default leftMenu
