import React from 'react'
import { Link } from 'react-router-dom'
import Typography from './Typography'
import Star from '../../assets/svgs/Star'
import StarFilled from '../../assets/svgs/StarFilled'
import ArrowDownward from '../../assets/svgs/ArrowDownward'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const SubtitleContainer = styled.div`
  margin-left: 2px;
`

const NavBarTitle = props => {
  return (
    <React.Fragment>
      <Typography element="h2">
        {props.handleRedirect &&
          (
            <Link className="nav-bar-arrow" to="/" onClick={() => props.handleRedirect()}>
              <ArrowDownward orientation='left'/>
            </Link>
          )
        }
        <TitlesContainer>
          <div>{props.title}</div>
          <SubtitleContainer>
            <Typography color="gray" element="p">
              {props.subtitle}
            </Typography>
          </SubtitleContainer>
        </TitlesContainer>
        {props.handleStarClick &&
          (
            <div onClick={(e) => props.handleStarClick(e, props.isStarred, {Miranda_ID: props.Miranda_ID})}>
              {props.isStarred ?
                (<StarFilled />)
                :
                (<Star />)
              }
            </div>
          )
        }
      </Typography>
    </React.Fragment>
  )
}

NavBarTitle.propTypes = {
  isStarred: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  handleStarClick: PropTypes.func,
  handleRedirect: PropTypes.func,
  Miranda_ID: PropTypes.string
}

export default NavBarTitle