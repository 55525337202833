import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../../shared/NewMenu'
import Download from '../../../assets/svgs/Download'
import Print from '../../../assets/svgs/Print'
import More from '../../../assets/svgs/More'
import styled from 'styled-components/macro'

const MenuStyled = styled(Menu)`
  height: 32px;
  margin-right: 16px;
  width: 32px;
  
  ul.mainMenu{
    margin-top:8px;
    left: calc(100% - 346px); 
  }
  div.reference{
    height: 32px;
    width: 32px;

    div{
      height: 32px;
      width: 32px;
    }
  }

  svg {
    width: 16px;
  }

  div {
    .mainMenu {
      left: calc(100% - 384px);
      margin-top: 8px;
    }
  }

`

const InvoicesMenu = (props) => {
  return (    
    <MenuStyled      
      options={[
        {
          id: 1,
          disabled: props.disabled,
          text: 'Download',
          iconLeft: <Download />,
          onClick: props.handleDownloadDocument
        },
        {
          id: 2,
          disabled: props.disabled,
          text: 'Add to print queue',
          iconLeft: <Print />,
          onClick: props.sendToPrintQueue
        },
      ]}
    >
      <More />
    </MenuStyled>
  )
}

InvoicesMenu.propTypes = {
  handleDownloadDocument: PropTypes.func,
  sendToPrintQueue: PropTypes.func,
  disabled: PropTypes.bool
}

export default InvoicesMenu