import React from 'react'
import PropTypes from 'prop-types'
import RightDrawerLayout from '../../layouts/RightDrawerLayout'
import InvoiceInformation from '../customer/InvoiceInformation'
import LeftMenu from '../shared/LeftMenu'
import leftMenuSettings from './leftMenuSettings'
import
InvoicesByCustomer
  from '../../containers/components/searchResults/InvoicesByCustomer/InvoicesByCustomer.container'

const SearchResultsView = ({
  notFoundInvoices,
  customers,
  detailedInvoice,
  clearDetailedInvoice,
  filterByCustomer,
  filterValue,
  handleReturn,
  changeDisputingStatus,
  counter,
  currentUser,
  selectedCustomer
}) => {
  return (
    <RightDrawerLayout
      isRightMenuOpen={detailedInvoice && true}
      rightMenu={
        detailedInvoice &&
        <InvoiceInformation
          changeDisputingStatus={changeDisputingStatus}
          invoice={detailedInvoice}
          clearDetailedInvoice={clearDetailedInvoice}
        />
      }
      title="search results"
      avatarName={currentUser.currentUser && currentUser.currentUser.data.user_name}
      clearCustomerDetails={handleReturn}
    >
      <div className="content-main">
        <LeftMenu
          customer={selectedCustomer}
          clearDetailedInvoice={clearDetailedInvoice}
          leftMenu={leftMenuSettings(customers, notFoundInvoices, filterByCustomer)}
          count={counter}
          filterValue={filterValue}
        />

        <InvoicesByCustomer />
      </div>
    </RightDrawerLayout>
  )
}

SearchResultsView.propTypes = {
  notFoundInvoices: PropTypes.array,
  customers: PropTypes.array,
  detailedInvoice: PropTypes.object,
  clearDetailedInvoice: PropTypes.func,
  filterByCustomer: PropTypes.func,
  filterValue: PropTypes.string,
  filteredInvoicesTotal: PropTypes.array,
  clearCustomerDetails: PropTypes.func,
  handleReturn: PropTypes.func,
  changeDisputingStatus: PropTypes.func,
  counter: PropTypes.func,
  currentUser: PropTypes.object,
  selectedCustomer: PropTypes.object
}

export default SearchResultsView