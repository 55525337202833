/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const SVG = styled.svg`
    width: 100%;
    height: 100%;
    fill: #888;
`

const Email = ({color}) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 13.16" color={color}>
    <path d="M14.55,14.58H1.78A1.78,1.78,0,0,1,0,12.8V2.87A1.44,1.44,0,0,1,.31,2l.4-.49L8,8.79l7.29-7.31.4.49a1.47,1.47,0,0,1,.31.9V13.13A1.45,1.45,0,0,1,14.55,14.58ZM1.13,3.5v9.3a.65.65,0,0,0,.65.65H14.55a.32.32,0,0,0,.32-.32V3.5L8.72,9.66a1,1,0,0,1-1.44,0Z" transform="translate(0 -1.42)" />
    <path d="M8,10a1,1,0,0,1-.72-.3L.61,3a1,1,0,0,1-.3-.88.76.76,0,0,1,.45-.56,1.92,1.92,0,0,1,.69-.12h13.1a2.4,2.4,0,0,1,.76.12.72.72,0,0,1,.43.53,1.11,1.11,0,0,1-.35.91L8.72,9.66A1,1,0,0,1,8,10ZM1.77,2.55,8,8.79l6.23-6.24Z" transform="translate(0 -1.42)" />
  </SVG>
)

Email.propTypes = {
  color: PropTypes.string
}

export default Email 