import { setSelectedCustomer } from './customers.actions'

export const SET_OPEN_SEARCH_MODAL = '@indigo/route/SET_OPEN_SEARCH_MODAL'
export const SELECT_OPTION_IN_SEARCH_MODAL = '@indigo/route/SELECT_OPTION_IN_SEARCH_MODAL'

export const setOpenSearchModal = (value) => ({
  type: SET_OPEN_SEARCH_MODAL,
  value
})

export const selectOptionInSearchModal = (value) => dispatch => {
  if (typeof value === 'object') {
    dispatch(setSelectedCustomer(value))
  }

  return {
    type: SELECT_OPTION_IN_SEARCH_MODAL,
    value
  }
}

