import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'



class Container extends Component {
  render() {
    const Wrapper = styled.div`
      padding: 40px;
      overflow-x:hidden;
      top: 72px;
      position: relative;
      .customer-container,
      .customer-container-starred {
        display: grid;
        grid-gap: 24px;
        padding: 24px 0;
        grid-template-columns: repeat( auto-fill, minmax(288px, auto) );
      }
    `

    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    )
  }
}

Container.propTypes = {
  padding: PropTypes.string,
  children: PropTypes.object,
}

export default Container