export const SET_DETAILED_INVOICE = '@indigo/customerDetails/SET_DETAILED_INVOICE'
export const setDetailedInvoice = (invoice) => ({
  type: SET_DETAILED_INVOICE,
  payload: {
    invoice
  }
})

export const CLEAR_DETAILED_INVOICE = '@indigo/customerDetails/CLEAR_DETAILED_INVOICE'
export const clearDetailedInvoice = () => {
  return {
    type: CLEAR_DETAILED_INVOICE
  }
}

export const UPDATE_DETAILED_INVOICE = '@indigo/customerDetails/UPDATE_DETAILED_INVOICE'
export const updateDetailedInvoice = (property, value) => {
  return {
    type: UPDATE_DETAILED_INVOICE,
    payload: {
      property,
      value
    }
  }
}