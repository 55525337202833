/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Calendar = () => (
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M14.7,16H1.3A1.3,1.3,0,0,1,0,14.7V2.39a1.3,1.3,0,0,1,1.3-1.3H14.7A1.3,1.3,0,0,1,16,2.39V14.7A1.3,1.3,0,0,1,14.7,16ZM1.37,14.63H14.63V2.46H1.37Z"/>
    <rect x="0.66" y="5.63" width="14.63" height="1.37"/>
    <path d="M4,3.4a.69.69,0,0,1-.69-.69v-2a.69.69,0,1,1,1.38,0v2A.69.69,0,0,1,4,3.4Z"/>
    <path d="M11.92,3.38h0a.68.68,0,0,1-.68-.69v-2A.69.69,0,0,1,11.93,0h0a.68.68,0,0,1,.68.69v2A.69.69,0,0,1,11.92,3.38Z"/>
  </SVG>
)

export default Calendar