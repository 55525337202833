import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import {Tooltip as TextTooltip, OverlayTrigger} from 'react-bootstrap'

const LabelText = styled(TextTooltip)`
pointer-events: none;
&.tooltip.top {
      margin-top: -8px;
}
&.fade{
    opacity: 0;
    transition: none;
  }
  &.tooltip.in{
    opacity: 1;
    .tooltip-inner{
      background-color: #252839;
      border-radius: 3px;
      opacity: 1;
      max-width: 200px;
      margin-left: -10px;
      p{
        opacity: 1;
        font-size: 14px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.43;
        letter-spacing: 0.3px;
        margin: 5px 0 5px 0;
        max-width: 100%;
        word-wrap: break-word;
      }
    }
    div.tooltip-arrow{
      border-top-color: #252839;
      margin-left: -10px;
    }
  }
`
const TooltipContainer= styled(OverlayTrigger)`
  opacity: 1;
`

const tooltipLabel = (text) => (
  <LabelText><p dangerouslySetInnerHTML={{__html: text}}/></LabelText>
)
const Tooltip = (props)=>(
  <TooltipContainer delayShow={0}
    delayHide={0}
    placement={'top'} overlay={tooltipLabel(props.toolTipText)}>
    {props.children}
  </TooltipContainer>
)

Tooltip.propTypes = {
  className: PropTypes.string,
  toolTipText: PropTypes.string
}


export default Tooltip