import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const Header1 = styled.h1`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 26px;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  letter-spacing: 0.6px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`

export const Header2 = styled.h2`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 22px;
  font-style: normal;
  font-stretch: normal;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`

export const Header3 = styled.h3`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 18px;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`

export const SubHeader = styled.h4`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 16px;
  font-style: ${props => props.isItalic ? 'italic' :  'normal'};
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`
export const Body = styled.p`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 14px;
  font-style: ${props => props.isItalic ? 'italic' :  'normal'};
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`

export const Small = styled.p`
  font-family: Open Sans;  
  font-weight: ${props => props.weight === 'regular' ? 'normal' : 600};
  font-size: 12px;
  font-style: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: ${props => props.color ? props.color : '#252839'};
  margin: ${props => props.spacing ? props.spacing : 0 };
`

class Typography extends Component {
  render() {
    const { element, weight, isItalic, color, children, onClick, className, spacing } = this.props

    let selectedElement = null

    switch(element) {
    case 'h1':
      selectedElement = (
        <Header1
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color} 
          spacing={spacing}         
        >
          {children}
        </Header1>
      )
      break
    case 'h2':
      selectedElement = (
        <Header2
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color} 
          spacing={spacing}        
        >
          {children}
        </Header2>
      )
      break  
    case 'h3':
      selectedElement = (
        <Header3
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color}   
          spacing={spacing}      
        >
          {children}
        </Header3>
      )
      break
    case 'h4':
      selectedElement = (
        <SubHeader
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color}      
          isItalic={isItalic}    
          spacing={spacing}         
        >
          {children}
        </SubHeader>
      )
      break  
    case 'span':
      selectedElement = (
        <Small
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color}    
          spacing={spacing}     
        >
          {children}
        </Small>
      )
      break    
    case 'p':   
    default:    
      selectedElement = (
        <Body
          className={className}
          onClick={onClick}
          element={element}
          weight={weight}
          color={color}      
          isItalic={isItalic}
          spacing={spacing}   
        >
          {children}
        </Body>
      ) 

      break      
    }

    return selectedElement
  }
}

Typography.propTypes = {
  element: PropTypes.string,
  weight: PropTypes.oneOf(['regular', '600']),
  isItalic: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  spacing: PropTypes.string
}

Typography.defaultProps = {
  weight: 'regular'
}

export default Typography