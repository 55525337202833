import { 
  SET_DETAILED_INVOICE,
  CLEAR_DETAILED_INVOICE,
  UPDATE_DETAILED_INVOICE,
} from '../../../actions/components/customer/customerDetails'

export const defaultState = {
  detailedInvoice: null
}

export const customerDetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
  case SET_DETAILED_INVOICE: 
    return {
      ...state,
      detailedInvoice: Object.assign(action.payload.invoice)
    }

  case CLEAR_DETAILED_INVOICE:
    return {
      ...state,
      detailedInvoice: null
    }  

  case UPDATE_DETAILED_INVOICE:  
    return {
      ...state,
      detailedInvoice: {
        ...state.detailedInvoice,        
        [action.payload.property]: action.payload.value
      }
    }  

  default:
    return state
  }
}