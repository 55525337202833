/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'


const SVG = styled.svg`
	width: 100%;
	height: 100%;
	fill: #888;

	.cls-1{
		fill:#f8c013;
	}
`

const StarFilled = () => (
  <SVG data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path className="cls-1" d="M3,10.67S0,5.9,0,5.89H0l5.05-1h0l3-4.9h0l3,4.94h0L16,6h0L13,10.74,13.32,16h0L8.09,14.06C7.66,14.22,2.5,16,2.5,16S2.9,11.27,3,10.67Z" transform="translate(0 0)"/>
  </SVG>
)

export default StarFilled