import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import CheckMark from '../../assets/svgs/CheckBox.jsx'

const Container = styled.div`
    height: 16px;
    width: 16px;

    &:last-child {
        margin: 0;
    }
    &.wh-container {
        margin: 0;
    }

    label {
        height: 16px;
        display: inline-block;
        display: flex;
        align-items: center;
        position: relative;
        user-select: none;
        text-transform: none;
        margin: 0;
    }
`

const CheckBox = styled.input`
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: solid 1px #888;
    border-radius: 4px;
    box-shadow: none;
    -webkit-appearance: none;
    /* override bootstrap checkbox default */
    margin: 0 !important;
    cursor: pointer;
    /*no default blue outline*/
    &:focus,
    &[type="checkbox"]:focus {
        outline: 0;
    }

    + svg {
        height: 16px;
        width: 16px;
        position: absolute;
        display: none;
        cursor: pointer;
        left: 0;
    }


    /*square style once checked*/
    &:checked {
        background-color: #252839;
        border: solid 1px #252839;
        /* Show the checkmark when checked */
        & ~ .check {
            display: block;
        }
    }
`

const SingleCheckbox = ({ checked, onChange, disabled, onClick }) => {
  return (
    <Container>
      <label>
        <CheckBox
          disabled={disabled}  
          onChange={onChange}
          onClick = {onClick}
          checked={checked}
          type="checkbox" />
        <CheckMark />
      </label>
      <br />
    </Container>
  )
}

SingleCheckbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func
}
export { SingleCheckbox }
