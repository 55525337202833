/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
`

const Menu = ({ color }) => (
    <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
        <path d="M15.11,1.78H.89A.89.89,0,0,1,.89,0H15.11a.89.89,0,1,1,0,1.78Z" transform="translate(0 0)"/>
        <path d="M15.11,16H.89a.89.89,0,0,1,0-1.78H15.11a.89.89,0,0,1,0,1.78Z" transform="translate(0 0)"/>
        <path d="M15.11,8.89H.89a.89.89,0,0,1,0-1.78H15.11a.89.89,0,1,1,0,1.78Z" transform="translate(0 0)"/>
    </SVG>
)

Menu.propTypes = {
    color: PropTypes.string
}

export default Menu


