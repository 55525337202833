/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const ExclamationMark = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path className="cls-1" d="M9,11.1H7.18a.78.78,0,0,1-.51-.15.83.83,0,0,1-.34-.7L5.78.94A.92.92,0,0,1,6,.27.87.87,0,0,1,6.66,0H9.34A.91.91,0,0,1,10,.27a.9.9,0,0,1,.23.67l-.55,9.45a.81.81,0,0,1-.45.62ZM7.18,1.37l.49,8.36h.66l.49-8.36Z"/>
    <path className="cls-1" d="M8,16a1.88,1.88,0,1,1,1.87-1.87A1.87,1.87,0,0,1,8,16Zm0-2.38a.51.51,0,1,0,.5.51A.5.5,0,0,0,8,13.62Z"/>
  </SVG>
)

export default ExclamationMark 

