/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'


export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color ? props.color : '#888'};
  width: 100%;
`

const Trash = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <path d="M6.27,12.36a.69.69,0,0,1-.68-.68V6.45A.69.69,0,0,1,7,6.45v5.23A.69.69,0,0,1,6.27,12.36Z" transform="translate(0.05 -0.01)" />
    <path d="M9.63,12.38a.68.68,0,0,1-.69-.68V6.48a.69.69,0,0,1,.69-.69.68.68,0,0,1,.68.69V11.7A.67.67,0,0,1,9.63,12.38Z" transform="translate(0.05 -0.01)" />
    <path d="M12.87,16H3a1.41,1.41,0,0,1-1.45-1.37V2.86a.69.69,0,0,1,.69-.69H13.63a.69.69,0,0,1,.69.69V14.64A1.41,1.41,0,0,1,12.87,16ZM3,3.54v11.1h9.92l.07-11.1Z" transform="translate(0.05 -0.01)" />
    <path d="M15.26,3.54H.64a.69.69,0,1,1,0-1.37H15.26a.69.69,0,1,1,0,1.37Z" transform="translate(0.05 -0.01)" />
    <path d="M9.25,1.38H6.65A.69.69,0,0,1,6,.69.68.68,0,0,1,6.65,0h2.6a.68.68,0,0,1,.69.68A.69.69,0,0,1,9.25,1.38Z" transform="translate(0.05 -0.01)" />
  </SVG>
)

Trash.propTypes = {
  color: PropTypes.string
}

export default Trash