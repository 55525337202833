import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../shared/Typography'
import styled from 'styled-components/macro'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  margin-left: 262px;

  > h3 {
    margin-bottom: 40px;
  }
`

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: row;

  > p:first-child {
    flex: 0 0 92px;
    margin-right: 8px;
  }
`

const NotFoundInvoices = ({ notFoundInvoices }) => {
  return (
    <Container>
      <Typography
        element='h3'
      >
        Not Found
      </Typography>

      <NotFoundContainer>
        <Typography
          element='p'
          color='#888888'
        >
         Couldn&apos;t find: 
        </Typography>
        <Typography
          element='p'
        >
          {notFoundInvoices.join(', ')}
        </Typography>

      </NotFoundContainer>

    </Container>
  )
}

NotFoundInvoices.propTypes = {
  notFoundInvoices: PropTypes.array
}

export default NotFoundInvoices

