import axios from 'axios'
import { UrlApi } from '../envVar'

export const apiRequest = (method, endpoint, data = null) => {
  return axios({
    method: method,
    url: `${UrlApi}/${endpoint}`,
    data: data
  })
}

export const awsRequest = (method, endpoint, data = null) => {
  return axios({
    method: method,
    url: endpoint,
    data: data
  })
}