import React, {Component} from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
    width: min-content;
    padding-top: 2.5px;
    background-color: transparent;    
    cursor: ${props => props.disabled ? 'unset' : 'pointer'};
    
    &:hover{
        div{            
            border-bottom: ${props => props.disabled ? '1.5px solid transparent' : '1.5px solid' + '#4a6791'};
            input{
                color: ${props => props.disabled ? '#888' : '#4a6791'};
            }
            svg{
                fill: ${props => props.disabled ? '#888' : '#4a6791'};
            } 
        } 
    }
`;

const ButtonContent = styled.div`
    display: flex;    
    align-items: center;
    padding-bottom: 4px;
    border-bottom: 1.5px solid transparent;
    
    input{
        color: ${props => props.disabled ? '#888' : '#6887b4'};
    }
    svg{
        height: 16px;
        width: 16px;
        fill: ${props => props.disabled ? '#888' : '#6887b4'};
        cursor: ${props => props.disabled ? 'unset' : 'pointer'}
        margin-left: ${props => props.right ? '8px' : '0'};
        margin-right: ${props => props.left ? '8px' : '0'};
    }    
`;

const Button = styled.input`
    cursor: pointer;
    background-color: inherit;
    font-size: 14px;
    font-weight: 600;    
    letter-spacing: 1px;
    text-align: center;    
    text-transform: uppercase;    
    border: none;
    padding: 0;

    &:disabled{
        color: '#888'
        cursor: unset;  
    }
`;

export default class extends Component{
    render(){
        return (
            <ButtonWrapper onClick = {this.props.onClick} disabled = {this.props.disabled} className = {this.props.className}>
                <ButtonContent disabled = {this.props.disabled} left = {this.props.left} right = {this.props.right}>              
                    {this.props.left && this.props.left}                
                    <Button value={this.props.children} readOnly = {true} type = 'button' disabled={this.props.disabled}></Button>
                    {this.props.right && this.props.right}                
                </ButtonContent>
            </ButtonWrapper>
        );
    };
}