import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Redirect } from 'react-router-dom'

import { AuthConsumer } from '../AuthContext'

export function Login (props) {
  const query = queryString.parse(props.location.search || '?')
  if (query.token) {
    const groups = Array.isArray(query.groups) ? query.groups : [query.groups]
    props.login({...query, groups})
  }
  const redirect = query.token ? '/' : '/logout'
  return <Redirect to={redirect} />
}

Login.propTypes = {
  location: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired
}
export default props => (
  <AuthConsumer>
    {
      ({login}) => <Login {...props} login={login} />
    }
  </AuthConsumer>
)
