import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Button, DropdownButton, MenuItem } from 'react-bootstrap'
import ArrowDownward from '../../../../assets/svgs/ArrowDownward'
import Loader from '../../../../components/shared/Loader'
import SearchIcon from '../../../../assets/svgs/SearchIcon'
import Typography from '../../Typography'

const Wrapper = styled.div`
  svg {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    fill: #252839;
  }

  img {
    width: 4%;
    margin-left: 10%;
  }

  margin: 16px 60px 16px 32px;
  display: flex;
  align-items: center;
  div + svg {
    height: 5px;
    width: 10px;
    float: right;
    margin-left: -17px;
    fill: #6887b4;
    pointer-events: none;
    background-color: #fff;
    padding-right: 0px;
    margin-right: 8px;
    margin-top: 2px;
    z-index:999;
  }
`
export const Input = styled.input`
  border: none;
  width: 505px;
  background-color: #ffffff;
  margin-left: 8px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  ::-webkit-input-placeholder {
    color: #888888;
  }
  :focus {
    color: #252839;
  }
`
export const StyledMenuItem = styled(MenuItem)`
  width: 115px;
  height: 32px;
  border: none;
  &.styled{
    >a:hover, a:focus {
      color: #252839;
      background-color:#f2f2f2;    
      background-image: none ;
      outline: none;
    }
    
    a{
      height: inherit;
      padding: 6px 24px 6px 26px;

    }
  
  }
   
  &.active{
    a{
      font-weight: 600;
      background-image: none !important;   
      background-color: rgb(224, 229, 240) !important;  
      color: #252839 !important;
    }
  }
`
export const StyledDropDown = styled(DropdownButton)`
  border-radius:0px;
  background-color:transparent;
  box-shadow: none;
  background-image: transparent;
  width: 99px;
  height: 32px;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.3px;
  color: #252839;
  &#styleDrop{
    padding-right:26px;
    padding-left:8px;
    :active {
      box-shadow: none;
    }
  }
  :hover {
    border-bottom: 2px solid rgba(204, 204, 204, 1);
    background-color:#fcfcfc;
    border-radius: 0px;

  }
  
  + ul{
    top: -10px;
    height:80px;
    border: none;
    border-radius: 0px;
    min-width:115px;
    margin-left: -16px;
    text-align: justify;
    padding-top:8px;
    padding-bottom: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

  }
`

const StyledButton = styled(Button)`
  &.btn.btn-default {
    background-image: none;
    box-shadow: none;
    text-shadow: none;
    padding: 0;
    border: none;
    margin-left: 72px;
    margin-top: -4px;
    &:hover {
      background-image: none;
      background-color: transparent;
    }
    &:focus {
      background-image: none;
      background-color: transparent;
      color: black;
    }
    span:hover {
      color: black;
    }
    span: focus {
      color: black;
    }
  }
`

const dropdownTypes = ['customer', 'invoice']

const SearchBar = ({ searchText, handleInput, handleClear, handleDropDownChange, dropdownValue, onRequest }) => {
  return (
    <Wrapper>
      <SearchIcon className="search-icon" />

      <StyledDropDown
        id="styleDrop"
        className="styledDropDown"
        onChange={e => handleDropDownChange(e)}
        noCaret
        bsStyle={dropdownValue.toLowerCase()}
        title={dropdownValue}>

        {dropdownTypes.map((type) => (
          <StyledMenuItem
            key={type}
            eventKey={type}
            className="styled"
            active={dropdownValue === type}
            onSelect={e => handleDropDownChange(e)}>
            {type}
          </StyledMenuItem>
        ))}

      </StyledDropDown>
      <ArrowDownward />
      <Input
        autoFocus={true}
        value={searchText}
        type="text"
        placeholder={dropdownValue === 'customer' ? 'search by name or ID' : 'search for one or more invoice #s'}
        onChange={e => handleInput(e)}
        disabled={onRequest}
      />

      {onRequest && <Loader />}
      
      {searchText.length > 0 && (
        <StyledButton onClick={handleClear}>
          <Typography element="span" color="#888888">
            Clear
          </Typography>
        </StyledButton>
      )}
    </Wrapper>
  )
}

SearchBar.propTypes = {
  searchText: PropTypes.string,
  handleInput: PropTypes.func,
  handleClear: PropTypes.func,
  handleDropDownChange: PropTypes.func,
  dropdownValue: PropTypes.string
}

export default SearchBar
