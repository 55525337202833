import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'
import Typography from './Typography'
import Angle from '../../assets/svgs/Angle'

const Ul = styled.ul`
  background: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  list-style: none;
  padding: 8px 0;
  position: absolute;
  width: 233px;
  z-index: 1001;

  div {
    width: 100% !important;

    div {
      align-items: center;
      display: flex;
      cursor: pointer;
      height: 32px;
      justify-content: flex-start !important;
      width: 100% !important;

     >svg:first-child {
        margin-left: 24px;
        margin-right: 16px;
        width: 16px;
        fill: black;
      }

      svg:nth-child(2) {
        fill: black;
        position: absolute;
        right: 16px;
        transform: rotate(-90deg);
        width: 10px !important;
      }
    }
    &:hover {
      background-color: #f2f2f2;
    }
  }
`

const Li = styled.li`
  align-items: center;
  display: flex;
  
  cursor: ${props => !props.disabled ? 'pointer' : 'default'};
  
  &:hover{
    background-color: ${props => !props.disabled ? '#f2f2f2' : ''}; 
  }
  
  height: 32px;
  svg {
    margin-left: 24px;
    margin-right: 16px;
    width: 16px;
    fill: ${props => props.disabled ? '#a4a4a4' : '#252839'};
  }
  p {
    margin: 5px 0;
    color: ${props => props.disabled && '#a4a4a4'};
  }

  &:hover {
    background-color: #f2f2f2;      
  }

  &.active{
    background-color: rgb(224, 229, 240);//need to use a prop here to condition the styling
  }
`

const Hr = styled.hr`
  margin: 8px 0;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 32px;
  color: black;
  border-radius: 4px;
  
  &:hover {
    background-color: rgba(226, 226, 226, 0.5);
    > svg {
      fill: #252839;
    }
  }

  &.opened {
    background-color: rgb(226, 226, 226);
    > svg {
      fill: #252839;
    }
    &:hover {
      background-color: rgb(226, 226, 226);
      > svg {
        fill: #252839;
      }
    }
  }

  &.disabled {
    cursor: default;
    &:hover {
      background-color: transparent;
      svg {
        fill: rgba(136, 136, 136, 0.6);
      }
    }
    svg {
      fill: rgba(136, 136, 136, 0.6);
      cursor: default;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

class ToogleMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasSubMenu: false
    }
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.subMenu=this.subMenu.bind(this)
  }

  onMouseLeave() {
    this.props.handleClose()
  }

  subMenu(val) {
    if (val !==this.state.hasSubMenu) {
      this.setState({ hasSubMenu: val })
    }
  }

  render() {
    const { options, isSubMenu, styles } = this.props
    return (
      <Ul
        style={styles}
        className={`${isSubMenu ? 'subMenu' : 'mainMenu'}`}
        onMouseLeave={this.onMouseLeave}
      >
        {options.map(item => {
          return (
            <React.Fragment key={item.id}>
              {item.divider ? (
                <Hr />
              ) : (
                  <React.Fragment>
                    {!item.hasChildren && (
                      <Li
                        key={item.id}
                        onMouseOver={()=>this.subMenu(false)}
                        onMouseOverNeeded={false}
                        disabled={!!item.disabled}
                        onClick={e => {
                          e && e.stopPropagation()
                          if (!item.disabled) {
                            if (item.onClick) {
                              item.onClick()
                            }
                            this.props.handleClose()
                          }
                        }}
                      >
                        {item.iconLeft}

                        <Typography
                          color={item.textColor ? item.textColor : 'black'}
                        >
                          {item.text}
                        </Typography>
                      </Li>
                    )}
                    {(item.hasChildren) ? (
                      <Menu options={item.options}
                        onMouseOverNeeded={true}
                        subMenu={this.subMenu}
                        canShow={this.state.hasSubMenu}
                        isSubMenu={true}
                        hasOtherMenu={true}
                        handleTriggered={this.props.handleClose}>
                        {item.iconLeft}
                        {item.text}
                        <Angle />
                      </Menu>
                    ):''}
                  </React.Fragment>
                )}
            </React.Fragment>
          )
        })}
      </Ul>
    )
  }
}

ToogleMenu.propTypes = {
  options: PropTypes.array,
  isSubMenu: PropTypes.bool,
  handleClose: PropTypes.func,
  textColor: PropTypes.string,
  styles: PropTypes.object
}

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openMenu: false,
      canShow: true
    }

    this.open = this.open.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleTriggered = this.handleTriggered.bind(this)
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.canShow !== this.state.canShow) {
      if(!this.props.canShow && this.state.openMenu !== false){
        this.open()
      }
      this.setState({
        canShow: this.props.canShow
      })
    }

    // Return null if the state hasn't changed
    return null;
  }
  open(e, child) {
    e && e.stopPropagation()
    if (!this.props.disabled) {
      if (this.props.handleMenu) {
        this.props.handleMenu(!this.state.openMenu)
      }

      this.setState(oldState => {
        return {
          openMenu: !oldState.openMenu
        }
      })
    }
  }

  handleTriggered() {
    this.open()

    if (this.props.isSubMenu) {
      this.props.handleTriggered()
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState(() => {
        return {
          openMenu: false
        }
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { children } = this.props
    return (
      <div
        className={`${this.props.className} reference`}
        ref={this.setWrapperRef}
      >
        {this.props.onMouseOverNeeded ? (
          <Wrapper
            onClick={(e) => e.stopPropagation()}
            onMouseOver={(e) => { this.open(e, this.props.hasSubMenu); this.props.subMenu(true) }}
            className={this.state.openMenu ? 'opened' : ''}
          >
            {children}
          </Wrapper>
        ) : (
            <Wrapper
              onClick={this.open}
              className={this.state.openMenu ? 'opened' : ''}
            >
              {children}
            </Wrapper>
          )}
        {(this.state.openMenu) && (
          <ToogleMenu {...this.props} open={this.open} styles={this.props.styles} handleClose={this.handleTriggered} />
        )}
      </div>
    )
  }
}

export default Menu
