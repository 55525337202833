const daysLayouts = (param)=>{
  const options = {
    0: 'Today',
    1: 'Yesterday'
  }
  return options[param] || `${param} days ago`
}

const datediff=(first, second)=>(
  Math.round((second-first)/(1000*60*60*24))
)

const DatebyProximity = (date)=>{
  let actualDate = new Date()
  let receivedDate = new Date(date)
  return datediff(receivedDate, actualDate)
}
const lastActivity = (date) =>{
  let diffDays = DatebyProximity(date)
  return daysLayouts(diffDays)
}
const parseTimeToAmPm=(date)=>{
  var hours = date.getHours()
  var minutes = date.getMinutes()
  var ampm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0'+minutes : minutes
  var strTime = hours + ':' + minutes + ' ' + ampm
  return strTime
}

const lastActivityFormated = (date)=>{
  date = new Date(date)
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()} at ${parseTimeToAmPm(date)}`
}

export {lastActivity, lastActivityFormated}