import React from 'react'
import { NavLink } from 'react-router-dom'
import Typography from '../Typography'
import Icons from '../Icons'
import styled, { css } from 'styled-components/macro'

const baseStyles = css`
  display: flex;
  align-items: center;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 11px 6px 10px;
  text-decoration: none !important; //could not figure out how to remove underline using normal css specificity

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  svg {
    fill: ${props => props.active ? '#252839' : '#888888'};
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  p {
    color: ${props => props.active ? '#252839' : '#888888'};
    margin: 0;
    width: ${props => props.active ? '146px' : '182px'};
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  } 

  background-color: ${props => props.active ? props.coloronactive : 'transparent'};

  &:hover {
    background-color: ${props => props.active ? props.active : props.coloronhover ? props.coloronhover : 'blue'};  
    text-decoration: none;  

    svg {
      fill: #252839;
    }

    p {
      color: #252839;
      margin: 0;      
    }

    .count {
      margin: 0;
      margin-left: auto;
      text-decoration: none; 
    }      
  }

  .count {
    margin: 0;
    margin-left: auto;
    width: 25px;
    text-align: right;
  } 
`

const NavLinkStyled = styled(NavLink)`
  ${baseStyles}
`
const Item = styled.div`
  ${baseStyles}
`

const MenuItem = ({disabled, item, counter, filterValue, currentURL, changeOfMenu, active}) => {
  const menuItemContent = (
    <React.Fragment>      
      {Icons[item.icon]}
      <Typography element="p" weight="600" color="#888888">{item.label}</Typography>      
      {(filterValue === item.filterValue && item.filterValue !== null) && (
        <Typography element="p" className="count">{counter}</Typography>
      )} 
    </React.Fragment>
  )

  const baseProps = {
    disabled,
    coloronactive: item.colorOnActive,
    coloronhover: item.colorOnHover,
    active: active,
    onClick: disabled && (item.action ? () => changeOfMenu(item) : null)
  }

  return (    
    item.redirectTo ? (
      <NavLinkStyled      
        {...baseProps}
        to={
          disabled ?
            (item.redirectTo ? currentURL + '/' + item.redirectTo : '#') :
            '#'
        }  
      >
        {menuItemContent}
      </NavLinkStyled>
    ) : (
      <Item
        {...baseProps}
      >
        {menuItemContent}
      </Item>
    ) 
  )
}

export default MenuItem