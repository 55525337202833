import React from 'react'
import LoadingMessage from '../components/shared/LoadingMessage'
import MainLoader from '../components/shared/MainLoader'
import { toast, cssTransition } from 'react-toastify'
import './styles.scss'

const SlideIn = cssTransition({
  enter: 'slideInUp',
  exit: 'slideOutDown',
})

const Toast = {
  callToast: (autoCloseTime, message, needsLoader = true) => {    
    return toast(
      (<LoadingMessage
        message={ message || 'Loading customers...'}>
        {needsLoader && <MainLoader />}
        
      </LoadingMessage>
      ), {
        autoClose: autoCloseTime || true,
        transition: SlideIn
      })
  },

  dismissToast: (toastId) => {
    toast.dismiss(toastId)
  }
}

export default Toast