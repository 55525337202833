/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Controls = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8,16a.68.68,0,0,1-.68-.69V13.92A.68.68,0,0,1,8,13.24a.69.69,0,0,1,.69.68v1.39A.69.69,0,0,1,8,16Z" transform="translate(0 0)"/>
    <path d="M8,11a.68.68,0,0,1-.68-.69V.69A.68.68,0,0,1,8,0a.69.69,0,0,1,.69.69v9.57A.69.69,0,0,1,8,11Z" transform="translate(0 0)"/>
    <path d="M8,14.61a2.52,2.52,0,1,1,2.51-2.51A2.52,2.52,0,0,1,8,14.61ZM8,11A1.15,1.15,0,1,0,9.14,12.1,1.14,1.14,0,0,0,8,11Z" transform="translate(0 0)"/>
    <path d="M13.49,16a.69.69,0,0,1-.69-.69V5.82a.69.69,0,0,1,1.37,0v9.49A.69.69,0,0,1,13.49,16Z" transform="translate(0 0)"/>
    <path d="M13.49,2.85a.69.69,0,0,1-.69-.69V.69a.69.69,0,1,1,1.37,0V2.16A.69.69,0,0,1,13.49,2.85Z" transform="translate(0 0)"/>
    <path d="M13.49,6.51A2.52,2.52,0,1,1,16,4,2.51,2.51,0,0,1,13.49,6.51Zm0-3.66A1.15,1.15,0,1,0,14.63,4,1.14,1.14,0,0,0,13.49,2.85Z" transform="translate(0 0)"/>
    <path d="M2.51,16a.69.69,0,0,1-.69-.69V9.5a.69.69,0,0,1,.69-.69.68.68,0,0,1,.68.69v5.81A.68.68,0,0,1,2.51,16Z" transform="translate(0 0)"/>
    <path d="M2.51,6.52a.68.68,0,0,1-.69-.68V.69A.69.69,0,0,1,2.51,0a.68.68,0,0,1,.68.69V5.84A.67.67,0,0,1,2.51,6.52Z" transform="translate(0 0)"/>
    <path d="M2.51,10.18A2.52,2.52,0,1,1,5,7.67,2.51,2.51,0,0,1,2.51,10.18Zm0-3.66A1.15,1.15,0,1,0,3.64,7.67,1.15,1.15,0,0,0,2.51,6.52Z" transform="translate(0 0)"/>
  </SVG>
)

export default Controls