/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#888'};
`

const Settings = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <path d="M7.65,15.42a1.39,1.39,0,0,1-1.38-1.14l-.19-1a5.3,5.3,0,0,1-1-.4l-.67.52a1.4,1.4,0,0,1-1.82-.08l-.54-.5A1.38,1.38,0,0,1,1.89,11l.56-.85a6.15,6.15,0,0,1-.34-.84l-1-.17A1.39,1.39,0,0,1,0,7.75V7A1.4,1.4,0,0,1,1.19,5.64l.92-.14a5.92,5.92,0,0,1,.38-.9L2,4A1.4,1.4,0,0,1,2,2.13l.5-.54a1.39,1.39,0,0,1,1.8-.22l.85.55a7,7,0,0,1,.89-.37L6.23.6A1.41,1.41,0,0,1,7.62-.58h.74A1.42,1.42,0,0,1,9.75.6l.16,1a6.4,6.4,0,0,1,.89.37l.82-.54a1.39,1.39,0,0,1,1.8.19l.51.54A1.38,1.38,0,0,1,14,3.92l-.53.69a5.92,5.92,0,0,1,.38.9l.95.16A1.4,1.4,0,0,1,16,7.07v.74a1.39,1.39,0,0,1-1.2,1.36l-.9.14a6.77,6.77,0,0,1-.36.88l.53.71a1.41,1.41,0,0,1-.12,1.82l-.52.53a1.42,1.42,0,0,1-1.81.17l-.77-.54a6,6,0,0,1-.91.38l-.14,1a1.4,1.4,0,0,1-1.36,1.2H7.65Zm-2.57-4a.81.81,0,0,1,.42.12,5,5,0,0,0,1.35.56l.47.11,0,.49L7.62,14h.77l.22-1.33a.81.81,0,0,1,.6-.67,5.39,5.39,0,0,0,1.24-.51.82.82,0,0,1,.88,0l1.05.73.57-.53-.75-1a.84.84,0,0,1,0-.9,4.64,4.64,0,0,0,.51-1.22A.83.83,0,0,1,13.33,8l1.25-.18,0-.77.09-.7L14.59,7,13.3,6.8a.83.83,0,0,1-.65-.61A4.79,4.79,0,0,0,12.14,5a.82.82,0,0,1,0-.91l.75-1-.51-.57-1.14.73a.83.83,0,0,1-.87,0,4.61,4.61,0,0,0-1.21-.5.82.82,0,0,1-.6-.66L8.39.82,7.62.8,7.38,2.12a.83.83,0,0,1-.6.66,5.12,5.12,0,0,0-1.21.49.79.79,0,0,1-.85,0L3.59,2.52l-.54.54.74,1A.8.8,0,0,1,3.85,5,5.18,5.18,0,0,0,3.33,6.2a.81.81,0,0,1-.66.61L1.4,7l0,.76L2.68,8a.82.82,0,0,1,.65.6,5.51,5.51,0,0,0,.51,1.23l.22.37L3,11.76l.55.54,1-.75A.82.82,0,0,1,5.08,11.38ZM10,12.9h0Zm-4.55-.26Zm8.12-3.28h0ZM14,5.84h0Zm-.44-.39Zm-.26-.56Zm0-.62ZM10,1.89h0Zm-.41-.44h0Z" transform="translate(0.01 0.58)" />
    <path d="M8,9.68a2.26,2.26,0,1,1,2.25-2.26A2.26,2.26,0,0,1,8,9.68ZM8,6.54a.89.89,0,1,0,.88.88A.88.88,0,0,0,8,6.54Z" transform="translate(0.01 0.58)" />
  </SVG>
)

Settings.propTypes = {
  color: PropTypes.string
}

export default Settings
