import React from 'react'
import PropTypes from 'prop-types'
import { SingleCheckbox } from '../../SingleCheckbox'
import ArrowDownward from '../../../../assets/svgs/ArrowDownward'
import styled from 'styled-components'

const StyledTR = styled.tr`
  border-bottom: 1px solid #d8d8d8;
  height: 56px;

  &.selected, &.selected:hover {
    background-color: #f2f2f2;

    .last-action.display-on-hover {
      background-image: none;
      background-color: #f2f2f2;
    }
  } 

  td:first-child {
    border-left: 1px solid transparent;
  } 

  &:hover td:first-child {
    border-left: 1px solid lightgray;
  } 

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    .menu-on-hover {
      position: absolute;
      right: 0;
      height: inherit;
      display: flex;
      align-items: center;

      .display-on-hover {
        display: flex;
        padding: 0 24px 0 20px;
        background-image: linear-gradient(to right, transparent 0%, #fff 15%);

        div {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          
          &:hover {
            background-color: #e2e2e2;
            border-radius: 4px;
          }

          svg {
            width: 16px;
          }
        }

        .right-arrow {
          width: 16px;
          margin-left: 16px;

          &:hover {
            background-color: unset;
            border-radius: unset;
          }

          svg {
            fill: #6887b4;
          }
        }
      } 
    }

    .options-cell,
    .due-col {                                 
      height: inherit;
      padding-right: 24px;

      div {
        float: right;
      } 
    }

    .display-always {
      display: none;
    }   
  }

  td {
    border: unset;
    vertical-align:middle;
  }

`
const StyledTD = styled.td`
  p {
      margin: 0;
  }

  &.invoice-number-cell {
      width: 7%;
  }

  &.invoice-icons-cell {
    width: 25%;

    span {
        display: inline-block;
    }                 
  }

  &.due-col {
    width:8%;
    p {
      text-align: right;
    }
  }

  &.options-cell { 
    width: 15%;                             
    height: inherit;

    div {
      text-align: left;
      margin-left:calc(100% - 73px);
    }
  }
`
const MenuWrapper = styled.div`
  .display-on-hover {
      display: none;                            
  }

  div.reference{
    position: relative;

    ul.mainMenu{
      position: absolute;
      margin-top: 42px;
      right: calc(100% - 34px);

      .reference{       
        .subMenu {
          left: -233px;
          top: -9px;
        }
      }
    }
  }
`

function TableRowView({ item, columns, handleSelectOne, rowMenu, rightArrow, handleRightArrow, isRowSelected, ...props }) {
  return (
    <StyledTR 
      key={item.Invoice_Number} 
      onClick={handleRightArrow ? () => handleRightArrow(item) : null}
      className={isRowSelected && 'selected'}
    >
      {props.checkable &&
        <StyledTD className="checkbox-table" onClick={function handleClick(e){e && e.stopPropagation()}}>
          <SingleCheckbox
            checked={item.checked}
            onChange={() => handleSelectOne(item)}
          />
        </StyledTD>
      }

      {columns.map((column) => {
        return (
          !column.hideWhen && (
            <StyledTD key={column.id} className={column.classNames && column.classNames.join(' ')}>
              {column.renderItem && column.renderItem(item)}
            </StyledTD>
          )
        )
      })}
   
      {rowMenu &&
        <StyledTD className="menu-on-hover">
          <MenuWrapper>
            <div className="last-action display-on-hover">
              {rowMenu && React.cloneElement(rowMenu, { invoice: item })}
              {rightArrow && (
                <div onClick={() => handleRightArrow(item)} className="right-arrow">
                  <ArrowDownward orientation='right' />
                </div>
              )}
            </div>
          </MenuWrapper>
        </StyledTD>
      }
    </StyledTR>
  )
}

TableRowView.propTypes = {
  item: PropTypes.object,
  checkable: PropTypes.bool,
  isRowSelected: PropTypes.bool,
  columns: PropTypes.array,
  handleSelectOne: PropTypes.func,
  rowMenu: PropTypes.element,
  rightArrow: PropTypes.bool,
  handleRightArrow: PropTypes.func
}

export default TableRowView
