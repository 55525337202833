import React from 'react'
import PropTypes from 'prop-types'
import FlatButton from '../../shared/FlatButton'
import Typography from '../../shared/Typography'
import Table from '../../shared/Table'
import PlusCircle from '../../../assets/svgs/PlusCircle'
import SimpleModal from '../../shared/SimpleModal/Modal'
import Snackbar from '../../shared/Snackbar'
import ContactForm from '../details/ContactForm'
import contactsTableColumns from '../invoices/contactsTableColumns'
import styled from 'styled-components'


const CreateButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #4a6791;
  cursor: pointer;
  width: 148px;
  height: 23px;
  margin-right: 10px;
  padding: 0px 0px 10px 0px;

  p {
    margin-top:10px;
    margin-right: 0;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-top: 10px;
  }

  &:hover {  
    border-bottom: 1px #4a6791 solid;
  }
`

const headerStyles = `
  th:nth-child(6) {
    padding-left: 26px;
  }
`

const ContactsView = ({
  customer,
  isModalOpen,
  handleEditContact,
  handleDeleteContact,
  contactSelected,
  toggleContactsModal,
  onAddContact,
  onEditContact,
  isDeleteModalOpen,
  onDeleteContact,
  toggleDeleteModal,
  closeContactModal,
  closeDeleteModal,
  ...props
}) => {
  let contacts = []
  if (customer) {
    contacts = customer.Customer_Emails
  }
  return (
    <div className="contacts-info">
      <Snackbar
        isActive={props.isSuccess}
        message={props.statusMessage}
      />

      <SimpleModal
        header={true}
        modalTitle={contactSelected ? 'Edit a contact' : 'Create a contact'}
        showModal={isModalOpen}
        onHide={closeContactModal}
      >
        <ContactForm
          customer={customer}
          contactToEdit={contactSelected}
          onCancel={toggleContactsModal}
          onSave={onAddContact}
          onEdit={onEditContact}
        />
      </SimpleModal>

      <SimpleModal
        width='440px'
        height='244px'
        header={true}
        modalTitle='Delete contact'
        footer={true}
        showModal={isDeleteModalOpen}
        onHide={closeDeleteModal}
        disableButton={() => props.onRequest}
        clickToCancel={true}
        button={{
          type: 'button',
          id: 'delete-button',
          name: 'delete-button',
          value: 'DELETE',
          onClick: () => onDeleteContact(contactSelected._id, customer)
        }}
      >
        Are you sure you want to delete this contact?
      </SimpleModal>

      <Table
        title="Contacts"
        headerStyles={headerStyles}
        headerButtons={[
          <CreateButtonWrapper onClick={toggleContactsModal} key="create-button-wrapper">
            <PlusCircle color="#4a6791" />
            <Typography element="p" weight="600" color="#4a6791">
              CREATE CONTACT
            </Typography>
          </CreateButtonWrapper>          
          // <FlatButton 
          //   onClick={toggleContactsModal} 
          //   disabled='true'
          //   hello={"hello"}
          //   left={<PlusCircle color="#4a6791" />} 
          //   // key="create-button-wrapper"
          // >
          //   Create Contact
          // </FlatButton>
        
        ]}
        header={() => contactsTableColumns(handleEditContact, handleDeleteContact)}
        filterValue='noFilter'
        data={contacts}
        checkable={false}
      />
    </div>
  )
}

ContactsView.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleContactsModal: PropTypes.func,
  onAddContact: PropTypes.func,
  customer: PropTypes.object,
  contacts: PropTypes.array,
  handleEditContact: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  contactSelected: PropTypes.object,
  handleInitials: PropTypes.func,
  isDeleteModalOpen: PropTypes.bool,
  onDeleteContact: PropTypes.func,
  isSuccess: PropTypes.bool,
  statusMessage: PropTypes.string,
  onEditContact: PropTypes.func,
  toggleDeleteModal: PropTypes.func,
  onRequest: PropTypes.func,
  closeDeleteModal: PropTypes.func,
  closeContactModal: PropTypes.func
}

export default ContactsView