/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  width: 100%;
  fill: ${props => props.color || '#888'};
`

const EditPencil = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15.96" color={color}>
    <path d="M.21,15.77a.93.93,0,0,1-.67-.28.94.94,0,0,1-.27-.82l.52-3.53L0,10.9C7.88,3,9.38,1.47,9.7,1.16h0l.44-.45A2.84,2.84,0,0,1,14,.49l.52.52a2.77,2.77,0,0,1-.22,3.9l-.44.44L4.11,15.18l-.3.07-3.45.51Zm.88-4L.71,14.32l2.72-.4L12.9,4.41l.47-.47a1.41,1.41,0,0,0,.22-2l-.52-.52a1.08,1.08,0,0,0-.78-.3,1.7,1.7,0,0,0-1.17.52l-.49.49ZM10.53,1.3h0Z" transform="translate(0.74 0.19)" />
    <path d="M12.42,6.19a.7.7,0,0,1-.5-.21L9.13,3.07a.69.69,0,0,1,0-1,.68.68,0,0,1,1,0L12.91,5a.67.67,0,0,1,0,1A.66.66,0,0,1,12.42,6.19Z" transform="translate(0.74 0.19)" />
  </SVG>
)

EditPencil.propTypes = {
  color: PropTypes.string
}

export default EditPencil