import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NavbarContainer from '../../containers/components/Navbar.container'
import './styles.scss'
import styled from 'styled-components/macro'
import NavBarTitle from '../../components/shared/NavBarTitle'

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  overflow: hidden;

  .invoices-info {
    .table-title {
      margin-left: 254px;
      padding-left: 8px;
    }
  }

  &.panel-open {
    .main,
    nav {
      width: calc(100% - 350px);
    }

    .table-title {
      margin-left: 254px;
      padding: 40px 8px;
      width: calc((100% - 342px) - 342px);
    }
  }
`

const Main = styled.div`
  min-height: 100vh;
  transition: all 0.3s;
  width: 100%;

  .content-main {
    position: relative;
    padding: 0 40px 40px;
    margin-top: 72px;
    width: 100%;
    .invoices-info {
      margin-left: 0 !important;
      .infinite-scroll-component {
        padding-top: 128px;
        margin-right: -40px;
        padding-right: 40px;
        padding-left: 262px;
      }
    }

    .contacts-info {
      margin-left: 262px;
      .infinite-scroll-component {
        position: relative;
        top: 108px;
      }
    }
  }
`

const Sidebar = styled.nav`
  display: block;    
  min-width: 350px;
  max-width: 350px;
  color: #000000;    
  z-index: 999;
  -webkit-box-shadow: -3px 0px 5px 0px rgba(226,226,226,1);
  -moz-box-shadow: -3px 0px 5px 0px rgba(226,226,226,1);
  box-shadow: -3px 0px 5px 0px rgba(226,226,226,1);

  &.active {
    margin-right: -350px;
    display: none;
  }

  .customer-details-nav {
    background: #fff;
  }
`

class RightDrawerLayout extends Component {
  constructor(props) {
    super(props)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  handleRedirect() {
    this.props.clearCustomerDetails()
  }

  render() {
    const isRightMenuOpen = this.props.isRightMenuOpen

    return (
      <Wrapper className={isRightMenuOpen && 'panel-open'}>
        <Main className="main">

          <NavbarContainer
            title={
              <NavBarTitle
                title={this.props.title}
                subtitle={this.props.customer && this.props.customer.Accounting_Code}
                handleRedirect={this.handleRedirect}
                isStarred={this.props.isStarred}
                handleStarClick={this.props.handleStarClick}
                Miranda_ID={this.props.customer ? this.props.customer.Miranda_ID : null}
              />
            }
            avatarName={this.props.avatarName || ''}
          />

          {this.props.children}
        </Main>
        <Sidebar className={!isRightMenuOpen && 'active'}>
          {this.props.rightMenu}
        </Sidebar>
      </Wrapper>
    )
  }
}

RightDrawerLayout.propTypes = {
  children: PropTypes.object,
  isRightMenuOpen: PropTypes.bool,
  rightMenu: PropTypes.object,
  clearCustomerDetails: PropTypes.func,
  title: PropTypes.string,
  isStarred: PropTypes.bool,
  handleStarClick: PropTypes.func,
  Miranda_ID: PropTypes.string,
  subtitle: PropTypes.string,
  customer: PropTypes.shape({
    Miranda_ID: PropTypes.string,
    Accounting_Code: PropTypes.string
  }),
  avatarName: PropTypes.string
}

export default RightDrawerLayout