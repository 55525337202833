import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { Modal as BootstrapModal } from 'react-bootstrap'
import * as R from 'ramda'
import Button from '../Button'
import FlatButton from '../FlatButton'
import PrimaryButton from '../PrimaryButton'
import ExitIcon from '../../../assets/svgs/ExitIcon.jsx'

const ModalContainer = styled(BootstrapModal)`
  width: ${props => props.width ? props.width: '496px'};
  min-height: ${props => props.height ? props.height: '368px'};
  max-height: calc(100vh - 160px);
  border-radius: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;

  .modal-dialog {
    width: 100%;
    margin: 0;
  }

  .modal {
    &-full {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        margin: 0;

        .modal-header .modal-title {
          font-size: 26px;
          line-height: 36px;
        }

        .modal-content {
          height: calc(100vh - 80px);
          overflow: auto;
          background-color: unset;
          box-shadow: none;
          border: none;
        }
        .modal-body {
          padding-bottom: 0;
        }
    }

    &-lg {
      width: 960px;
      margin: 80px auto auto;
    }

    &-md {
      width: 680px;
      margin: 80px auto auto;
    }

    &-sm,
    &-sm-gray {
      width: 496px;
      margin: 80px auto auto;
      &-gray .modal-content {
        background: #fafafa;
      }
    }

    &-min {
      width: 368px;
      margin: 80px auto auto;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding: 40px 48px;
    min-height: ${props => props.height ? props.height: '368px'};
  }

  .modal-body {
    padding: 0;

    .form-group input {
      width: calc(100% - 16px);
    }
  }

  .modal-header {
    border-bottom: none;
    padding: 0;

    .modal-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 1;
      letter-spacing: 0.4px;
      color: #252839;
    }
  }

  .modal-footer {
    padding: 0;
    border-top: none;
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    align-items: center;

    div:not(:first-child){
      margin-left: 32px;
    }    

    #cancel {
      background-color: transparent;
      margin-right: 30px;
    }
  }
`

const ExitModal = styled.div`
  height: 56px;
  width: 56px;
  background-color: #ececec;
  border-radius: 50%;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 48px;
  z-index: 1;
    svg {
      height: 20px;
      width: 20px;
    }
`

const Header = styled.div`
  display: flex; 
  align-items: center;
  margin-bottom: 40px;

  p {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin: 0;
  }

  p:first-child {
    font-weight: 600;
    color: #252839;
  }

  p:last-child {
    color: #888888;
    margin-left: auto;
  }
`

class SimpleModal extends Component {
  render(){
    const { width, height, buttonWidth } = this.props

    return (
      <ModalContainer 
        show={ R.type(this.props.showModal) === 'Function' ? this.props.showModal() : this.props.showModal } 
        onHide={this.props.onHide}
        onEnter={this.props.onEnter}
        onExit={this.props.onExit}
        dialogClassName={this.props.modalSize}
        onExited={this.props.onExited}
        width = {width}
        height={height}
        buttonWidth={buttonWidth}
      >
        { this.props.clickToExit && <ExitModal onClick={this.props.onHide}>
          <ExitIcon />
        </ExitModal>
        }
        { this.props.header && <BootstrapModal.Header>
          <Header>
            <p>
              {this.props.modalTitle} 
            </p>
            <p>
              {this.props.subTitle}
            </p>
          </Header>
        </BootstrapModal.Header>
        } 
        <BootstrapModal.Body>
          {this.props.children}
        </BootstrapModal.Body>
        { this.props.footer && <BootstrapModal.Footer>
          {/* { this.props.clickToCancel && <Button             
            name="Cancel"
            value="Cancel"
            type="button"
            onClick={this.props.onHide}
            id="cancel"
            btnType="secondary"
          />
          } */}
          { this.props.clickToCancel && <FlatButton                         
            onClick={this.props.onHide}
            id="cancel">
            Cancel
          </FlatButton>
          }
          {/* <Button 
            disabled={this.props.disableButton()}
            type={this.props.button.type}
            name={this.props.button.name}
            value={this.props.button.value}
            id={this.props.button.id}
            onClick={this.props.button.onClick}
          /> */}
          <PrimaryButton 
            disabled={this.props.disableButton()}            
            id={this.props.button.id}
            onClick={this.props.button.onClick}>
            {this.props.button.value}
          </PrimaryButton>
        </BootstrapModal.Footer>
        }
      </ModalContainer>
    )
  }
}

SimpleModal.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  onExited: PropTypes.func,
  clickToExit: PropTypes.bool,
  header: PropTypes.bool,
  modalTitle: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.object,
  footer: PropTypes.bool,
  clickToCancel: PropTypes.bool,
  disableButton: PropTypes.func,
  button: PropTypes.object
}

export default SimpleModal
