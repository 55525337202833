import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import CustomerIcon from '../../../assets/svgs/CustomerIcon'
import InvoiceIcon from '../../../assets/svgs/Invoice'

import Typography from '../../shared/Typography'
import PropTypes from 'prop-types'

export const Wrapper = styled.div`
  svg {
    height: 16px;
    width: 13.7px;
    fill: #252839;
    margin: 8px 8px 8px 10px;
    flex: 0 0 auto;
  }
  display:flex;
  align-items: center;
  width: 788px;
  height: auto;
  margin-top: 8px; 
  p {
    margin: 0px
  }
  :hover {
    cursor: pointer;
  }
  ${ ({ active }) => active && `
    background-color: #F1F1F1;
    border-radius: 4px;
    color: #252839;
    cursor: pointer;
  `}
`

const SearchResponse = ({ cursor, response, pos, notifyFocus, notifyMouseLeave, handleSelectItem, dropdownValue }) => {
  return (
    <Link to={dropdownValue === 'customer' ? `/customer/${response.Accounting_Code}/invoices` : '/invoices/search-results'}>
      <Wrapper
        className="responseElement"
        active={cursor === pos}
        onMouseEnter={() => notifyFocus(pos)}
        onMouseLeave={() => notifyMouseLeave()}
        onClick={() => handleSelectItem(pos)}>
        
        {dropdownValue === 'customer' ?
          <React.Fragment>
            <CustomerIcon />
            <Typography element="p" weight="600">
              {response.Name}
            </Typography>
            <Typography element="p" weight="600">
              {', ' + response.Accounting_Code}
            </Typography>
          </React.Fragment>
          :
          <React.Fragment>
            <InvoiceIcon />
            <Typography element="p" weight="600">
              {response}
            </Typography>
          </React.Fragment>
        }
      </Wrapper>
    </Link>
  )

}

SearchResponse.propTypes = {
  cursor: PropTypes.number,
  response: PropTypes.object,
  key: PropTypes.number,
  id: PropTypes.string,
  pos: PropTypes.number,
  notifyFocus: PropTypes.func,
  handleSelectItem: PropTypes.func,
  notifyMouseLeave: PropTypes.func,
  dropdownValue: PropTypes.string
}


export default SearchResponse