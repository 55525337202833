/* eslint-disable max-len */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const SVG = styled.svg`
  cursor: pointer;
  height: 100%;
  fill: ${props => props.color || '#888'};
  width: 100%;
`

const More = ({ color }) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" color={color}>
    <path d="M8,11.08A2.47,2.47,0,0,1,5.94,10a.68.68,0,1,1,1.17-.71c.23.39.88.4.89.4H8a1.1,1.1,0,0,0,.72-.32A.56.56,0,0,0,8.78,9V8.93a3.91,3.91,0,0,0-1.37-.5l-.09,0A1.92,1.92,0,0,1,6,7.33a1.72,1.72,0,0,1,.13-1.41,2,2,0,0,1,1.37-1,3.19,3.19,0,0,1,2.24.72.69.69,0,0,1-.87,1.07A1.75,1.75,0,0,0,7.76,6.3a.61.61,0,0,0-.42.33.42.42,0,0,0,0,.21c0,.07.14.17.37.25.89.18,2.31.61,2.42,1.6a2,2,0,0,1-.25,1.45,2.36,2.36,0,0,1-1.78.94Zm.71-2.26Z"/>
    <path d="M8,6.29a.69.69,0,0,1-.69-.69V4.34a.69.69,0,0,1,1.38,0V5.6A.69.69,0,0,1,8,6.29Z"/>
    <path d="M8,12.34a.68.68,0,0,1-.69-.68V10.4a.69.69,0,0,1,1.38,0v1.26A.68.68,0,0,1,8,12.34Z"/>
    <path d="M8,16a8,8,0,1,1,8-8A8,8,0,0,1,8,16ZM8,1.37A6.63,6.63,0,1,0,14.63,8,6.64,6.64,0,0,0,8,1.37Z"/>
  </SVG>
)

More.propTypes = {
  color: PropTypes.string
}

export default More

