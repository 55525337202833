import { combineReducers } from 'redux'
import customers from './customers.reducer'
import { routeReducer } from './routeApp.reducer'
import appReducer from './app.reducer'
import { deliveryMethodReducer } from './components/customer/deliveryMethod.reducer'
import { customerDetailsReducer } from './components/customer/customerDetails.reducer'
import searchResultsReducer from './components/searchResults/searchResults.reducer'
import contacts from './contacts.reducer'
import invoices from './components/customer/invoices.reducer'
import { usersReducer } from './users.reducer'

const rootReducer = combineReducers({
  appReducer,
  customersReducer: customers,
  contactsReducer: contacts,
  invoicesReducer: invoices,
  routeReducer, 
  customerDetailsReducer,
  searchResultsReducer,
  deliveryMethodReducer,
  usersReducer
})

export default rootReducer