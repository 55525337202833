import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { apiRequest } from '../../../../shared/apiRequest'
import { InputWrap } from '../../../shared/InputWrap'
import { default as NotesIcon } from '../../../../assets/svgs/Notes'
import styled from 'styled-components'
import FlatButton from '../../../shared/FlatButton'

const AddNotesButton = styled(FlatButton)`
  margin-left: 8px;
`

const DoneButton = styled(FlatButton)`
  grid-column: 2;
  grid-row: 2;
  justify-self: flex-end;  
  margin-top: 8px;
`

const NotesContainerWraper = styled.div`
display: grid;
grid-template-columns: 16px 1fr;
align-items: end;
column-gap: 8px;
justify-content: center;
padding: 32px 30px;
p {
  margin: 0px; 
  cursor: pointer;
  text-align: left;
  word-break: break-all;
  padding: 8px 8px 6px 8px;
  &:hover{
    background-color: #fafafa;
    border-bottom: 1.5px solid #e2e2e2;
  }
}

svg {
  width: 16px;  
  height: 16px;
  flex: none;
  align-self: flex-start;
  margin-top: 4px;
}

input.secondary{
  padding-top: 0;
}
`

class Notes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      note: '',
      baseNote: '',
      canBeSaved: false
    }
    
    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
  }

  async componentDidMount() {
    const response = await apiRequest('GET', `api/v1/notes/${this.props.invoiceNumber}`)

    if (response.data.length !== 0) {
      this.setState({
        note: response.data[0].Note,
        baseNote: response.data[0].Note
      })
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.invoiceNumber !== this.props.invoiceNumber) {
      const response = await apiRequest('GET', `api/v1/notes/${this.props.invoiceNumber}`)

      if (response.data.length !== 0) {        
        this.setState({
          note: response.data[0].Note,
          baseNote: response.data[0].Note
        })
      } else {
        this.setState(
          {
            isEditing: false,
            note: '',
            baseNote: '',
            canBeSaved: false
          }
        )
      }
    }
  }  
  
  handleBlur(){    
    this.setState({
      isEditing: false
    })
    this.handleSave()
  }

  handleFocus(){    
    this.setState({
      isEditing: true
    })
  }

  handleInput(e) {
    let canBeSaved = this.state.canBeSaved    
    if (this.state.baseNote.trim() !== e.target.value.trim()
      && ((this.state.baseNote.length === 0 && e.target.value.length > 0) || e.target.value.length > 0)) {
      canBeSaved = true
    }

    this.setState({
      note: e.target.value,
      canBeSaved: canBeSaved
    })
  }

  handleEnter(e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      this.handleSave()
      e.target.blur()
    }
  }

  async handleSave() {    
    this.setState({
      isEditing: false,
      note: this.state.note.trim()
    })

    if (this.state.canBeSaved) {
      await apiRequest('POST', 'api/v1/notes/create', {
        'Invoice_Number': this.props.invoiceNumber,
        'Note': this.state.note.trim()
      })
    }

  }

  render() {
    const { isEditing, note, className } = this.state
    return (
      <NotesContainerWraper>
        <NotesIcon/>
        {(note || isEditing) ? 
        <React.Fragment>
        <InputWrap 
          type="textarea" 
          value={note} 
          onChange={this.handleInput} 
          onKeyDown={this.handleEnter}
          onFocus={this.handleFocus}  
          onBlur={this.handleBlur}
          //autofocus={note.length ? 'false' : 'true'} this only works the first time
           />
          { isEditing ? <DoneButton          
          id='Done'
          onClick={this.handleSave}>                    
          Done
          </DoneButton> : null}
        {/* { isEditing ? <DoneButton          
          type='button'
          name='Done'
          value='Done'
          id='Done'
          onClick={this.handleSave}          
          btnType="secondary"               
        /> : null} */}
        </React.Fragment>
        : <AddNotesButton                    
          id='add-notes'
          onClick={this.handleFocus}>
        Add Notes
        </AddNotesButton>        
        // : <AddNotesButton          
        //   type='button'
        //   name='add notes'
        //   value='add notes'
        //   id='add-notes'
        //   onClick={this.handleFocus}      
        //   btnType="secondary"          
        // />
        }
      </NotesContainerWraper>
    )
  }
}

Notes.propTypes = {
  invoiceNumber: PropTypes.string
}

export default Notes