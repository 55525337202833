import React from 'react'
import RightDrawerLayout from '../../../layouts/RightDrawerLayout'
import InvoiceInformation from '../InvoiceInformation'
import LeftMenu from '../../shared/LeftMenu'
import leftMenuSettings from './leftMenuSettings'
import SimpleModal from '../../shared/SimpleModal/Modal'
import { Route } from 'react-router-dom'
import Contacts from '../../../containers/components/customer/contacts/Contacts.container'
import AutoDelivery from '../../../containers/components/customer/autoDelivery/autoDelivery.container'
import InvoicesView from '../invoices/InvoicesView'
import { AuthConsumer } from '../../../auth/AuthContext'

const CustomerDetailsView = (props) => {
  return (
    <RightDrawerLayout
      isRightMenuOpen={props.detailedInvoice && true}
      rightMenu={
        props.detailedInvoice &&
        <InvoiceInformation
          invoice={props.detailedInvoice}
          clearDetailedInvoice={props.clearDetailedInvoice}
          changeDisputingStatus={props.changeDisputingStatus}
        />
      }
      title={props.customer && props.customer.Name}
      subtitle={props.customer && props.customer.Accounting_Code}
      avatarName={props.currentUser.currentUser && props.currentUser.currentUser.data.user_name}
      clearCustomerDetails={props.clearCustomerDetails}
      isStarred={(props.favorites && props.customer) && props.favorites.includes(props.customer.Miranda_ID)}
      handleStarClick={props.handleStarClick}  
      customer={props.customer}
    >
      <div className="content-main">
        <LeftMenu
          customer={props.customer}
          invoices={props.invoices}
          clearDetailedInvoice={props.clearDetailedInvoice}
          currentURL={props.currentURL}
          leftMenu={leftMenuSettings(props.handleLeftMenu, props.toggleDeliveryMethodModal, props.onRequest)}
          count={props.invoicesCounter}
          filterValue={props.filterValue}
        />

        <Route
          path={props.currentURL + '/invoices'}
          render={routeProps => {          
            return (
              <InvoicesView
                {...routeProps}
                filterValue={props.filterValue}
                filterBy={props.filterBy}
                setSelectAllInvoices={props.setSelectAllInvoices}
                setSelectOneInvoice={props.setSelectOneInvoice}
                invoiceSelected={props.invoiceSelected}
                setInvoiceSelected={props.setInvoiceSelected}
                tableLength={props.tableLength}
                handleInfiniteScroll={props.handleInfiniteScroll}      
                detailedInvoice={props.detailedInvoice}
                isCustomEmailModalOpen={props.isCustomEmailModalOpen}
                toggleCustomEmailModal={props.toggleCustomEmailModal}
                handleDownloadDocument={props.handleDownloadDocument}
                sendEmail={props.sendEmail}
                sendToPrintQueue={props.sendToPrintQueue}
                contacts={props.contacts}
                countSelectedInvoices={props.countSelectedInvoices}
                handleSort={props.handleOrderInvoices}
                sort={props.sort}
                invoices={props.invoices}
                customer={props.customer}
                customerID={props.customerID}
                clearDetailedInvoice={props.clearDetailedInvoice}
                setDetailedInvoice={props.setDetailedInvoice}
              />
            )
          }}
        />

        <Route
          path={props.currentURL + '/contacts'}
          render={props => <Contacts {...props} customerID={props.customerID} />}
        />

        <SimpleModal
          showModal={props.isDeliveryMethodModalOpen}
          width="496px"
          height="428px"
        >
          <AutoDelivery
            handleClose={props.toggleDeliveryMethodModal}
            customerID={props.customerID}
          />
        </SimpleModal>
      </div>
    </RightDrawerLayout>
  )
}



const CustomerDetailsViewWithUser = (props) => (
  <AuthConsumer>
    {currentUser => (
      <CustomerDetailsView {...props} currentUser={currentUser} />
    )}
  </AuthConsumer>
)

export default CustomerDetailsViewWithUser
