import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Login from './auth/Login'
import Logout from './auth/Logout'
import Home from './containers/components/home.container'
import ProtectedRoute from './auth/ProtectedRoute'
import { connect } from 'react-redux'
import SearchModal from './components/shared/SearchModal'
import { setOpenSearchModal, selectOptionInSearchModal } from './actions/route'
import { searchInvoices, setSearchedInvoices } from './actions/components/customer/invoices'
import { Switch, Route, withRouter  } from 'react-router-dom'
import CustomerDetailsContainer from './containers/components/customer/CustomerDetails.container'
import SearchResultsContainer from './containers/components/searchResults/SearchResults.container'
import ClickOutsideWrapper from './components/shared/SearchModal/ClickOutsideWrapper'
import NotFound from './components/404/NotFound'
import GlobalLoader from './components/shared/GlobalLoader'


class RouteApp extends Component {
  render() {
    return (
      <React.Fragment>
        {/* 
        Please do not remove this component. In research
        <Snackbar /> */}
        <Switch>
          <Route exact path='/login' component={Login} />
          <Route exact path='/logout' component={Logout} />
          <ProtectedRoute allowedGroups={['general']} exact path='/' component={Home} />
          <ProtectedRoute allowedGroups={['general']} path='/customer/:idcustomer' 
            component={CustomerDetailsContainer} />
          <ProtectedRoute allowedGroups={['general']} path='/invoices/search-results' 
            component={SearchResultsContainer} />  
          <Route exact path="/404" component={NotFound}/>
          <Route component={NotFound}/>
        </Switch>

        {this.props.openSearchModal &&
          <ClickOutsideWrapper
            outsideClickIgnoreClass='modal-dialog'
            handleModal={this.props.handleModal}
          >
            <SearchModal
              show
              setSearchedInvoices={this.props.setSearchedInvoices}
              isOpen={this.props.openSearchModal}
              handleSelectItem={this.props.selectElementModal}
              onHide={true}
              handleModal={this.props.handleModal}
              enableOnClickOutside={true}
              searchInvoices={this.props.searchInvoices}
            />
          </ClickOutsideWrapper> 
        }
        <GlobalLoader/>
      </React.Fragment>
    )
  }
}

RouteApp.propTypes = {
  handleModal: PropTypes.func,
  openSearchModal: PropTypes.bool,
  selectElementModal: PropTypes.func,
  searchInvoices: PropTypes.func
}

export const mapStateToProps = (state) => ({
  openSearchModal: state.routeReducer.openSearchModal,
})

export const mapDispatchToProps = (dispatch) => ({
  handleModal: () => dispatch(setOpenSearchModal()),
  selectElementModal: (value) => dispatch(selectOptionInSearchModal(value)),
  searchInvoices: (invoices) => dispatch(searchInvoices(invoices)),
  setSearchedInvoices: (invoices) => dispatch(setSearchedInvoices(invoices))

})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteApp))

