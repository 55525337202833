import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
// import Menu from '../../assets/svgs/MenuIcon'
import Menu from '../../assets/svgs/Menu'
import SearchIcon from '../../assets/svgs/SearchIcon'
import Avatar from '../../components/shared/Avatar'

const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  height: 72px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 0 40px;
  position: fixed;
  z-index: 1;
  background-color: #fff;
  transition: width .08s ease-in-out;

  svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }

  h2 {
    display: flex;
    margin-left: 40px;
    /* margin-left 40px when StyledMenu is active, remove 108px */
    /* margin-left: 40px; */

    .nav-bar-arrow svg {
      margin: ${props => props.isCustomerView ? '20px' : '0'} 16px 0 0;

      &:hover {
        fill: #252839;
      }
    }

    svg {
      margin-left: 12px;
    }
  }
`

const StyledBrand = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 16px;
    cursor: default;
  }

  p {
    width: 82px;
  }
`

// const StyledMenu = styled.div`
//   display: flex;
//   svg {
//     width: 20px;
//     fill: #252839;
//   }
// `

const StyledNav = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  div {
    display: flex;

    svg.search-icon {
      margin-right: 24px;
    }
  }
`

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.handleRedirect = this.handleRedirect.bind(this)
  }

  handleRedirect() {
    this.props.clearCustomerDetails()
    window.location.replace('/')
  }

  render() {
    const isCustomerView =  this.props.match.path.indexOf('/customer') !== -1
    return (
      <StyledNavbar isCustomerView={isCustomerView}>
        <StyledBrand>
          <Menu color="#252839" />
        </StyledBrand>
        {/* Menu when there are other options, remove StyledBrand */}
        {/* <StyledMenu>
          <Menu />
        </StyledMenu> */}
        {this.props.title}
        <StyledNav>
          <div onClick={this.props.handleSearchButton}>
            <SearchIcon className="search-icon" />
          </div>
          <Avatar name={this.props.avatarName} />
        </StyledNav>
      </StyledNavbar>
    )
  }
}

Navbar.propTypes = {
  customer: PropTypes.object,
  handleSearchButton: PropTypes.func,
  clearCustomerDetails: PropTypes.func,
  title: PropTypes.string,
  avatarName: PropTypes.string,
  match: PropTypes.shape({
    path: PropTypes.string
  }).isRequired
}

export default Navbar
