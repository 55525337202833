/* eslint-disable react/no-unescaped-entities */

import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Typography from '../shared/Typography'
import CaseStackLogo from '../../assets/svgs/CaseStackFilled';

const Wrapper = styled.div`
  left: 50%;
  position: absolute;
  top: 137px;
  text-align: center;
  transform: translateX(-50%) translateY(-50%);

  h4:not(:last-child) {
    margin-bottom: 4px;
  }

  svg {
    margin-bottom: 17px;
    width: 48px; 
  }
`

const LinksWrapper = styled.div`
  margin-top: 40px;

  a:hover {
    text-decoration: none;
  }

  p:not(:last-child) {
    margin-bottom: 8px;
  }
`

const NotFound = () => {
  return (
    <Wrapper>
      <CaseStackLogo color='#f8c013' />
      <Typography element="h4" color="#888888">404 error</Typography>
      <Typography element="h4">We can't find the page you're looking for.</Typography>

      <LinksWrapper>
        <Typography element="p">These links might help:</Typography>

        <Link to="/">
          <Typography element="p" color="#6887b4">Customers</Typography>
        </Link>
      </LinksWrapper>

    </Wrapper>
  )
}

export default NotFound