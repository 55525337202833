import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../NewMenu'
import Plane from '../../../assets/svgs/Plane'
import Customer from '../../../assets/svgs/Customer'
import Account from '../../../assets/svgs/Account'
import Controls from '../../../assets/svgs/Controls'
import Download from '../../../assets/svgs/Download'
import Print from '../../../assets/svgs/Print'
import More from '../../../assets/svgs/More'
import styled from 'styled-components'

const SingleMenuWrapper = styled.div`
  div.reference{
    ul.mainMenu{
    }
  }

`

const SingleInvoiceMenu = (props) => {
  const menuOptions = [
    {
      id: 1,
      text: 'Send',
      iconLeft: <Plane />,
      hasChildren: true,
      options: [
        {
          id: 1,
          text: 'Quick send to',
          iconLeft: <Plane />,
          hasChildren: true,
          options: [
            {
              id: 1,
              text: 'Customer contacts',
              disabled: props.contacts.length === 0,
              onClick: () => props.handleSendEmail({to: props.contacts}),
              iconLeft: <Customer />
            },
            {
              id: 2,
              text: 'Myself',
              onClick: props.handleSendEmail,
              iconLeft: <Account />
            }
          ]
        },
        {
          id: 2,
          text: 'Custom',
          iconLeft: <Controls />,
          onClick: props.toggleCustomEmailModal
        }
      ]
    },
    {
      id: 2,
      text: 'Download',
      iconLeft: <Download />,
      onClick: props.handleDownloadDocument
    },
    {
      id: 3,
      text: 'Add to print queue',
      iconLeft: <Print />,
      onClick: props.sendToPrintQueue
    }
  ]

  return (
    <SingleMenuWrapper>
      <Menu
        options={menuOptions}
        handleMenu={(isOpen) => { props.openIndividualAction(props.invoice) }}
      >
        <More />
      </Menu>

    </SingleMenuWrapper>
  )
}

SingleInvoiceMenu.propTypes = {
  handleSendEmail: PropTypes.func,
  handleDownloadDocument: PropTypes.func,
  sendToPrintQueue: PropTypes.func,
  openIndividualAction: PropTypes.func,
  invoice: PropTypes.object,
  toggleCustomEmailModal: PropTypes.func
}

export default SingleInvoiceMenu