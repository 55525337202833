/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Star = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.9 15.98">
    <path d="M2.52,16L2.6,15s0.29-3.39.38-4.39L1.46,8.15,0,5.88l5-1L8.08,0l3,4.92,4.86,1-3,4.75L13.31,16l-0.92-.31L8.07,14.07l-4.61,1.6ZM2.14,6.79l2.05,3.32L4.31,10.3l0,0.22c0,0.35-.21,2.36-0.31,3.6l3.87-1.34,0.25-.09,0.24,0.11,3.53,1.29-0.27-3.73,2.24-3.53-3.57-.74L8.07,2.48,5.85,6.06Z" transform="translate(-0.05 -0.01)"/>
  </SVG>
)

export default Star