import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../../shared/NewMenu'
import PrimaryButton from '../../shared/PrimaryButton';
import Button from '../../shared/Button'
import Plane from '../../../assets/svgs/Plane'
import Customer from '../../../assets/svgs/Customer'
import Account from '../../../assets/svgs/Account'
import CaseStackFilled from '../../../assets/svgs/CaseStackFilled'
import Controls from '../../../assets/svgs/Controls'
import styled from 'styled-components/macro'

const MenuWrapper = styled.div`
  div.reference {
    div {
      > div {
        height: 40px;
        width: 97px;
        padding: 0 16px;
        input {
          padding: 0;
        }
      }
    }
  }

  div {
    ul.mainMenu {
      left: calc(100% - 198px);
      margin-top: 7px;
      width: 198px;

      div.reference {
        div {
          padding: 0;
        }
      }
    }
  }
`

const SendMenu = props => {
  return (
    <MenuWrapper>
      <Menu
        options={[
          {
            id: 1,
            text: "Quick send to",
            iconLeft: <Plane />,
            hasChildren: true,
            options: [
              {
                id: 1,
                text: "Customer contacts",
                disabled: props.contacts.length === 0,
                onClick: () => {
                  props.handleSendEmail({ to: props.contacts })
                },
                iconLeft: <Customer />
              },
              {
                id: 2,
                text: "Myself",
                onClick: props.handleSendEmail,
                iconLeft: <Account />
              }
            ]
          },
          { divider: true },
          {
            id: 2,
            text: "Custom",
            onClick: props.toggleCustomEmailModal,
            iconLeft: <Controls />
          }
        ]}
        disabled={props.disabled}
      >
        <PrimaryButton
          left = {<Plane />}
          disabled={props.disabled}>          
          Send
        </PrimaryButton>
      </Menu>
    </MenuWrapper>
  )
}

SendMenu.propTypes = {
  toggleCustomEmailModal: PropTypes.func,
  handleSendEmail: PropTypes.func,
  disabled: PropTypes.bool
}

export default SendMenu
