import React from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import styled from 'styled-components/macro'

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 40px 0;
  position: fixed;
  width: calc(100% - 342px);
  background-color: #fff;
  z-index: 1;
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 4px;

    &.with-link:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

const HeaderOptions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  p {
    margin: 0;
    margin-right: 40px;
  }
  p + div{
          ul.mainMenu{
            margin-top:8px;
            left: calc(100% - 346px); 
          }
      }
  div + div {
    div.reference{
      ul.mainMenu{
        .reference{       
          .subMenu {
            left: -233px;
            top: 0px;
          }
        }
      }
    }
  }

`

const TableTitle = ({ title, subtitle, selectedItems, headerButtons, handleClickOnTitle, data }) => {
  return (
    <Title className="table-title">
      <HeaderTitle>
        {data.length > 0 && (
          <React.Fragment>
            <Typography element="h3" onClick={handleClickOnTitle !== null ? handleClickOnTitle : null} className={handleClickOnTitle !== null ? 'with-link' : ''}>
              {title}
            </Typography>
            <Typography element="p" color='#888888'>
              {subtitle}
            </Typography>
          </React.Fragment>
        )}
      </HeaderTitle>
      <HeaderOptions>
        {selectedItems > 0 && (
          <Typography element="p" color="#888888">
            {selectedItems} selected
          </Typography>
        )}
          {headerButtons && headerButtons.map(button => {
            return button
          })}
      </HeaderOptions>
    </Title>
  )
}

TableTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedItems: PropTypes.number,
  headerButtons: PropTypes.array
}

export default TableTitle