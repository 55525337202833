const getInitialLetters = (name) => {
  if (name.length === 0) {
    return ''  
  }

  if (name.split(' ').length > 1) {
    let initials = name.split(' ').map((obj) => obj.split(''))
    
    return (initials[0][0] && initials[1][0]) ?
      (initials[0][0].toUpperCase() + initials[1][0].toUpperCase()) : ('') 
  }
  
  return (name[0]) ? (name[0].toUpperCase()) : ('')
}

export default getInitialLetters