export const FILTER_BY = {
  STATUS: 'STATUS',
  CUSTOMER: 'CUSTOMER',
}

export const CUSTOMER = {
  NOT_FOUND: 'NotFound',
  FOUND: 'Found'
}

export const STATUS = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  ALL: 'All'
}