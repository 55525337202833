import { apiRequest } from '../shared/apiRequest'

export const ON_REQUEST = '@indigo/deliveryMethod/ON_REQUEST'
export const ON_REQUEST_SUCCESS = '@indigo/deliveryMethod/ON_REQUEST_SUCCESS'
export const ON_REQUEST_FAILURE = '@indigo/deliveryMethod/ON_REQUEST_FAILURE'
export const DISPLAY_DELIVERY_METHOD = '@indigo/deliveryMethod/DISPLAY_DELIVERY_METHOD'
export const TOGGLE_DELIVERY_METHOD_MODAL = '@indigo/deliveryMethod/TOGGLE_DELIVERY_METHOD_MODAL'
export const CHANGE_DELIVERY_METHOD_LOCAL = '@indigo/customers/CHANGE_DELIVERY_METHOD_LOCAL'
export const CANCEL_CHANGE_DELIVERY_METHOD_LOCAL = '@indigo/customer/CANCEL_CHANGE_DELIVERY_METHOD_LOCAL'
export const onRequest = () => {
  return {
    type: ON_REQUEST
  }
}

export const onRequestSuccess = () => {
  return {
    type: ON_REQUEST_SUCCESS
  }
}

export const onRequestFailure = () => {
  return {
    type: ON_REQUEST_FAILURE
  }
}

export const displayDeliveryMethod = (deliveryMethod) => {
  return {
    type: DISPLAY_DELIVERY_METHOD,
    payload: {
      deliveryMethod
    }
  }
}

export const changeDeliveryMethodLocal = (value) => {
  return {
    type: CHANGE_DELIVERY_METHOD_LOCAL,
    payload: {
      value
    }
  }
}

export const toggleDeliveryMethodModal = (value) => {
  return {
    type: TOGGLE_DELIVERY_METHOD_MODAL,
    payload: {
      value
    }
  }
}

export const onCancelDeliveryMethodChange = () => (dispatch) => {
  dispatch(toggleDeliveryMethodModal())
}

export const onUpdateDeliveryMethod = (setDefaultAutoDeliveryMethod = false) => (dispatch, getState) => {
  const { customersReducer } = getState()
  const dataToUpdate = {
    Accounting_Code: customersReducer.selectedCustomer.Accounting_Code,
    Delivery_Method: customersReducer.selectedCustomer.Delivery_Method
  }

  return apiRequest('put', 'api/v1/customers/update', dataToUpdate)
    .then((response) => {
      !setDefaultAutoDeliveryMethod ?
        dispatch(toggleDeliveryMethodModal()) :
        dispatch(displayDeliveryMethod(response.data))

      dispatch(onRequestSuccess)
    })
    .catch(() => {
      dispatch(onRequestFailure)
    })
}

export const onFetchDeliveryMethod = (customerId) => (dispatch, getState) => {
  getState()
}