import React, {Component} from 'react';
import styled from 'styled-components';

//convert to hex once you find out the thing Jackie was talking about
const ButtonWrapper = styled.div`  
    display: flex;    
    align-items: center;    
    width: min-content;
    height: 40px;
    padding: 0 16px;   
    background-color: ${props => props.disabled ? '#e2e2e2' : '#6887b4'};
    cursor: ${props => props.disabled ? 'unset' : 'pointer'};
    &:hover{
        box-shadow: ${props => props.disabled ? 'unset' : '0 2px 4px 0 #0000004C'};
    }
    svg{
        margin-left: ${props => props.right ? '8px' : '0'};
        margin-right: ${props => props.left ? '8px' : '0'};
    }
`;

const Button = styled.input`
    cursor: pointer;
    background-color: inherit;
    font-size: 14px;
    font-weight: 600;  
    line-height: 1.43;
    letter-spacing: 1px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0;
    border: none;    

    &:disabled{
        color: #888;
        cursor: unset;  
    }
`;

const AddFill = (icon, props) => {
    return  Object.assign({}, icon, {props: {color: props.disabled ? '#888' : '#fff'}});
};

export default class extends Component{
    render(){
        return (
            <ButtonWrapper onClick = {this.props.onClick} disabled = {this.props.disabled} left = {this.props.left} right = {this.props.right}>    
                {this.props.left && AddFill(this.props.left, this.props)}                
                <Button value={this.props.children} readOnly = {true} type = 'button' disabled={this.props.disabled}></Button>
                {this.props.right && AddFill(this.props.right, this.props)}                
            </ButtonWrapper>
        );
    };
}