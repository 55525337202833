import React from 'react'
import PropTypes from 'prop-types'
import { SingleCheckbox } from '../SingleCheckbox'
import Typography from '../Typography'
import ArrowDownward from '../../../assets/svgs/ArrowDownward'
import styled from 'styled-components'

const StyledTH = styled.th`
  p { 
    color: #888888;    
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  &.sortable:hover {
    p {
      color: #252839;
      svg {
        fill: #252839;
      }
    }
  }

`
const StyledTR = styled.tr`
  ${props => !props.headerStyles ? props.headerStyles : null}
  th:nth-child(1){
    p{
      margin-left: 24px !important;
    }
  }

`
const StyledThead = styled.thead`
  &.table-header {
    tr{
      th{
        vertical-align: unset;
        border-bottom: 1px solid #ccc;
        padding: 0 16px 12px 0;

      &.sortable {
          cursor: pointer;
      }

      &.checkbox-table {
        width: 16px;
        padding: 0 24px 16px 24px;
      }

      &:not(.checkbox-table) {
          svg {                        
              margin-left: 7px;
              width: 10px;
              height: 5px;
          }                     
      }

      &.right {
        padding: 0 24px 12px 0;
        p {
          float: right;
        }
      }

      }
    }
  }
`

const TableHeader = (props) => {
  return (
    <StyledThead className="table-header">
      <StyledTR
        headerStyles={props.headerStyles}>
        {props.checkable &&
          <th className="checkbox-table">
            <SingleCheckbox
              disabled={props.data.length === 0}
              checked={props.isSelectAll}
              onChange={props.handleSelectAll}
            />
          </th>
        }
        {props.header().map(column => {
          return (
            !column.hideWhen && (
              <StyledTH
                key={column.id}
                onClick={column.isSortable ? () => props.handleSort(props.order, column.id) : null}
                className={`${column.isSortable ? 'sortable' : ''} ${column.align === 'right' ? 'right' : ''}`}>
                <Typography element="p">
                  {column.label}
                  {column.isSortable && column.id === props.sortBy && <ArrowDownward orientation={!props.order ? 'up' : ''} />}
                </Typography>
              </StyledTH>
            )
          )
        })}
      </StyledTR>
    </StyledThead>
  )
}

TableHeader.propTypes = {
  data: PropTypes.array,
  isSelectAll: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  header: PropTypes.func,
  handleSort: PropTypes.func,
  order: PropTypes.bool,
  sortBy: PropTypes.string,
}

export default TableHeader
