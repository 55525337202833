import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import SimpleModal from '../../shared/SimpleModal/Modal'
import EmailForm from './emails'
import InvoicesMenu from '../../shared/CustomMenus/InvoicesMenu'
import SendMenu from '../../shared/CustomMenus/SendMenu'
import SingleInvoiceMenu from '../../shared/CustomMenus/SingleInvoiceMenu'
import tableColumns from '../../shared/Table/tableColumns'
import Table from '../../shared/Table'
import Loader from '../../../components/shared/Loader'
import getMoney from './../../../shared/utils/getMoney'
import Snackbar from '../../shared/Snackbar'

const headerStyles = `
  th:nth-child(6){
    padding-left: 26px;
  }
`

class InvoicesView extends Component {
  render() {
    const {
      customer,
      invoices,
      filterValue,
      isCustomEmailModalOpen,
      toggleCustomEmailModal,
      
    } = this.props

    let contacts = []

    let contactsEmails = []

    const selectedInvoices = filterValue && invoices ? invoices.data.filter(invoice => invoice.checked).length : 0

    if (customer) {
      contactsEmails = customer.Customer_Emails.map(contact => contact.Email) 
      contacts = customer.Customer_Emails
    }

    return (
      <React.Fragment>
        <Snackbar/>

        <SimpleModal
          showModal={isCustomEmailModalOpen}
          width="496px"
          height="358px"
        >
          <EmailForm
            toggleCancel={toggleCustomEmailModal}
            contacts={contacts}
            countSelectedInvoices={selectedInvoices}
            sendEmail={this.props.sendEmail}
          />
        </SimpleModal>

        <Col className="invoices-info">
          {(!this.props.customer || !this.props.filterValue) ? (<Loader />) : (
            
            <Table
              headerStyles={headerStyles}
              title={filterValue + ' invoices'}
              subtitle={`$${getMoney(this.props.customer.Amount_Due)} due`}
              headerButtons={[
                <InvoicesMenu
                  key="invoices-menu"
                  handleDownloadDocument={this.props.handleDownloadDocument}
                  sendToPrintQueue={this.props.sendToPrintQueue}
                />,
                <SendMenu
                  key="send-menu"
                  contacts={contactsEmails}
                  handleSendEmail={this.props.sendEmail}
                  toggleCustomEmailModal={this.props.toggleCustomEmailModal}
                />
              ]}
              header={() => tableColumns(!!this.props.detailedInvoice)}
              data={invoices ? invoices.data : []}
              defaultLength={this.props.tableLength}
              maxLength={invoices ? invoices.totalInvoices : []}              
              handleInfiniteScroll={this.props.handleInfiniteScroll}
              filterValue={filterValue}
              handleSelectAll={this.props.setSelectAllInvoices}
              handleSelectOne={this.props.setSelectOneInvoice}
              rightArrow={true}
              handleSort={this.props.handleSort}
              handleRightArrow={this.props.setDetailedInvoice}
              rowSelected={this.props.detailedInvoice}
              rowMenu={
                <SingleInvoiceMenu
                  handleDownloadDocument={this.props.handleDownloadDocument}
                  sendToPrintQueue={this.props.sendToPrintQueue}
                  toggleCustomEmailModal={this.props.toggleCustomEmailModal}
                  handleSendEmail={this.props.sendEmail}
                  contacts={contactsEmails}
                  openIndividualAction={this.props.setInvoiceSelected}
                />
              }
            />
          )}
        </Col>
      </React.Fragment>
    )
  }
}

InvoicesView.propTypes = {
  customer: PropTypes.object,
  invoices: PropTypes.array,
  filterValue: PropTypes.string,
  isCustomEmailModalOpen: PropTypes.bool,
  toggleCustomEmailModal: PropTypes.func,
  sendEmail: PropTypes.func,
  handleDownloadDocument: PropTypes.func,
  sendToPrintQueue: PropTypes.func,
  detailedInvoice: PropTypes.object,
  tableLength: PropTypes.number,
  handleInfiniteScroll: PropTypes.func,
  setSelectAllInvoices: PropTypes.func,
  setSelectOneInvoice: PropTypes.func,
  handleSort: PropTypes.func,
  setDetailedInvoice: PropTypes.func,
  setInvoiceSelected: PropTypes.func
}

export default InvoicesView
