import {
  ON_REQUEST,
  ON_REQUEST_FAILURE,
  ON_REQUEST_SUCCESS,
  DISPLAY_CUSTOMERS,
  SET_SELECTED_CUSTOMER,
  SET_STARRED_CUSTOMERS,
  ADD_STARRED_CUSTOMER,
  REMOVE_STARRED_CUSTOMER,
  SET_MIRANDA_CUSTOMERS,
  ADD_CONTACT,
  DELETE_CONTACT,
  EDIT_CONTACT
} from '../actions/customers.actions'

import {CHANGE_DELIVERY_METHOD_LOCAL, CANCEL_CHANGE_DELIVERY_METHOD_LOCAL} from '../actions/deliveryMethod.action'

const initialState = {
  customers: [],
  starredCustomers: null,
  mirandaCustomers: [],
  isFetching: true,
  onRequest: false,
  isSuccess: false,
  isError: false,
  selectedCustomer: null
}

export default (state = initialState, action) => {
  switch (action.type) {
  
  case ON_REQUEST:
    return {
      ...state,
      isFetching: true
    }

  case ON_REQUEST_FAILURE:
    return {
      ...state,
      isFetching: false,
      isError: true
    }


  case ON_REQUEST_SUCCESS:
    return {
      ...state,
      isFetching: false,
      isError: false
    }
  
  case DISPLAY_CUSTOMERS:  
    return {
      ...state,
      customers: action.payload.customers.slice(0, 15), // This slice() is while we implement the infinite scroll
      isFetching: false,
      isError: false
    }  

  case SET_SELECTED_CUSTOMER: 
    return {
      ...state,
      selectedCustomer: action.payload.customer
    }  
  
  case CHANGE_DELIVERY_METHOD_LOCAL: 
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        Delivery_Method: action.payload.value
      }
    }  
  
  case CANCEL_CHANGE_DELIVERY_METHOD_LOCAL:
    return {
      ...state,
      selectedCustomer:{
        ...state.selectedCustomer,
        Delivery_Method: state.Delivery_Method === 'Print' ? 'Email': 'Print'
      }
    }
  
  case SET_STARRED_CUSTOMERS: 
    return {
      ...state,
      starredCustomers: action.payload.starredCustomers
    }  

  case ADD_STARRED_CUSTOMER: 
    return {
      ...state,
      starredCustomers: [...state.starredCustomers, action.payload.customer]
    }

  case REMOVE_STARRED_CUSTOMER: 
    return {
      ...state,
      starredCustomers: state.starredCustomers.filter(customer => {
        return customer.Miranda_ID !== action.payload.customer.Miranda_ID
      })
    }

  case SET_MIRANDA_CUSTOMERS:
    return {
      ...state,
      mirandaCustomers: [...action.payload.mirandaCustomers]
    }
  
  case ADD_CONTACT:
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        Customer_Emails:[...state.selectedCustomer.Customer_Emails, action.payload.contact]
      }
    }
  case DELETE_CONTACT:
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        Customer_Emails: state.selectedCustomer.Customer_Emails.filter(contact => contact._id !== action.payload.id),
      }
    }
  case EDIT_CONTACT:
    return {
      ...state,
      selectedCustomer: {
        ...state.selectedCustomer,
        Customer_Emails: state.selectedCustomer.Customer_Emails.map(contact => {
          if (contact._id === action.payload.contact._id) {
            contact = action.payload.contact
          }
          return contact
        })
      }
    }
  default:
    return state
  }
}

