import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'cs-reusable-component-library-exporter'

const Overlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
`

const GlobalLoader = ({ isPerformingAnAction }) => {
  return (
    <>
      {
        isPerformingAnAction && (
          <Overlay>
            <div>
              <Spinner spinning size="large" />
            </div>
          </Overlay>
        )
      }
    </>
  )
}

function mapStateToProps(state) {
  return { isPerformingAnAction: state.appReducer.isPerformingAnAction }
}

export default connect(mapStateToProps)(GlobalLoader)
