import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableTitle from './TableTitle'
import TableView from './TableView'

const orderBy = (data, field, order) => {
  if (order) {
    return data.sort(function(a,b){
      return new Date(b[field]) - new Date(a[field])
    })
  } else {
    return data.sort(function(a,b){
      return new Date(a[field])- new Date(b[field])
    })
  }
}

class Table extends Component {
  constructor(props) {
    super(props)

    this.state = {
      // isSelectAll: false,
      filterValue: '',
      data: [],
      visibleData: [],
      defaultHasMoreLoader: <b>Loading...</b>,
      defaultNoDataFoundMessage:  <b>Invoices not found</b>,      
      sortBy: null,
      order: false // true: desc; false: asc
    }
    
    this.handleInfiniteScroll = this.handleInfiniteScroll.bind(this)

    this.handleRightArrow = this.handleRightArrow.bind(this)
    this.handleSelectAll = this.handleSelectAll.bind(this)
    this.handleSelectOne = this.handleSelectOne.bind(this)
    this.calculateSelectedItems = this.calculateSelectedItems.bind(this)
    this.handleDefaultLoader = this.handleDefaultLoader.bind(this)
    this.handleDefaultOrder = this.handleDefaultOrder.bind(this)
    this.handleClickOnTitle = this.handleClickOnTitle.bind(this)
    this.handleSort = this.handleSort.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    
    if (props.filterValue === 'noFilter') {      
      return {
        data: props.data,
        // visibleData: props.data.slice(0, props.defaultLength)
      }
    }

    if (props.filterValue !== state.filterValue) {
      return {
        filterValue: props.filterValue,
        data: props.data,
        // visibleData: props.data.slice(0, props.defaultLength),
        // isSelectAll: Table.setSelectAll(props.data, props.data.slice(0, props.defaultLength))
      }
    } else{
      return{
        data: props.data,        
      }
    }
  }

  componentDidMount() {
    this.setState({
      sortBy: this.handleDefaultOrder(this.props.header())
    })
  }

  handleDefaultOrder(headers) {
    const sortableHeaders = []
    
    headers.forEach(header => {
      if (header.isSortable) {
        sortableHeaders.push(header.id)      
      }
    }) 
    
    return sortableHeaders[0]
  }

  handleRightArrow(invoice) {
    this.props.handleRightArrow(invoice)
  }

  handleSelectOne(itemSelected) {
    this.props.handleSelectOne(itemSelected)
  }

  handleSelectAll() {
    this.props.handleSelectAll(this.state.isSelectAll)

    this.setState({
      isSelectAll: !this.state.isSelectAll
    })
  }

  handleDefaultLoader() {
    const { hasMoreLoader, noDataFoundMessage } = this.props
    const { defaultHasMoreLoader, defaultNoDataFoundMessage } = this.state

    return {
      hasMoreLoader: hasMoreLoader ? hasMoreLoader : defaultHasMoreLoader,
      noDataFoundMessage: noDataFoundMessage ? noDataFoundMessage : defaultNoDataFoundMessage
    }
  }

  handleSort(order, field) {
    this.props.handleSort(order, field)

    this.setState({
      order: !this.state.order
    })
    
  }

  calculateSelectedItems() {
    return this.state.data.filter(item => item.checked).length
  }

  handleInfiniteScroll() {
    this.props.handleInfiniteScroll(this.state.order)
  }

  handleClickOnTitle() {
    this.props.handleClickOnTitle()
  }

  render() {
    return (
      <React.Fragment>
        <TableTitle
          title={this.props.title}
          handleClickOnTitle={this.props.handleClickOnTitle ? this.handleClickOnTitle : null}
          subtitle={this.props.subtitle}
          selectedItems={this.calculateSelectedItems()}
          headerButtons={this.props.headerButtons && this.props.headerButtons.map(button => {
            return React.cloneElement(button, {
              disabled: !this.props.data.filter(item => item.checked).length > 0
            })
          })}
          data={this.props.data}
        />
        
        <TableView 
          headerStyles={this.props.headerStyles}
          rowStyles = {this.props.rowStyles}
          header={this.props.header}
          checkable={this.props.checkable}

          data={this.props.data}
          isSelectAll={this.props.data.length > 0 && this.props.data.filter(element => element.checked).length === this.props.data.length}
          defaultLength={this.props.defaultLength}
          rowSelected={this.props.rowSelected}

          handleSelectAll={this.handleSelectAll}
          handleSelectOne={this.handleSelectOne}
          loader={this.handleDefaultLoader()}
          handleInfiniteScroll={this.handleInfiniteScroll}          
          hasMoreItems={this.props.data.length < this.props.maxLength}

          rightArrow={this.props.rightArrow}
          handleRightArrow={this.props.rightArrow ? this.handleRightArrow : null}
          rowMenu={this.props.rowMenu}
          sortBy={this.state.sortBy}
          order={this.state.order}
          handleSort={this.handleSort}
        />  
      </React.Fragment>
    )
  }
}

Table.defaultProps = {
  defaultLength: 15,
  checkable: true
}


Table.propTypes = {
  handleRightArrow: PropTypes.func
  // title: PropTypes.string,
  // subtitle: PropTypes.string,
  // headerButtons: PropTypes.array



  // header: PropTypes.func,
  // handleSelectAll: PropTypes.func,
  // hasMoreLoader: PropTypes.string,
  // noDataFoundMessage: PropTypes.string
}

export default Table
