import { apiRequest } from './../../../shared/apiRequest'
import { setSelectedCustomer } from '../../app.actions'
import { onFetchContactsByCustomer } from '../../contacts.actions'
import { FILTER_BY, STATUS, CUSTOMER } from '../../../shared/constants/filters'

export const ON_REQUEST = '@indigo/invoices/ON_REQUEST'
export const ON_REQUEST_SUCCESS = '@indigo/invoices/ON_REQUEST_SUCCESS'
export const ON_REQUEST_FAILURE = '@indigo/invoices/ON_REQUEST_FAILURE'
export const SET_FILTER = '@indigo/invoices/SET_FILTER'
export const SET_INVOICES = '@indigo/invoices/SET_INVOICES'
export const REPLACE_INVOICES = '@indigo/invoices/REPLACE_INVOICES'
export const SET_SELECT_ALL_INVOICES = '@indigo/invoices/SET_SELECT_ALL_INVOICES'
export const SET_SELECT_ONE_INVOICE = '@indigo/invoices/SET_SELECT_ONE_INVOICE'
export const CHANGE_DISPUTING_STATUS = '@indigo/invoices/CHANGE_DISPUTING_STATUS'
export const SET_INVOICE_SELECTED = '@indigo/invoices/SET_INVOICE_SELECTED'
export const CLEAR_INVOICE_SELECTED = '@indigo/invoices/CLEAR_INVOICE_SELECTED'
export const TOGGLE_CUSTOM_EMAIL_MODAL = '@indigo/invoices/TOGGLE_CUSTOM_EMAIL_MODAL'
export const CLEAR_CUSTOMER_DETAILS = '@indigo/invoices/CLEAR_CUSTOMER_DETAILS'
export const SET_NOT_FOUND_INVOICES = '@indigo/invoices/SET_NOT_FOUND_INVOICES'
export const ORDER_INVOICES = '@indigo/invoices/ORDER_INVOICES'
export const CLEAR_DETAILED_INVOICE = '@indigo/invoices/CLEAR_DETAILED_INVOICE'
export const SET_DETAILED_INVOICE = '@indigo/invoices/SET_DETAILED_INVOICE'
export const SET_SEARCHED_INVOICES = '@indigo/invoices/SET_SEARCHED_INVOICES'
export const SET_LAST_SEARCHED_INVOICES = '@indigo/invoices/SET_LAST_SEARCHED_INVOICES'


export const onRequest = () => ({
  type: ON_REQUEST
})

export const onRequestSuccess = () => ({
  type: ON_REQUEST_SUCCESS
})

export const onRequestFailure = () => ({
  type: ON_REQUEST_FAILURE
})

export const setFilter = (filterBy, filterValue) => {
  return {
    type: SET_FILTER,
    payload: {
      filterBy,
      filterValue
    }
  }
}

export function handleDisputing(isDisputing) {
  return async (dispatch, getState) => {
    const { invoicesReducer, usersReducer, searchResultsReducer } = getState()
    
    const invoiceNumber = invoicesReducer.detailedInvoice !== null ?
      invoicesReducer.detailedInvoice.Invoice_Number :
      searchResultsReducer.detailedInvoice.Invoice_Number
    
    const data = {
      invoice: {
        Invoice_Number: invoiceNumber, 
        Disputing: isDisputing
      },
      action: 'Disputing',
      user: {
        user_name: usersReducer.user.user_name
      }
    }

    await apiRequest('PUT', 'api/v1/invoices/disputing', data)
    
    dispatch(changeDisputingStatus(invoiceNumber))
  }
}

export function searchInvoices(invoices) {
  return (dispatch) => {
    return apiRequest('POST', 'api/v1/invoices/search-multiple', { invoices })
      .then(response => {
        const { found, notFound } = response.data
        dispatch(setNotFoundInvoices(notFound))
        
        if (found.length > 0) {
          const customer = {
            Accounting_Code: found[0].Accounting_Code,
            Customer_Name: found[0].Customer_Name,
            Customer_Amount_Due: found[0].Customer_Amount_Due,
          }
          dispatch(setFilter(FILTER_BY.CUSTOMER, customer.Accounting_Code))     
          dispatch(setSelectedCustomer(customer))
          dispatch(onFetchContactsByCustomer(customer.Accounting_Code))
          dispatch(replaceInvoices(found, found.length, found.length, found[0].Accounting_Code))
          dispatch(setLastSearchedInvoices(invoices)) 
        }

        if (found.length === 0) {
          dispatch(setFilter(FILTER_BY.CUSTOMER, CUSTOMER.NOT_FOUND))     
          dispatch(setSelectedCustomer(null))
          dispatch(replaceInvoices(found, found.length, found.length, CUSTOMER.NOT_FOUND))          
        }                
      })
      .catch(error => {
        return error
      })
  }
}

export const setSearchedInvoices = (invoices) => ({
  type: SET_SEARCHED_INVOICES,  
  payload: {
    invoices
  }
})

export const setNotFoundInvoices = (notFoundInvoices) => ({
  type: SET_NOT_FOUND_INVOICES,
  payload: {
    notFoundInvoices
  }
})

export function getInvoices(customerID, order = false, replace=false ) {
  return async (dispatch, getState) => {
    dispatch(onRequest())

    const { invoicesReducer } = getState()

    const { tableLength } = invoicesReducer
    const filterValue = invoicesReducer.filterValue || STATUS.OPEN
    const invoicesToSkip = !replace ? invoicesReducer.invoices[filterValue].data.length : 0
    const invoicesToDisplay = !replace ? (invoicesToSkip + tableLength) : invoicesReducer.invoices[filterValue].data.length

    try {
      const response = await apiRequest(
        'GET', 
        `api/v1/invoices/by-status/${customerID}?status=${filterValue}&length=${15}&skip=${invoicesToSkip}&order=${order}`
      ).then(res => res.data)
      const {invoices, totalInvoices} = response
      if (!replace) {
        dispatch(setInvoices(invoices, invoicesToDisplay, totalInvoices, filterValue))    
      } else {
        dispatch(replaceInvoices(invoices, invoicesToDisplay, totalInvoices, filterValue))    
      }      

      dispatch(onRequestSuccess())
    } catch (error) {
      dispatch(onRequestFailure())
    }
  }
}

export const setInvoices = (invoices, invoicesToDisplay, totalInvoices, filterValue) => {
  return {
    type: SET_INVOICES,
    payload: {
      invoices,
      invoicesToDisplay,
      totalInvoices,
      filterValue
    }
  }
}

export const clearDetailedInvoice = () => {
  return {
    type: CLEAR_DETAILED_INVOICE
  }
}

export const setDetailedInvoice = (invoice) => {
  return {
    type: SET_DETAILED_INVOICE,
    payload: {
      invoice
    }
  }
}


export const replaceInvoices = (invoices, invoicesToDisplay, totalInvoices, filterValue) => {
  return {
    type: REPLACE_INVOICES,
    payload: {
      invoices,
      invoicesToDisplay,
      totalInvoices,
      filterValue
    }
  }
}

export const setSelectAllInvoices = (isSelectAll) => ({
  type: SET_SELECT_ALL_INVOICES,
  payload: {
    isSelectAll
  }
})

export const setSelectOneInvoice = (invoice) => {
  return {
    type: SET_SELECT_ONE_INVOICE,
    payload: {
      invoice
    }
  }
}

export const changeDisputingStatus = (id) => {
  return {
    type: CHANGE_DISPUTING_STATUS,
    payload: {
      id
    }
  }
}

export const setInvoiceSelected = (invoice = {}) => {
  return {
    type: SET_INVOICE_SELECTED,
    payload: {
      invoice
    }
  }
}

export const clearInvoiceSelected = () => ({
  type: CLEAR_INVOICE_SELECTED
})

export const toggleCustomEmailModal = () => ({
  type: TOGGLE_CUSTOM_EMAIL_MODAL 
})

export const clearCustomerDetails = () => ({
  type: CLEAR_CUSTOMER_DETAILS
})

export const handleOrderInvoices = (order, field) => {
  return (dispatch, getState) => {
    const { invoicesReducer, customersReducer } = getState()

    const filter = invoicesReducer.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : invoicesReducer.filterValue

    const invoices = invoicesReducer.invoices[filter]
    
    if (invoices.invoicesToDisplay >= invoices.totalInvoices) {
      dispatch(orderInvoices(!order, field))
    } else {
      dispatch(getInvoices(customersReducer.selectedCustomer.Accounting_Code, !order, true))
    }
  }
}

export const orderInvoices = (order, field) => {
  return {
    type: ORDER_INVOICES,
    payload: {
      order,
      field
    }
  }
}

export const setLastSearchedInvoices = lastSearchedInvoices => ({
  type: SET_LAST_SEARCHED_INVOICES,
  payload: {
    lastSearchedInvoices
  }
})

export function setDisabled() {}
export function selectInvoice() {}
export function setOrderInvoices() {}





// export const SET_DISABLED = '@indigo/invoices/SET_DISABLED'
// export const setDisabled = (value) => ({
//   type: SET_DISABLED,
//   value
// })


// export const CLEAR_CUSTOMER_DETAILS = '@indigo/invoices/CLEAR_CUSTOMER_DETAILS'
// export function clearCustomerDetails() {
//   return {
//     type: CLEAR_CUSTOMER_DETAILS
//   }
// }

// export function sendEmail(action, inData) {
//   let result = ''
//   switch (action) {
//     case 'jcherrera@casestack.com':
//       result = action.replace('@casestack.com', '@belatrixsf.com')
//       break
//     case 'jdcruz@casestack.com':
//       result = action.replace('@casestack.com', '@belatrixsf.com')
//       break
//     case 'mdeltoro@casestack.com':
//       result = action.replace('@casestack.com', '@belatrixsf.com')
//       break
//     case 'cafernandez@casestack.com':
//       result = action.replace('@casestack.com', '@belatrixsf.com')
//       break
//     case 'cavendano@casestack.com':
//       result = action.replace('@casestack.com', '@belatrixsf.com')
//       break
//     default:
//       result = action
//       break
//   }
//   return async (dispatch, getState) => {
//     const state = getState()

//     dispatch(openIndividualAction())

//     let invoices = state.invoicesReducer.filteredInvoices.filter((invoice) => {
//       return invoice.checked
//     })

//     if (state.invoicesReducer.invoiceSelected.Invoice_Number) {
//       invoices = [
//         state.invoicesReducer.invoiceSelected
//       ]
//     }

//     const customer = state.customersReducer.selectedCustomer

//     customer.invoices = invoices
//     let data = {
//       customer,
//       templates: ['invoice-pdf'],
//       email: result
//     }
//     if (inData) {
//       data = {
//         ...data,
//         to: inData.to,
//         cc: inData.cc,
//         customMessage: inData.message
//       }
//     }
//     await apiRequest('post', 'api/v1/emails/send', data).then((res) => res)

//   }
// }