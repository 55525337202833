import React, { Component } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  width: 100%;
  margin: 0;
`

export class ClickOutsideWrapper extends Component {
  handleClickOutside = () => {
    this.props.handleModal()
  }

  render() {
    return (
      <Wrapper>
        {this.props.children}
      </Wrapper>
    )
  }
}
 
ClickOutsideWrapper.propTypes = {
  children: PropTypes.object,
  handleModal: PropTypes.func
}

export default onClickOutside(ClickOutsideWrapper) 
