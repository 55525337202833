import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SimpleTabs from '../../shared/Tabs'
import InvoiceDetails from './InvoiceDetails'
import ActionsHistory from './Actions'
import Typography from '../../shared/Typography'
import ExitIcon from '../../../assets/svgs/ExitIcon'
import './styles.scss'

class InvoiceInformation extends Component {
  constructor(props) {
    super(props)

    this.generateTabs = this.generateTabs.bind(this)
  }

  generateTabs() {
    return [
      {
        title: 'DETAILS',
        component: (
          <InvoiceDetails 
            invoice={this.props.invoice} 
            changeDisputingStatus={this.props.changeDisputingStatus}
          />
        )
      },
      {
        title: 'ACTIVITY',
        component: <ActionsHistory
          invoice={this.props.invoice}
        />
      }
    ]
  }

  render() {
    const { Invoice_Number } = this.props.invoice

    return (
      <div className="customer-details-nav-container">
        <div className="customer-details-nav">        
          <div className="sidebar-title">        
            <Typography
              element="span"
              color="#9db4c4"
            >
            INVOICE
            </Typography>        
          
            <Typography
              element="h3"
            >
              {Invoice_Number}
            </Typography>        
          </div>
          <div 
            className="icon-container" 
            onClick={() => this.props.clearDetailedInvoice()}
          >
            <ExitIcon  />
          </div>
        </div>

        <SimpleTabs tabs={this.generateTabs()}/>        
      </div>
    )
  }
}

InvoiceInformation.propTypes = {
  invoice: PropTypes.object,
  clearDetailedInvoice: PropTypes.func,
  changeDisputingStatus: PropTypes.func
}

export default InvoiceInformation