import React from 'react'
import styled from 'styled-components/macro'
import Typography from '../../../../shared/Typography'

const StyledOption = styled.div`
  cursor: pointer;
  width: 372px;
  height: 52px;
  padding: 6px 0px 6px 24px;
  margin-top: 8px;
  margin-bottom:6px;
  &:hover{
    background-color: #f2f2f2;
  }
`

export const Option = props => {
  const { innerProps, data } = props
  return (
    <StyledOption {...innerProps}>
      {data.name ?
        (<React.Fragment>
          <Typography element="p">
            {data.name}
          </Typography>
          <Typography element="p" color="#888888">
            {data.value}
          </Typography>
        </React.Fragment>
        )
        :
        <Typography element="p">
          {data.value}
        </Typography>
      }
    </StyledOption>
  )
}

