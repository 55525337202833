import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 40px;
  width: 40px;
`
export const Icon= styled.div`
  height:16px;
  width: 16px;    
  svg {
    height:16px;
    width: 16px; 
    fill: ${props => props.iconColor}
  }
`

const ActionIcon = ({action}) => {
  return (
    <Wrapper color={action.backgroundColor}>
      <Icon iconColor={action.iconColor}>
        {action.icon}
      </Icon>
    </Wrapper>
  )
}

ActionIcon.propTypes = {
  action: PropTypes.object
}

export default ActionIcon