import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

const StyledToastContainer = styled(ToastContainer)`
  width: auto !important;
  margin-bottom: 24px;
  margin-left:24px;
  & > .Toastify__toast{
    background-color: #252839;
    color: #ffffff;
    font-size: 14px;
    width: auto;
    min-height: 44px;
    max-height: 44px;
    border-radius: 3px;
    margin-bottom: 0;
    & > .Toastify__toast-body{
      flex: none;
      width: auto;
    }
    & > button{
      display: none;
    }
  }
 `

const Snackbar = (props) => {
  return(
    <StyledToastContainer 
      autoClose={3000}
      closeOnClick={true}
      position={'bottom-left'}
      hideProgressBar={true}
      draggable={false}
    />
  )
}

export default React.memo(Snackbar)