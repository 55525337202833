import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab } from 'react-bootstrap'
import styled from 'styled-components/macro'
import Typography from '../shared/Typography'  

const StyledTabs = styled(Tabs)`
  padding-top: 24px;
  hr {
    margin: 0;
  }

  ul {
    border: none;
    min-height: 48px;
    max-height: 48px;
    border-bottom: 1px solid #e2e2e2;
    padding: 0px 16px;    
    
    li { 
      margin: 0px 16px 0px 16px;
      a {
        margin: 0;
        padding: 0;
        padding-bottom: 21px;        
        outline: none;
        
        h4 {
          color: #888888;
          margin: 0;
          text-transform: uppercase;
        }

        &:hover {
          background-color: unset;
          border-color: rgba(255,255,255,0)
        }
      }
      &:hover {
        a{
          h4{
            color: #252839;
          }
        }
      }
      &.active > a {
        border: none;
        border-bottom: 3px solid #f8c013;
        h4{
        color: #252839;
        }
        &:focus {
          border: none;
          border-bottom: 3px solid #f8c013;
        }

        &:hover {
          border: none;
          border-bottom: 3px solid #f8c013;
        }
      }
    }
  }
`

class SimpleTabs extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      key: '',
    }
  }
  
  componentDidMount() {
    this.setState({
      key: this.props.tabs[0].title
    })
  }

  render() {
    return (
      <StyledTabs
        id="controlled-tab"
        activeKey={this.state.key}
        onSelect={key => this.setState({ key })}
      >
        {this.props.tabs.map(tab => {
          return (
            <Tab 
              key={tab.title} 
              eventKey={tab.title} 
              title={<Typography element="h4" weight="600">{tab.title}</Typography>}
            >
              {
                React.cloneElement(
                  tab.component,
                  {
                    selectedTab: this.state.key
                  }
                ) 
              }
            </Tab>
          )
        })}
      </StyledTabs>
    )
  }
}

SimpleTabs.propTypes = {
  tabs: PropTypes.array
}


export default SimpleTabs