/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Order = () => (
  <SVG id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">    
    <path d="M14.47,16H1.53A1.54,1.54,0,0,1,0,14.47V1.53A1.54,1.54,0,0,1,1.53,0H14.47A1.54,1.54,0,0,1,16,1.53V14.47A1.54,1.54,0,0,1,14.47,16ZM1.53,1.37a.16.16,0,0,0-.16.16V14.47a.16.16,0,0,0,.16.16H14.47a.16.16,0,0,0,.16-.16V1.53a.16.16,0,0,0-.16-.16Z" transform="translate(0 0)"/>
    <path d="M4.44,6.2A1.26,1.26,0,1,1,5.7,4.94,1.27,1.27,0,0,1,4.44,6.2Zm0-1.37a.11.11,0,0,0-.11.11c0,.13.22.13.22,0A.11.11,0,0,0,4.44,4.83Z" transform="translate(0 0)"/>
    <path d="M12.13,5.63H8A.69.69,0,1,1,8,4.25h4.13a.69.69,0,0,1,0,1.38Z" transform="translate(0 0)"/>
    <path d="M4.44,9.26A1.26,1.26,0,1,1,5.7,8,1.27,1.27,0,0,1,4.44,9.26Zm0-1.37A.11.11,0,0,0,4.33,8c0,.13.22.13.22,0A.11.11,0,0,0,4.44,7.89Z" transform="translate(0 0)"/>
    <path d="M12.13,8.69H8A.69.69,0,1,1,8,7.31h4.13a.69.69,0,0,1,0,1.38Z" transform="translate(0 0)"/>
    <path d="M4.44,12.32A1.26,1.26,0,1,1,5.7,11.06,1.27,1.27,0,0,1,4.44,12.32Zm0-1.37a.11.11,0,0,0-.11.11c0,.13.22.13.22,0A.11.11,0,0,0,4.44,11Z" transform="translate(0 0)"/>
    <path d="M12.13,11.75H8a.69.69,0,0,1,0-1.38h4.13a.69.69,0,1,1,0,1.38Z" transform="translate(0 0)"/>
  </SVG>
)

export default Order