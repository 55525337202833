import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-bootstrap'
import Table from '../../shared/Table'
import InvoicesMenu from '../../shared/CustomMenus/InvoicesMenu'
import SendMenu from './SendMenu'
import InvoiceMenu from '../../shared/CustomMenus/SingleInvoiceMenu'
import EmailForm from '../../../components/customer/invoices/emails'
import Modal from '../../shared/SimpleModal/Modal'
import tableColumns from '../../shared/Table/tableColumns'
import NotFoundInvoices from '../NotFoundInvoicesTable'
import { CUSTOMER } from '../../../shared/constants/filters'
import Snackbar from '../../shared/Snackbar'
import getMoney from '../../../shared/utils/getMoney'

const headerStyles = `
  th:nth-child(6){
    padding-left: 26px;
  }
`

function InvoicesByCustomerView(props) {
  const contactsEmails = props.contacts.map(contact => contact.Email)

  return (
    <React.Fragment>
      <Snackbar/>
      <Modal
        showModal={props.isCustomEmailModalOpen}
        width="496px"
        height="358px"
      >
        <EmailForm
          toggleCancel={props.toggleCustomEmailModal}
          contacts={props.contacts}
          countSelectedInvoices={props.calculateSelectedInvoices()}
          sendEmail={props.handleSendEmail}
        />
      </Modal>

      <Col className="invoices-info">
        {
          props.filterValue !== CUSTOMER.NOT_FOUND ?
            <Table
              headerStyles={headerStyles}
              title={props.selectedCustomer && props.selectedCustomer.Customer_Name}
              handleClickOnTitle={props.handleClickOnTitle}              
              subtitle={props.selectedCustomer && `$${getMoney(props.selectedCustomer.Customer_Amount_Due)} due`}
              headerButtons={[
                <InvoicesMenu
                  key='invoices-menu'
                  handleDownloadDocument={props.handleDownloadDocument}
                />,
                <SendMenu
                  key='send-menu'
                  contacts={contactsEmails}
                  handleSendEmail={props.handleSendEmail}
                  toggleCustomEmailModal={props.toggleCustomEmailModal}
                />
              ]}
              header={() => tableColumns(!!props.detailedInvoice)}
              data={props.filterValue ? props.invoices : []}
              defaultLength={15}
              rowSelected={props.detailedInvoice}
              filterValue={props.filterValue}
              handleSelectAll={props.setSelectAllInvoices}
              handleSelectOne={props.setSelectOneInvoice}
              rightArrow={true}
              handleRightArrow={props.setDetailedInvoice}
              rowMenu={
                <InvoiceMenu
                  contacts={contactsEmails}
                  handleDownloadDocument={props.handleDownloadDocument}
                  toggleCustomEmailModal={props.toggleCustomEmailModal}
                  handleSendEmail={props.handleSendEmail}
                  openIndividualAction={props.setInvoiceSelected}
                />
              }
            />
            :
            <NotFoundInvoices
              notFoundInvoices={props.notFoundInvoices}
            />
        }
      </Col>

    </React.Fragment>
  )
}

InvoicesByCustomerView.propTypes = {
  contacts: PropTypes.array,
  isCustomEmailModalOpen: PropTypes.bool,
  toggleCustomEmailModal: PropTypes.func,
  calculateSelectedInvoices: PropTypes.func,
  handleSendEmail: PropTypes.func,
  filterValue: PropTypes.string,
  selectedCustomer: PropTypes.object,
  handleClickOnTitle: PropTypes.func,
  handleDownloadDocument: PropTypes.func,
  detailedInvoice: PropTypes.object,
  invoices: PropTypes.array,
  setSelectAllInvoices: PropTypes.func,
  setSelectOneInvoice: PropTypes.func,
  setDetailedInvoice: PropTypes.func,
  setInvoiceSelected: PropTypes.func,
  notFoundInvoices: PropTypes.array
}

export default InvoicesByCustomerView