/* eslint-disable no-case-declarations */

import {
  ON_REQUEST,
  ON_REQUEST_SUCCESS,
  ON_REQUEST_FAILURE,
  SET_FILTER,
  SET_INVOICES,
  REPLACE_INVOICES,
  SET_NOT_FOUND_INVOICES,
  SET_SELECT_ALL_INVOICES,
  SET_SELECT_ONE_INVOICE,
  CHANGE_DISPUTING_STATUS,
  SET_INVOICE_SELECTED,
  TOGGLE_CUSTOM_EMAIL_MODAL,
  CLEAR_CUSTOMER_DETAILS,
  ORDER_INVOICES,
  CLEAR_INVOICE_SELECTED,
  SET_DETAILED_INVOICE,
  SET_SEARCHED_INVOICES,
  CLEAR_DETAILED_INVOICE,
  SET_LAST_SEARCHED_INVOICES
} from '../../../actions/components/customer/invoices'
import { FILTER_BY, STATUS, CUSTOMER } from '../../../shared/constants/filters'

const setDefaultInvoices = () => {
  let invoices = {}

  const filters = Object.assign(STATUS, CUSTOMER)

  for (const invoiceStatus in filters) {
    const statusName = STATUS[invoiceStatus]

    invoices[statusName] = {
      totalInvoices: null,
      data: []
    }
  }

  return invoices
}

export const defaultState = {
  onRequest: false,
  onRequestSucess: false,
  onRequestFailure: false,
  invoices: setDefaultInvoices(),
  tableLength: 15,
  filterBy: FILTER_BY.CUSTOMER,
  filterValue: STATUS.OPEN,
  invoicesToRequest: 20,
  invoiceSelected: null,
  detailedInvoice: null,
  searchedInvoices: null,
  searchInvoices: null
}

const orderBy = (data, field, order) => {
  if (order) {
    return data.sort(function(a,b){
      return new Date(b[field]) - new Date(a[field])
    })
  } else {
    return data.sort(function(a,b){
      return new Date(a[field])- new Date(b[field])
    })
  }
}


const invoicesReducer = (state = defaultState, action) => {
  switch (action.type) {
  case ON_REQUEST: 
    return {
      ...state,
      onRequest: true
    }

  case ON_REQUEST_SUCCESS: 
    return {
      ...state,
      onRequest: false,
      onRequestSucess: true,
      onRequestFailure: false
    }

  case ON_REQUEST_FAILURE: 
    return {
      ...state,
      onRequest: false,
      onRequestSucess: false,
      onRequestFailure: true
    }

  case SET_FILTER: {
    let customerToFilter = action.payload.filterValue
    return { 
      ...state,       
      filterBy: action.payload.filterBy, 
      filterValue: action.payload.filterValue === '' ? customerToFilter : action.payload.filterValue
    }
  }
  
  case SET_INVOICES: 
  case REPLACE_INVOICES:
    const { invoicesToDisplay, totalInvoices, filterValue, invoices } = action.payload
    let filter = state.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : state.filterValue

    return {
      ...state,
      invoices: {
        ...state.invoices,
        [filter]: {
          invoicesToDisplay: invoicesToDisplay,
          totalInvoices: totalInvoices,
          data: action.type === SET_INVOICES ? [...state.invoices[filter].data, ...invoices] : invoices
        }
      },
      filterValue: filterValue
    }
  case SET_SEARCHED_INVOICES:
    return {
      ...state,
      searchedInvoices: action.payload.invoices
    }

  case SET_NOT_FOUND_INVOICES:
    return {
      ...state,
      invoices: {
        ...state.invoices,
        [CUSTOMER.NOT_FOUND]: {
          invoicesToDisplay: action.payload.notFoundInvoices.length,
          totalInvoices: action.payload.notFoundInvoices.length,
          data:action.payload.notFoundInvoices
        }
      }
    }   

  case SET_SELECT_ALL_INVOICES:
    filter = state.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : state.filterValue

    return {
      ...state,
      invoices: {
        ...state.invoices,
        [filter]: {
          ...state.invoices[filter],
          data: state.invoices[filter].data.map(invoice => {
            if (state.filterBy !== FILTER_BY.CUSTOMER) {
              invoice.checked = !action.payload.isSelectAll          
            } else {
              if (state.filterValue === invoice.Accounting_Code) {
                invoice.checked = !action.payload.isSelectAll
              }
            }

            return invoice
          })
        }
      }
    }  
  
  case SET_SELECT_ONE_INVOICE:  
    filter = state.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : state.filterValue
    return {
      ...state,
      invoices: {
        ...state.invoices,
        [filter]: {
          ...state.invoices[filter],
          data: state.invoices[filter].data.map((invoice) => {
            if (invoice.Invoice_Number === action.payload.invoice.Invoice_Number) {
              invoice.checked = !invoice.checked
            }

            return invoice
          })
        }
      }
    }  
    
  case CHANGE_DISPUTING_STATUS:
    filter = state.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : state.filterValue

    return {
      ...state,
      invoices: {
        ...state.invoices,
        [filter]: {
          ...state.invoices[filter],
          data: state.invoices[filter].data.map((invoice) => {
            if (invoice.Invoice_Number === action.payload.id) {
              invoice.Disputing = !invoice.Disputing 
            }

            return invoice
          })
        }
      }
    }

  case SET_INVOICE_SELECTED:
    return {
      ...state,
      invoiceSelected: action.payload.invoice
    }
    
  case TOGGLE_CUSTOM_EMAIL_MODAL:
    return {
      ...state,
      isCustomEmailModalOpen: !state.isCustomEmailModalOpen
    }

  case CLEAR_CUSTOMER_DETAILS:
    return {
      ...defaultState
    }  

  case ORDER_INVOICES:  
    filter = state.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : state.filterValue

    return {
      ...state,
      invoices: {
        ...state.invoices,
        [filter]: {
          ...state.invoices[filter],
          data: orderBy(state.invoices[filter].data, action.payload.field, action.payload.order)
        }
      }
    }  

  case CLEAR_INVOICE_SELECTED:  
    return {
      ...state,
      invoiceSelected: null
    }
   
  case SET_DETAILED_INVOICE: 
    return {
      ...state,
      detailedInvoice: Object.assign(action.payload.invoice)
    }

  case CLEAR_DETAILED_INVOICE:
    return {
      ...state,
      detailedInvoice: null
    }
  
  case SET_LAST_SEARCHED_INVOICES:
    return {
      ...state,
      lastSearchedInvoices: action.payload.lastSearchedInvoices
    }

  default: 
    return {
      ...state
    }
  }
}

export default invoicesReducer
