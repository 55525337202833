import React, { Component } from 'react'
import styled from 'styled-components/macro'

const Radio = styled.input`
  display: none;

  &:checked:disabled+label:before {
    background: #f8c013;
    opacity: 0.5; 
  }

  &:checked+label:before {
    background: #f8c013;
    margin: 2px 2px 4px 2px;
  }
`

const Custom = styled.label`
    border: 1px solid #a4a4a4;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 0;
    cursor: pointer;
    display: inline-block;

    &:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        z-index: 5;
    }
`

class RadioInput extends Component {
  render() {
    return (
      <div className="radio-container">
        <Radio 
          disabled={this.props.disabled}
          id={this.props.id} 
          name={this.props.name} 
          type={this.props.type}
          checked={this.props.value === this.props.radioSelection}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <Custom 
          htmlFor={this.props.id}
        />
      </div>
    )
  }
}

export default RadioInput