/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #fff;
`

const CheckBox = () => (

  <SVG className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M6.88,12a.81.81,0,0,1-.57-.23L3.14,8.56a.68.68,0,0,1,.1-.93.66.66,0,0,1,.83,0L6.84,10.4l5-6.12a.65.65,0,1,1,1,.83L7.5,11.67a.8.8,0,0,1-.58.29Z"/>
  </SVG>
)

export default CheckBox