import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Table } from 'react-bootstrap'
import TableRow from './TableRow'
import TableHeader from './TableHeader'
import styled from 'styled-components'


const StyledTable = styled(Table)`
  height: auto;
  width: 100%;

  &.table {
    tbody {
      ${props => !props.rowStyles ? props.rowStyles : null} 

      tr {
        td {
          vertical-align: middle;
          border-top: none;
          padding: 0 16px 0 0;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.due-col,
          &.options-cell {
            padding-right: 24px;    
          }

          &.checkbox-table {
            padding: 0 24px;
          }

          &.menu-on-hover {
            padding: 0;
          }
        }

        &:hover td.menu-on-hover {
          padding-right: 48px;
        }
      }
    }
}
`

const TableView = (props) => {
  return (
    <InfiniteScroll
      scrollThreshold="2px"
      dataLength={props.data.length}
      next={props.handleInfiniteScroll}
      hasMore={props.hasMoreItems}
      style={{ minHeight: '450px', overflowX: 'hidden', overflowY: 'auto', position: 'relative' }}
      height='calc(100vh - 128px - 72px)'
      loader={
        <h4 style={{ textAlign: 'center' }}>
          {(props.data.length > 0 && props.hasMoreItems) ? (
            props.loader.hasMoreLoader
          ) : (
            props.loader.noDataFoundMessage
          )}
        </h4>
      }
    >
      <StyledTable
        rowStyles={props.rowStyles}
      >
        <TableHeader
          headerStyles={props.headerStyles}
          data={props.data}
          isSelectAll={props.isSelectAll}
          checkable={props.checkable}
          handleSelectAll={props.handleSelectAll}
          header={props.header}
          handleSort={props.handleSort}
          order={props.order}
          sortBy={props.sortBy}
        />

        <tbody>
          {(props.data.map((item, index) => {
            return (
              <TableRow
                rowStyles={props.rowStyles}
                key={index}
                columns={props.header()}
                item={item}
                checkable={props.checkable}
                handleSelectOne={props.handleSelectOne}
                rowMenu={props.rowMenu}
                rightArrow={props.rightArrow}
                handleRightArrow={props.handleRightArrow}
                isRowSelected={props.rowSelected && props.rowSelected.Invoice_Number === item.Invoice_Number}
              />
            )
          }))}
        </tbody>
      </StyledTable>
    </InfiniteScroll>
  )
}

TableView.propTypes = {
  data: PropTypes.object,
  handleInfiniteScroll: PropTypes.func,
  hasMoreItems: PropTypes.bool,
  loader: PropTypes.object,
  rowStyles: PropTypes.object,
  headerStyles: PropTypes.object,
  isSelectAll: PropTypes.bool,
  checkable: PropTypes.bool,
  rightArrow: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  handleSelectOne: PropTypes.func,
  handleRightArrow: PropTypes.func,
  rowMenu: PropTypes.node,
  header: PropTypes.func,
  handleSort: PropTypes.func,
  order: PropTypes.bool,
  sortBy: PropTypes.bool,
  rowSelected: PropTypes.object,
}

export default TableView