import React from 'react'
import Typography from '../../shared/Typography'
import './styles.scss'
import Airplane from '../../../assets/svgs/Airplane'
import Calendar from '../../../assets/svgs/Calendar'
import Button from '../../shared/Button'
import FlatButton from '../../shared/FlatButton'
import PrimaryButton from '../../shared/PrimaryButton'
import RadioInput from '../../shared/RadioInput'
import PropTypes from 'prop-types'
import { customerDetailsReducer } from '../../../reducers/components/customer/customerDetails.reducer';
import paymentTermsMethod from './paymentTerms'
const autoDeliveryMethods = [
  { label: 'Email', value: 'Email' },
  { label: 'Print queue', value: 'Print' }
]

const AutoDeliveryView = (props) => {
  const {
    deliveryMethod,
    hasChanged,
    hasChangedState,
    paymentTerms,
    changeDeliveryMethodLocal,
    onUpdateDeliveryMethod,
    deliveryMethodHasChanged,
    contacts,
    customer
  } = props
  return (
    <React.Fragment>
      <Typography
        className="styled-title"
        element="h3"
        weight="600"
        color="#252839">
        Invoicing settings
      </Typography>
      <div className="main-delivery-container">
        <div className="delivery-method">
          <Airplane color="#888888" />
          <div className="delivery-title">
            <Typography
              element="p"
              weight="600"
              color="#252839">
              Auto-delivery method
            </Typography>
            <Typography
              element="p"
              color="#888888">
              {'How we first send an invoice when it\'s generated'}
            </Typography>
          </div>
        </div>
        {autoDeliveryMethods.map(method => (
          <div
            className="radio-label"
            key={method.label}
            onClick={(customer.Delivery_Method === method.value || customer.Customer_Emails.length === 0) ?
              null : () => {
                hasChanged()
                changeDeliveryMethodLocal(method.value)
              }}
          >
            <RadioInput
              name={method.label}
              type="radio"
              value={method.value}
              radioSelection={customer.Delivery_Method}
              disabled={contacts.length === 0}
            />
            <Typography
              element="p"
              color="#252839"
            >
              {method.label}
            </Typography>
          </div>
        ))}
        <div className="payment-terms">
          <Calendar color="#888888" />
          <div className="terms-content">
            <Typography
              element="p"
              weight="600"
              color="#252839">
              Payment terms
            </Typography>
            <Typography
              element="p"
              color="#252839">
              {customer && customer.Payment_Terms ? paymentTermsMethod(customer.Payment_Terms) : 'No Terms'}
            </Typography>
          </div>
        </div>
      </div>
      <div className="buttons-container-delivery">
        <FlatButton                              
          id="cancel"
          onClick={() => {
            if(hasChangedState){
              changeDeliveryMethodLocal((customer.Delivery_Method === 'Print')?('Email'):('Print'))
            }
            props.handleClose()
          }}>
          Cancel
        </FlatButton>
        {/* <Button
          className="cancel-button"
          type="button"
          btnType="secondary"
          name="cancel"
          value="cancel"
          id="cancel"
          onClick={() => {
            if(hasChangedState){
              changeDeliveryMethodLocal((customer.Delivery_Method === 'Print')?('Email'):('Print'))
            }
            props.handleClose()
          }}
        /> */}
        <PrimaryButton
          disabled={!hasChangedState}          
          id='save'
          onClick={() => onUpdateDeliveryMethod()}>
          Save
        </PrimaryButton>
        {/* <Button
          disabled={!hasChangedState}
          type="button"
          name="save"
          value='save'
          id='save'
          onClick={() => onUpdateDeliveryMethod()}
        /> */}
      </div>
    </React.Fragment>
  )
}

AutoDeliveryView.propTypes = {
  handleClose: PropTypes.func,
  deliveryMethod: PropTypes.string,
  paymentTerms: PropTypes.func,
  changeDeliveryMethodLocal: PropTypes.func,
  onUpdateDeliveryMethod: PropTypes.func,
  deliveryMethodHasChanged: PropTypes.bool,
  contacts: PropTypes.array
}

export default AutoDeliveryView