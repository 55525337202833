import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  onFetchContactsByCustomer,
  toggleContactsModal,
  toggleDeleteModal,
  handleEditContact,
  handleDeleteContact,
  clearRequestProcess,
  closeContactModal,
  closeDeleteModal
} from '../../../../actions/contacts.actions'
import {
  onAddContact,
  onDeleteContact,
  onEditContact
} from '../../../../actions/customers.actions'
import { setFilter } from '../../../../actions/components/customer/invoices'
import ContactsView from '../../../../components/customer/contacts/ContactsView'

class Contacts extends Component {
  componentDidMount() {
    this.props.setFilter('', 'none')

  }

  render() {
    return (
      <ContactsView
        handleEditContact={this.props.handleEditContact}
        handleDeleteContact={this.props.handleDeleteContact}
        onAddContact={this.props.onAddContact}
        onEditContact={this.props.onEditContact}
        onDeleteContact={this.props.onDeleteContact}
        toggleContactsModal={this.props.toggleContactsModal}
        toggleDeleteModal={this.props.toggleDeleteModal}
        isModalOpen={this.props.isModalOpen}
        isDeleteModalOpen={this.props.isDeleteModalOpen}
        contactSelected={this.props.contactSelected}
        customer={this.props.customer}
        onRequest={this.props.onRequest}
        isSuccess={this.props.isSuccess}
        statusMessage={this.props.statusMessage}
        clearRequestProcess={this.props.clearRequestProcess}
        closeContactModal={this.props.closeContactModal}
        closeDeleteModal={this.props.closeDeleteModal}
      />
    )
  }
}

const mapStateToProps = ({ customersReducer, contactsReducer }) => ({
  customer: customersReducer.selectedCustomer,
  isModalOpen: contactsReducer.isModalOpen,
  isDeleteModalOpen: contactsReducer.isDeleteModalOpen,
  contactSelected: contactsReducer.contactSelected,
  onRequest: contactsReducer.onRequest,
  isError: contactsReducer.isError,
  isSuccess: contactsReducer.isSuccess,
  statusMessage: contactsReducer.statusMessage
})

const mapDispatchToProps = {
  clearRequestProcess,
  onFetchContactsByCustomer,
  onAddContact,
  onEditContact,
  toggleContactsModal,
  handleEditContact,
  handleDeleteContact,
  onDeleteContact,
  toggleDeleteModal,
  setFilter,
  closeContactModal,
  closeDeleteModal,
}

Contacts.propTypes = {
  onFetchContactsByCustomer: PropTypes.func,
  customerID: PropTypes.string,
  setFilter: PropTypes.func,
  handleEditContact: PropTypes.func,
  handleDeleteContact: PropTypes.func,
  onAddContact: PropTypes.func,
  onEditContact: PropTypes.func,
  onDeleteContact: PropTypes.func,
  toggleDeleteModal: PropTypes.bool,
  toggleContactsModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  isDeleteModalOpen: PropTypes.bool,
  contacts: PropTypes.array,
  contactSelected: PropTypes.object,
  customer: PropTypes.object,
  onRequest: PropTypes.bool,
  isSuccess: PropTypes.bool,
  statusMessage: PropTypes.string,
  clearRequestProcess: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(Contacts)