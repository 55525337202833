import { omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

import { AuthConsumer } from '../AuthContext'

export class ProtectedRoute extends Component {
  constructor (props) {
    super(props)
    this.getComponent = this.getComponent.bind(this)
  }

  isAllowed () {
    const allowedGroups = this.props.allowedGroups
    const currentUserGroups = this.props.currentUserGroups
    if (allowedGroups === 'all') return true
    return currentUserGroups && allowedGroups.some(group => currentUserGroups.includes(group))
  }

  getComponent (props) {
    const Component = this.props.component
    return this.isAllowed() ? <Component {...props} /> : <Redirect to='/logout' />
  }

  render () {
    return <Route {...omit(this.props, ['component'])} render={this.getComponent} />
  }
}

ProtectedRoute.propTypes = {
  allowedGroups: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]).isRequired,
  currentUserGroups: PropTypes.array
}

export default props => (
  <AuthConsumer>
    {
      ({currentUserGroups}) => <ProtectedRoute {...props} currentUserGroups={currentUserGroups} />
    }
  </AuthConsumer>
)
