import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SearchModalView from './SearchModalView'
import { apiRequest } from '../../../shared/apiRequest'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { clearDetailedInvoice } from '../../../actions/components/searchResults/searchResults.actions'



let interval = null

export class SearchModal extends Component {
  constructor() {
    super()

    this.state = {
      cursor: -1,
      searchText: '',
      baseSearchText: '',
      results: [],
      filteredResults: [],
      dropdownValue: 'customer',
      onRequest: true
    }

    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.notifyFocus = this.notifyFocus.bind(this)
    this.handleSelectItem = this.handleSelectItem.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.notifyMouseLeave = this.notifyMouseLeave.bind(this)
  }

  handleDropDownChange(e) {
    this.setState({
      dropdownValue: e,
      cursor: -1,
      filteredResults: [],
      searchText: ''
    })
  }

  async componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)

    this.setState({
      onRequest: false
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)    
  }

  async handleInput(e) {
    let searchText = e.target.value.toLowerCase()

    if (searchText.length === 0) {
      return this.setState({
        searchText,
        filteredResults: []
      })
    }

    this.searchCoincidence(searchText)

    return this.setState({
      searchText
    })
  }

  async searchCoincidence(searchText) {
    const { dropdownValue } = this.state
    let finalResult = []
    let cursor = -1
    let time = 300

    if (dropdownValue === 'customer') {
      if (searchText.length >= 4) {
        clearInterval(interval)
        let filteredResults = await new Promise(resolve => {
          interval = setInterval(async () => {
            let results = await apiRequest(
              'GET',
              `api/v1/customers/searchcustomer?text=${searchText}`
            ).then(res => res.data)
            clearInterval(interval)
            resolve(results)
          }, time)
        })
        finalResult = filteredResults.sort(function(a, b) {
          if (a.Name < b.Name) {
            return -1
          }
          if (a.Name > b.Name) {
            return 1
          }
          return 0
        })
      }else{
        finalResult = []
      }
    } else {
      finalResult.push(searchText)
      cursor = 0
    }
    if (finalResult.length === 1) {
      cursor = 0
    }
    this.setState({
      filteredResults: finalResult.slice(0, 5),
      cursor
    })
  }

  handleClear() {
    this.setState({
      searchText: '',
      filteredResults: []
    })
  }

  handleExit() {
    this.props.handleModal()
  }

  handleKeyDown(e) {
    const { cursor, filteredResults } = this.state

    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }))
    } else if (e.keyCode === 40 && cursor < filteredResults.length - 1) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }))
    } else if (cursor === filteredResults.length - 1 && e.keyCode === 40) {
      this.setState({
        cursor: 0
      })
    } else if (cursor === 0 && e.keyCode === 38) {
      this.setState({
        cursor: filteredResults.length - 1
      })
    } else if (e.keyCode === 27 && this.props.isOpen) {
      this.props.handleModal()
    } else if (e.keyCode === 13) {
      this.handleSelectItem(cursor)
    }
  }

  notifyFocus(index) {
    this.setState({
      cursor: index
    })
  }

  notifyMouseLeave() {
    this.setState({
      cursor: -1
    })
  }

  async handleSelectItem(index) {
    const {
      handleSelectItem,
      handleModal,
      history,
      setSearchedInvoices,
      clearDetailedInvoice
    } = this.props

    const item = this.state.filteredResults[index]

    if (this.state.dropdownValue === 'customer') {
      handleSelectItem(item)
      history.push(`/customer/${item.Accounting_Code}/invoices`)
    }

    if (this.state.dropdownValue !== 'customer') {
      setSearchedInvoices(item.trim())
      window.localStorage.setItem('searchValues', item.trim())
      history.push('/invoices/search-results')

    }

    handleModal()
    clearDetailedInvoice()
  }

  render() {
    const {
      cursor,
      filteredResults,
      searchText,
      dropdownValue,
      onRequest
    } = this.state

    const { handleModal, isOpen } = this.props

    return (
      <SearchModalView
        notifyMouseLeave={this.notifyMouseLeave}
        header={true}
        cursor={cursor}
        onRequest={onRequest}
        results={filteredResults}
        searchText={searchText}
        handleInput={this.handleInput}
        handleClear={this.handleClear}
        handleKeyDown={this.handleKeyDown}
        notifyFocus={this.notifyFocus}
        handleSelectItem={this.handleSelectItem}
        handleModal={handleModal}
        isOpen={isOpen}
        handleDropDownChange={this.handleDropDownChange}
        dropdownValue={dropdownValue}
      />
    )
  }
}

SearchModal.propTypes = {
  handleModal: PropTypes.func,
  isOpen: PropTypes.bool
}

const mapStateToProps = ({ customersReducer }) => {
  return {
    results: customersReducer.mirandaCustomers
  }
}

const mapDispatchToProps = {
  clearDetailedInvoice,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchModal))
