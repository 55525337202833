import React from 'react'
import styled from 'styled-components/macro'
import * as R from 'ramda'
import * as L from 'partial.lenses'
import TextArea from 'react-textarea-autosize'



const InputContainer = styled.div`
    display: flex;
    flex-direction: column;    
    width: 100%;
    position: relative;
`

export const Input = styled.input`
    background-color: #fafafa;
    //width: 280px;
    width: calc(100% - 16px);
    height: 32px;    
    border-radius: 0;
    border: 0;
    border-bottom: 1.5px solid #e2e2e2;
    letter-spacing: 0.3px;
    font-size: 14px;
    padding: 0 8px;
    &:focus ~ span:before { width: 100%; }
    &:focus { outline: none; }
    ::placeholder { color: #a4a4a4; }
`

export const InputArea = styled(TextArea)`
    overflow: hidden;
    max-width: 100%;
    min-width: 100%;
    min-height: 32px;
    resize: none;
    border-radius: 0;
    border: 0;
    letter-spacing: 0.3px;
    font-size: 14px;
    padding: 8px 8px 2px 8px;
    &:focus ~ span:before { width: 100%; }
    &:focus { outline: none; }
    ::placeholder { color: #a4a4a4; }

    &:hover, &:focus{
      background-color: #fafafa;    
      border-bottom: 1.5px solid #e2e2e2;
    }    
`

const Bar = styled.span`
    width: 100%;
    display: block;
    position: relative;
    &:before {
        content: '';
        height: 2px;
        width: 0;
        position: absolute;
        background: #F8c013;
        transition: .2s ease all;
        left: 0;
        bottom: 0;
    }
`

const ErrorBar = styled.span`
  width: 100%;
  display: block;
  position: relative;
  &:before {
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    background: #d1495b;
    transition: .2s ease all;
    left: 0;
    bottom: 0;
  }
`

const Validate = styled.p`
    color: #d1495b;
    font-size: 12px;
    margin: 8px 0 0;
    text-transform: none;
    position: absolute;
    bottom: -20px;
`

class InputWrap extends React.Component {
  constructor(props){
    super(props)
    const value = props.value || ''
    this.state = { 
      editingText: value, 
      lastValid: value,
    }
  }

  componentDidMount() {
    if (this.props.type === 'textarea') {
      if (this.state.value !== '') {
        this.setState({
          height: document.getElementById('text-area').scrollHeight
        })
      }
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.value !== this.props.value) {
      const value = this.props.value || ''
      this.setState({
        editingText: value
      })
    }
  }

  render(){
    const onBlurWrapper = e => {
      const save = (field, text) => {
        this.setState({ [field]: text })
        e.target.value = text
      }

      const fixedText = L.get(['validator', 'fixText'], this.props) ? 
        this.props.validator.fixText(e.target.value) : 
        e.target.value

      if(this.props.validator && this.props.validator.isError(e.target.value)){
        save('editingText', this.state.lastValid)
      } else if(this.props.onBlur && fixedText !== this.props.value){
        save('lastValid', fixedText)
        save('editingText', fixedText)
      } else {
        save('lastValid', fixedText)
        save('editingText', fixedText)
      }
      if(this.props.onBlur) this.props.onBlur(e)
    }

    const onChangeWrapper = e => {
      if (this.props.type === 'textarea') {
        const height = e.target.scrollHeight
        this.setState({ editingText: e.target.value, height })
      }
      
      this.setState({ editingText: e.target.value })

      if(this.props.onChange) this.props.onChange(e)
    }

    const passThroughProps = R.omit(['onBlur', 'onChange', 'value'], this.props)
    const validatorError = this.props.validator && this.props.validator.isError(this.state.editingText)
    const error = this.props.error || validatorError
    return <InputContainer className="input-container">
      { this.props.type === 'textarea' ? (<InputArea
        id="text-area"
        name={this.props.name}
        //onKeyDown={(e) => (console.log(e.keyCode))}
        innerRef={ this.props.innerRef }
        data-val="true"
        style={{height: this.state.height}}
        spellCheck="false"
        onChange={ onChangeWrapper }
        onBlur={ onBlurWrapper }
        value={ this.props.value }
        { ...passThroughProps } />) : (<Input
        innerRef={ this.props.innerRef }
        data-val="true"
        type="text"
        style={{height: this.state.height + 25}}        
        spellCheck="false"
        onChange={ onChangeWrapper }
        onBlur={ onBlurWrapper }
        value={ this.props.value }
        { ...passThroughProps } />) }
      { !this.props.disabled && (error ? <ErrorBar/> : <Bar/>) }
      <Validate>{ R.is(String, error) ? error : '' }</Validate>
    </InputContainer>
  }
}

export { InputWrap }

