import {
  ADD_CUSTOMER_TO_FAVORITE,
  REMOVE_CUSTOMER_FROM_FAVORITE,
  ON_SET_USER
} from '../actions/users.actions'

export const initialState = {
  user: {
    favorites: null
  },
}

export const usersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case ADD_CUSTOMER_TO_FAVORITE:
    return {
      user: {
        ...state.user,
        favorites: [...state.user.favorites, payload.customerId]
      }
    }
  case REMOVE_CUSTOMER_FROM_FAVORITE:
    return {
      user: {
        ...state.user,        
        favorites: state.user.favorites.filter(customer => customer !== payload.customerId)
      }
    }
  case ON_SET_USER:
    return {
      user: payload.user
    }
  default:
    return state
  }
}