import React from 'react'
import Shadow from './Shadow'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  &>div{
    margin-right: 24px;
  }

`

const Placeholder = ({ cardsNumber }) => {
  const cards = []
  for (let i = 0; i < cardsNumber; i++) {
    cards.push(
      (<Shadow
        width={'322px'}
        height={'180px'}
        opacity={'0.5'}
        borderRadius={'4px'}
        backgroundColor={'#e2e2e2'}
      />)
    )
  }
  return (
    <React.Fragment>
      <Shadow
        width={'161px'}
        height={'24px'}
        opacity={'0.5'}
        borderRadius={'10px'}
        backgroundColor={'#e2e2e2'}
      />
      <Wrapper>
        {cards}
      </Wrapper>
    </React.Fragment>
  )
}

export default Placeholder