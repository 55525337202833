import React from 'react'
import ActionIcon from '../actionIcon'
import { lastActivity, lastActivityFormated }from '../../../../../shared/utils/lastActivity'
import Tooltip from '../../../../shared/Tooltip'
import IconsList from './cards'

export const ActionCard = ({ actionsList }) => {
  if(actionsList && Array.isArray(actionsList) && actionsList.length > 0) {
    return actionsList.map((action, index) => (
      <div key={index} className='actions-container'>
        <div className='action-avatar'>
          <ActionIcon action={IconsList(action)[action.Action_Method]}/>
        </div>
        <div className='action-text'>
          <div>
            {IconsList(action)[action.Action_Method].label}
          </div>
          <div>
            <div>
              <Tooltip toolTipText={lastActivityFormated(action.Action_Date)} container={this}>
                <p className='action-comment'>{lastActivity(action.Action_Date)}</p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    ))
  }
}

export default React.memo(ActionCard)