import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {}

const enhancers = (typeof window === 'object' && (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
  [(window).__REDUX_DEVTOOLS_EXTENSION__ && (window).__REDUX_DEVTOOLS_EXTENSION__()] :
  compose

const middleware = [
  thunk
]

const composedEnhancers = compose(
  applyMiddleware(...middleware)
)
const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
)

export default store
