/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'


const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #f6c018;
`

const Truck = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M4.06,14.54l-1.15-.11H1.06a1.09,1.09,0,0,1-.73-.28A1,1,0,0,1,0,13.31V1.73A1.56,1.56,0,0,1,1.35,0H11.79c.75,0,1.32.74,1.32,1.72V5.27H9.56L9.51,7.32l5.63,0,.62,1a1.82,1.82,0,0,1,.24.88V13a1.31,1.31,0,0,1-1.17,1.46H13.12l.33-.92a.48.48,0,0,0,0-.15,1.31,1.31,0,0,0,0-.17,1.38,1.38,0,0,0-.5-1.07,1.44,1.44,0,0,0-.84-.35h-.09a1.47,1.47,0,0,0-1.44,1.37v.19a3,3,0,0,0,0,.31l.1.78-.77,0H6.39v-.91a.68.68,0,0,0,0-.19,1.06,1.06,0,0,0,0-.31l0-.17A1.35,1.35,0,0,0,6,12.2l-.07-.07A1.37,1.37,0,0,0,5,11.77l-.14,0a1.45,1.45,0,0,0-1.38,1.44c0,.12,0,.24,0,.35ZM1.37,13.35h0Zm-.32-.29h0Zm.4-11.69a.83.83,0,0,0-.08.36V13.06h.78A2.81,2.81,0,0,1,5,10.4h.21a2.77,2.77,0,0,1,1.77.79l.1.12a2.78,2.78,0,0,1,.65,1.29l0,.07a2.33,2.33,0,0,1,.08.38H9.26a2.81,2.81,0,0,1,2.8-2.66h.22a2.66,2.66,0,0,1,1.53.6l.12.1a2.61,2.61,0,0,1,.7.94V9.21A.37.37,0,0,0,14.58,9l-.2-.32H9.56A1.4,1.4,0,0,1,8.14,7.32v-2A1.41,1.41,0,0,1,9.56,3.89h2.19V1.72a.88.88,0,0,0-.07-.35Z" transform="translate(0)"/>
    <path d="M5,16a2.8,2.8,0,0,1-2.69-2,.83.83,0,0,1,0-.18,3.67,3.67,0,0,1-.06-.6A2.79,2.79,0,0,1,5,10.4h.21a2.77,2.77,0,0,1,1.77.79l.1.12a2.78,2.78,0,0,1,.65,1.29l0,.11a2.26,2.26,0,0,1,0,.47,3,3,0,0,1,0,.44A2.78,2.78,0,0,1,5,16Zm0-4.25a1.45,1.45,0,0,0-1.38,1.44c0,.12,0,.24,0,.35A1.47,1.47,0,0,0,5,14.63a1.4,1.4,0,0,0,1.4-1.21.9.9,0,0,0,0-.23.79.79,0,0,0,0-.23l0-.11A1.35,1.35,0,0,0,6,12.2l-.07-.07A1.37,1.37,0,0,0,5,11.77Z" transform="translate(0)"/>
    <path d="M14.81,8.71H9.55A1.4,1.4,0,0,1,8.14,7.32v-2A1.41,1.41,0,0,1,9.56,3.89H12.8l.27.3,2.38,3.49.16.34-.4.67ZM9.56,5.26,9.51,7.32l4,0L12.14,5.27Z" transform="translate(0)"/>
    <path d="M12.06,16a2.84,2.84,0,0,1-2.75-2.22,5,5,0,0,1-.05-.59v-.1a2.81,2.81,0,0,1,2.8-2.69h.22a2.66,2.66,0,0,1,1.53.6l.12.1a2.72,2.72,0,0,1,.94,2.08c0,.1,0,.22,0,.32h0a2,2,0,0,1-.11.47A2.77,2.77,0,0,1,12.06,16Zm0-4.24a1.47,1.47,0,0,0-1.44,1.37v.06c0,.12,0,.23,0,.34a1.45,1.45,0,0,0,1.4,1.1,1.43,1.43,0,0,0,1.38-1.06,1.12,1.12,0,0,0,.05-.21,1.31,1.31,0,0,0,0-.17,1.38,1.38,0,0,0-.5-1.07,1.44,1.44,0,0,0-.84-.35Z" transform="translate(0)"/>
  </SVG>
)

export default Truck