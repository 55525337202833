import { SET_SELECTED_CUSTOMER, SET_ACTIVITY } from '../actions/app.actions'

const defaultState = {
  selectedCustomer: null,
  notificationMessage: '',
  isPerformingAnAction: false
}

const appReducer = (state = defaultState, action) => {
  switch (action.type) {
  case SET_SELECTED_CUSTOMER:      
    return {
      ...state,
      selectedCustomer: action.payload.customer
    }
  case SET_ACTIVITY:
    return {
      ...state,
      isPerformingAnAction: action.isPerformingAnAction
    }
  default:
    return state
  }
}

export default appReducer