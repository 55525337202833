import React,  { Component } from 'react'
import PropTypes from 'prop-types'
import Typography from '../Typography'
import styled from 'styled-components/macro'
import MenuItem from './MenuItem'

/*
*      receive in props an array with this structure
*        [
*         {
*           title: 'Tile if it has'
*           items:[
*             {
*               id: 'id of action',
*               label: 'Name filter',
*               icon: 'Name of icon',
*               action: function()
*           ]
*         }
*       ]
*/

const Menu = styled.div`
  width: 262px;
  float: left;
  padding: 40px 40px 0 0;
  height: 100vh;
  position: fixed;
  z-index: 1000;
`

const MenuSection = styled.div`
  margin-bottom: 36px;

  > p {
    margin-bottom: 8px;
  }
`

class LeftMenu extends Component {
  constructor(props){
    super(props)
    this.changeOfMenu = this.changeOfMenu.bind(this)
  }

  changeOfMenu(itemMenu){    
    this.props.clearDetailedInvoice()
    itemMenu.action(itemMenu.filterBy, itemMenu.filterValue)
  }

  render() {
    return (
      <Menu>
        {this.props.leftMenu.length > 0 && this.props.leftMenu.map(menuSection => (
          <MenuSection key={menuSection.title}>
            { menuSection.title && (
              <Typography element="span" weight="600" color="#9db4c4">
                { menuSection.title }
              </Typography>              
            )}

            { menuSection.items.map(item => (
              <MenuItem
                disabled={this.props.customer}
                key={item.id}              
                item={item}
                filterValue={this.props.filterValue}
                counter={menuSection.title === 'SETTINGS' ? null : this.props.count}
                currentURL={this.props.currentURL}
                changeOfMenu={this.changeOfMenu}
                active={this.props.filterValue === item.filterValue}
              />                  
            ))}
          </MenuSection>
        ))}
      </Menu>
    )
  }
}

LeftMenu.propTypes = {
  clearDetailedInvoice: PropTypes.func,
  leftMenu: PropTypes.array,
  filterValue: PropTypes.string,
  count: PropTypes.number,
  currentURL: PropTypes.string,
  customer: PropTypes.object
}

export default LeftMenu