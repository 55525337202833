import React from 'react'
import axios from 'axios'
import {UrlApi} from './../../envVar'

const LoginApiRequestContext = React.createContext({})

export const LoginApiRequestProvider = LoginApiRequestContext.Provider
export const LoginApiRequestConsumer = LoginApiRequestContext.Consumer

const cache = {}

const ApiRequest = async (path, reload = false) => {
  if (!reload && cache[path]) return cache[path]

  let result = await axios.get(`${UrlApi}/api/v1/${path}`)
  cache[path] = result

  return result
}

export function LoginApiRequest (props) {
  return (
    <LoginApiRequestProvider value={ApiRequest}>
      {props.children}
    </LoginApiRequestProvider>
  )
}