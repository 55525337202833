import { apiRequest } from '../shared/apiRequest'
import Toast from '../shared/toast'
import { onUpdateDeliveryMethod } from './deliveryMethod.action'
import { toggleDeleteModal } from './contacts.actions'
import { setActivity } from './app.actions'

export const ON_REQUEST = '@indigo/customers/ON_REQUEST'
export const ON_REQUEST_SUCCESS = '@indigo/customers/ON_REQUEST_SUCCESS'
export const ON_REQUEST_FAILURE = '@indigo/customers/ON_REQUEST_FAILURE'
export const FETCH_CUSTOMER = '@indigo/customers/FETCH_CUSTOMER'
export const FETCH_CUSTOMERS = '@indigo/customers/FETCH_CUSTOMERS'
export const DISPLAY_CUSTOMERS = '@indigo/customers/DISPLAY_CUSTOMERS'
export const SET_SELECTED_CUSTOMER = '@indigo/customers/SET_SELECTED_CUSTOMER'
export const SET_STARRED_CUSTOMERS = '@indigo/customers/SET_STARRED_CUSTOMERS'
export const ADD_STARRED_CUSTOMER = '@indigo/customers/ADD_STARRED_CUSTOMER'
export const REMOVE_STARRED_CUSTOMER = '@indigo/customers/REMOVE_STARRED_CUSTOMER'
export const SET_MIRANDA_CUSTOMERS = '@indigo/customers/SET_MIRANDA_CUSTOMERS'
export const ADD_CONTACT = '@indigo/customers/ADD_CONTACT'
export const CLEAR_REQUEST_PROCESS = '@indigo/customers/CLEAR_REQUEST_PROCESS'
export const DELETE_CONTACT = '@indigo/customers/DELETE_CONTACT'
export const EDIT_CONTACT = '@indigo/customers/EDIT_CONTACT'

export const onRequest = () => {
  return {
    type: ON_REQUEST
  }
}

export const onRequestSuccess = () => {
  return {
    type: ON_REQUEST_SUCCESS
  } 
}

export const onRequestFailure = () => {
  return {
    type: ON_REQUEST_FAILURE
  }
}

export const displayCustomers = customers => {
  return {
    type: DISPLAY_CUSTOMERS,
    payload: {
      customers
    }
  }
}

export const setStarredCustomers = starredCustomers => {
  return {
    type: SET_STARRED_CUSTOMERS,
    payload: {
      starredCustomers
    }
  }
}

export const addRemoveStarredCustomer = (customer, addRemove) => {
  const actionType =
    addRemove === 'add' ? ADD_STARRED_CUSTOMER : REMOVE_STARRED_CUSTOMER
  return {
    type: actionType,
    payload: {
      customer
    }
  }
}

export const clearRequestProcess = () => {
  return {
    type: CLEAR_REQUEST_PROCESS
  }
}

export const setMirandaCustomers = mirandaCustomers => {
  return {
    type: SET_MIRANDA_CUSTOMERS,
    payload: {
      mirandaCustomers
    }
  }
}

export const fetchCustomersFromMiranda = () => dispatch => {
  dispatch(onRequest)
  dispatch(setActivity(true))

  return apiRequest('GET', 'api/v1/customers/miranda')
    .then(response => {
      dispatch(setActivity(false))
      dispatch(setMirandaCustomers(response.data))
      dispatch(onRequestSuccess())
    })
    .catch(() => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure())
    })
}

export const fetchStarredCustomers = userFavorites => (dispatch) => {
  if (userFavorites) {
    let toastId = null

    if (userFavorites.length > 0) {
      dispatch(onRequest())
      dispatch(setActivity(true))
  
      if (window.location.pathname === '/') {
        toastId = Toast.callToast()
      }
      return apiRequest('POST', 'api/v1/customers/miranda/get-starred', {
        customers: userFavorites
      })
        .then(response => {
          dispatch(setActivity(false))
          const customers = response.data.map(customer => {
            customer = {
              Miranda_ID: customer.customer_id,
              ...customer
            }
        
            return customer
          })
          dispatch(onRequestSuccess())
          dispatch(setStarredCustomers(customers))
          Toast.dismissToast(toastId)
        })
        .catch(() => {
          dispatch(setActivity(false))
          Toast.dismissToast(toastId)
          dispatch(onRequestFailure())
        })
    } else {
      
      dispatch(onRequestSuccess())
      dispatch(setStarredCustomers([]))
    }
  }
}

export const onFetchCustomer = (customerID) => dispatch => {
  dispatch(onRequest())
  dispatch(setActivity(true))

  return apiRequest('get', `api/v1/customers/${customerID}`)
    .then(response => {
      dispatch(setActivity(false))
      dispatch(setSelectedCustomer(response.data))
      dispatch(onRequestSuccess())
    })
    .catch((error) => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure())
    })
}

export const onFetchCustomers = () => dispatch => {
  dispatch(onRequest())
  dispatch(setActivity(true))

  return apiRequest('get', 'api/v1/customers')
    .then(response => {
      dispatch(setActivity(false))
      dispatch(displayCustomers(response.data))
      dispatch(onRequestSuccess())
    })
    .catch(() => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure())
    })
}

export const onAddContact = (contact) => dispatch => {
  dispatch(onRequest)
  dispatch(setActivity(true))
  const newContact = {
    'First_Name': contact.firstName,
    'Last_Name': contact.lastName,
    'Email': contact.email
  }
  return apiRequest('post', `api/v1/customers/${contact.customer}/contacts/create`, newContact)
    .then(response => {
      dispatch(setActivity(false))
      dispatch(addContact(response.data))
      dispatch(onRequestSuccess('New contact created'))
    })
    .catch(() => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure)
    })
}

export const onEditContact = (contact) => (dispatch) => {
  dispatch(onRequest)
  dispatch(setActivity(true))
  
  const editedContact = {
    '_id': contact._id,
    'Accounting_Code': contact.customer,
    'First_Name': contact.firstName,
    'Last_Name': contact.lastName,
    'Email': contact.email
  }

  return apiRequest('put', `api/v1/customers/${contact.customer}/contacts/update/${contact._id}`, editedContact)
    .then(() => {
      dispatch(setActivity(false))
      dispatch(editContact(editedContact))
      dispatch(onRequestSuccess())
    })
    .catch(() => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure)
    })
}

export const editContact = (contact) => {
  return {
    type: EDIT_CONTACT,
    payload: {
      contact
    }
  }
}

export const onDeleteContact = (id, customer) => (dispatch, getState) => {
  dispatch(onRequest)
  dispatch(setActivity(true))
  
  const { contactsReducer } = getState()
  return apiRequest('delete', `api/v1/customers/${customer.Accounting_Code}/contacts/delete/${id}`)
    .then(() => {
      dispatch(setActivity(false))
      dispatch(deleteContact(id))
      contactsReducer.contacts.length === 1 && dispatch(onUpdateDeliveryMethod(true))
      dispatch(toggleDeleteModal())
      dispatch(onRequestSuccess())
    })
    .catch(() => {
      dispatch(setActivity(false))
      dispatch(onRequestFailure())
    })
}

export const deleteContact = (id) => {
  return {
    type: DELETE_CONTACT,
    payload: {
      id
    }
  }
}



export const addContact = (contact) => {
  return {
    type: ADD_CONTACT,
    payload: {
      contact
    }
  }
}

export const setSelectedCustomer = customer => {
  return {
    type: SET_SELECTED_CUSTOMER,
    payload: {
      customer
    }
  }
}
