/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Notes = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.28 16">
    <path d="M12.87,15.6H1.5A1.44,1.44,0,0,1,.05,14.17V1A1.45,1.45,0,0,1,1.5-.4H8A1.45,1.45,0,0,1,9,0l4.86,4.73a1.41,1.41,0,0,1,.43,1v8.4A1.44,1.44,0,0,1,12.87,15.6ZM1.5,1A.08.08,0,0,0,1.42,1V14.17s0,.06.08.06H12.87c.05,0,.08,0,.08-.06V5.77L8.07,1Z" transform="translate(-0.05 0.4)"/>
    <path d="M9.39,7.47H5A.69.69,0,0,1,5,6.09H9.39a.69.69,0,0,1,0,1.38Z" transform="translate(-0.05 0.4)"/>
    <path d="M9.39,9.79H5A.69.69,0,0,1,5,8.41H9.39a.69.69,0,0,1,0,1.38Z" transform="translate(-0.05 0.4)"/>
    <path d="M9.39,12.11H5a.69.69,0,0,1,0-1.38H9.39a.69.69,0,0,1,0,1.38Z" transform="translate(-0.05 0.4)"/>
  </SVG>
)

export default Notes