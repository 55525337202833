/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const CustomerIcon = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.99 16.01">
    <path d="M7.92,16a12.07,12.07,0,0,1-5.51-1,1,1,0,0,1-.35-1.17A5.48,5.48,0,0,1,5.65,10.6,4.39,4.39,0,0,1,4.31,9.5l-.07-.07a6,6,0,0,1-.63-.89h0l-.07-.12A3.85,3.85,0,0,1,3.33,8a3.39,3.39,0,0,1-.83-.39,1.75,1.75,0,0,1-.81-1.46,2.54,2.54,0,0,1,1-2.06,2.56,2.56,0,0,1,.39-.26,1.87,1.87,0,0,1,.07-.22,2.06,2.06,0,0,0,.09-.26C3.68,1.94,4.87.17,8.06,0c2.09-.12,4.56,1.3,4.89,3.58a1.7,1.7,0,0,1,0,.26A2.18,2.18,0,0,1,14.3,6.13a2.25,2.25,0,0,1-1.64,2,2.25,2.25,0,0,1-.35.6l-.15.21a4.17,4.17,0,0,1-.36.47l-.08.09a4.45,4.45,0,0,1-1.18,1,5.7,5.7,0,0,1,3.59,3.18,1,1,0,0,1,0,.81.91.91,0,0,1-.51.51A16,16,0,0,1,7.92,16ZM3.48,14a15.51,15.51,0,0,0,9.22-.09,4.17,4.17,0,0,0-3.07-2.2l-.56-.11V11c0-.23,0-.89,0-.93l0-.46.43-.17a2.66,2.66,0,0,0,1.18-.89A3.65,3.65,0,0,0,11,8.15l.17-.24a1.24,1.24,0,0,0,.28-.56l.14-.5h.52a1,1,0,0,0,.8-.83.88.88,0,0,0-.72-1l-.51-.1-.08-.81c0-.09,0-.13,0-.34-.22-1.51-2-2.49-3.46-2.4-2,.1-3.11.88-3.57,2.4-.07.23-.11.32-.13.38s0,.1,0,.27l-.1.78-.55-.14a1.21,1.21,0,0,0-.69,1.11c0,.32.49.48.73.55l.51-.2.21.71a2,2,0,0,0,.21.49l.08.13h0a3.8,3.8,0,0,0,.46.65,2.82,2.82,0,0,0,1.41,1l.48.16v1.94l-.52.12A4.43,4.43,0,0,0,3.48,14Zm-.14.32Z" transform="translate(-0.01 0)" />
  </SVG>
)

export default CustomerIcon 