import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: #888;
`

const Activity = () => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path className="cls-1" d="M8.06,16a.87.87,0,0,1-.83-.71L5.08,6.46,4,9.45a.86.86,0,0,1-.8.59H.69A.68.68,0,0,1,0,9.36a.69.69,0,0,1,.69-.69H2.81l1.57-4.3a.84.84,0,0,1,.84-.6A.88.88,0,0,1,6,4.48l2,8L9.93.79A.85.85,0,0,1,11.62.72l1.87,8h1.82a.69.69,0,0,1,.69.69.68.68,0,0,1-.69.68H13.07a.89.89,0,0,1-.84-.72l-1.36-5.8-2,11.69a.89.89,0,0,1-.83.79ZM5.67,4.84Z" transform="translate(0 0)"/>
  </SVG>
)

export default Activity