import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

export const SVG = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color || '#888'};
`

const Flag = ({color}) => (
  <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" className="flag" color={color}>
    <path 
      className="cls-1" 
      d="M15.16,11.47H0l0-.73V.05L.73,0H15.56L16,.68l-.17.38L13.34,6,16,10.73l-.4.72ZM1.36,10.1H14.08L11.81,6,12,5.65l2.18-4.28H1.36Z" 
      transform="translate(0 0)"/>
    <path 
      className="cls-1" 
      d="M.68,16A.69.69,0,0,1,0,15.31V.69A.69.69,0,0,1,.68,0a.68.68,0,0,1,.68.69V15.31A.68.68,0,0,1,.68,16Z" 
      transform="translate(0 0)"/>
  </SVG>)

Flag.propTypes = {
  color: PropTypes.string
}  

export default Flag
