import React, { Component } from 'react'
import styled from 'styled-components/macro'

const ButtonContainer = styled.div`

    svg{
        fill: ${props => props.disabled ? '#a4a4a4' : '#fff'};
    }
    align-items: center;
    width: min-content;
    background-color: ${props => props.disabled ? '#e2e2e2' : '#6887b4'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    display: flex;
    justify-content: center;
    padding: 0 22px 0 22px;
    svg {
        height: 16px;
        margin-right: 8px;
        width: 16px;        
    }    

    &.secondary {
        color: ${props => props.disabled ? '#888' : '#6887b4'};
        background-color: transparent;
        opacity: 1;
        line-height: 20px;
        height: auto;
        padding: 4px 0;
        position: relative;
        &:hover{            
            &:after{
              content: '';
              background-color: #6887b4;
              width: 100%;
              height: 2px;
              display: block;
              position: absolute;
              bottom: 4px;
            }
        }
    }
`
const Button = styled.input`
    height: 40px;    
    text-transform: uppercase;
    border: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    align-self: center;
    color: ${props => props.disabled ? '#a4a4a4' : '#fff'};
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    opacity: ${props => props.disabled ? '0.8' : '1'};
    background-color: ${props => props.disabled ? '#e2e2e2' : '#6887b4'};

    &.secondary {
        color: ${props => props.disabled ? '#888' : '#6887b4'};
        background-color: transparent;
        opacity: 1;
        line-height: 20px;
        height: auto;
        padding: 4px 0;        
    }
`

export default class extends Component {
  render() {
    return (
      <ButtonContainer onClick={this.props.onClick} disabled={this.props.disabled} className={this.props.btnType + ' ' + this.props.className}>
        {this.props.children}
        <Button
          disabled={this.props.disabled}
          type={this.props.type}
          name={this.props.name}
          value={this.props.value}
          id={this.props.id}
          btnType={this.props.btnType}
          className={this.props.btnType}
        />
      </ButtonContainer>
    )
  }
}