import React from 'react'
import styled from 'styled-components/macro'
import { Modal as BootstrapModal } from 'react-bootstrap'
import Typography from '../Typography'
import ExitNoCircle from '../../../assets/svgs/ExitNoCircle'
import SearchBar from './SearchBar'
import PropTypes from 'prop-types'
import SearchResponseList from './SearchResponseList'


const ModalContainer = styled(BootstrapModal)`
  width: 836px;
  min-height: 133px;
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
  }
  box-shadow: none;
  // modal sizing
  .modal {
    &-full {
      width: 100%;
      height: 133px;
      background-color: #ffffff;
      bottom: none;
      margin: 0;
      .modal-header .modal-title {
        font-size: 26px;
        line-height: 36px;
      }
      .modal-content {
        
        overflow: auto;
        background-color: unset;
        box-shadow: none;
        border: none;
        
      }
      .modal-body {
        padding-bottom: 0;
      }
    }

    &-lg {
      width: 960px;
      margin: 80px auto auto;
    }

    &-md {
      width: 680px;
      margin: 80px auto auto;
    }

    &-sm,
    &-sm-gray {
      width: 496px;
      margin: 80px auto auto;
      &-gray .modal-content {
        background: #fafafa;
      }
    }

    &-min {
      width: 368px;
      margin: 80px auto auto;
    }
  }
  .modal-content {
    border-radius: 4px;
    max-height: 100%;
    min-height: 133px;
  }
  .modal-body {
    padding: 0;
    .form-group input {
      width: calc(100% - 16px);
    }
  }
  .modal-header {
    border-bottom: none;
    padding: 0;
    .modal-title {
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.4px;
      color: #252839;
    }
    border-bottom-width: inherit;
    border-block-end-color: #e2e2e2;
    border-bottom-style: solid;
  }
  .modal-footer {
    padding: 0;
    border-top: none;
    #cancel {
      width: 60px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: center;
      color: #6887b4;
      margin-right: 24px;
      padding: 0;
      background-color: transparent;
    }
  }
`

const ExitModal = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 32px;
  top: 26px;
  z-index: 1;
  svg {
    height: 12px;
    width: 12px;
    margin-top: -2px;
    fill: #888888;
    &:hover {
      fill: black;
    }
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;

  p {
    line-height: 24px;
    letter-spacing: 0.4px;
    margin: 0;
  }
  p:first-child {
    font-weight: 600;
    color: #252839;
  }
  p:last-child {
    color: #888888;
    margin-left: auto;
  }
`
const OptionsDiv = styled.div`
  margin: 24px 476px 24px 32px;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: #888888;
  display: flex;
  justify-content: space-between;
  .option {
    display: flex;
    justify-content: space-between;
    margin-right: 8px;
    p {
      margin: 0 2px;
    }
  }
`

const SearchModalView = ({
  header,
  cursor,
  results,
  searchText,
  handleInput,
  handleClear,
  notifyFocus,
  handleSelectItem,
  handleModal,
  isOpen,
  dropdownValue,
  handleDropDownChange,
  notifyMouseLeave,
  onRequest
}) => {
  return (
    <ModalContainer
      show={isOpen}
      onExit={handleModal}
      backdrop={true}
    >
      <ExitModal onClick={handleModal}>
        <ExitNoCircle />
      </ExitModal>

      {header && (
        <BootstrapModal.Header>
          <Header>
            <SearchBar
              onRequest={onRequest}
              searchText={searchText}
              handleInput={handleInput}
              handleClear={handleClear}
              dropdownValue={dropdownValue}
              responseList={results}
              handleDropDownChange={handleDropDownChange}
            />
          </Header>
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body>
        <SearchResponseList
          notifyMouseLeave={notifyMouseLeave}
          searchText={searchText}
          responseList={results}
          cursor={cursor}
          notifyFocus={notifyFocus}
          handleSelectItem={handleSelectItem}
          dropdownValue={dropdownValue}
        />        
        <OptionsDiv>
          <div className="option">
            <Typography element="span" weight="600" color="#888888">
              up/down
            </Typography>
            <Typography element="span" color="#888888">
              to navigate
            </Typography>
          </div>

          <div className="option">
            <Typography element="span" weight="600" color="#888888">
              enter
            </Typography>
            <Typography element="span" color="#888888">
              to select
            </Typography>
          </div>

          <div className="option">
            <Typography element="span" weight="600" color="#888888">
              esc
            </Typography>
            <Typography element="span" color="#888888">
              to close
            </Typography>
          </div>
        </OptionsDiv>
      </BootstrapModal.Body>
      <BootstrapModal.Footer>
      </BootstrapModal.Footer>
    </ModalContainer>
  )
}

SearchModalView.propTypes = {
  cursor: PropTypes.number,
  results: PropTypes.array,
  handleKeyDown: PropTypes.func,
  notifyFocus: PropTypes.func,
  itemSelected: PropTypes.func,
  handleSelectItem: PropTypes.func,
  handleModal: PropTypes.func,
  header: PropTypes.bool,
  searchText: PropTypes.string,
  handleInput: PropTypes.func,
  handleClear: PropTypes.func,
  isOpen: PropTypes.bool,
  dropdownValue: PropTypes.string,
  handleDropDownChange: PropTypes.func,
  notifyMouseLeave: PropTypes.func
}

export default SearchModalView
