const getMoney = (money) => {
  money = money ? money.toString() : '0'
  let value = money.split('.')
  if (value.length === 2) {
    if (value[1].split('').length === 2) {
      return Intl.NumberFormat().format(money)
    } else if (value[1].split('').length === 1) {
      return `${Intl.NumberFormat().format(money)}0`
    } else if (value[1].split('').length > 2) {
      if (value[1].split('')[2] === '0') {
        return Intl.NumberFormat('us', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })
          .format(money).replace('$', '').replace('US', '')
      } else {
        return Intl.NumberFormat('us', { style: 'currency', currency: 'USD', minimumFractionDigits: 3 })
          .format(money).replace('$', '').replace('US', '')
      }
    }
  } else if (value.length === 1) {
    return `${Intl.NumberFormat().format(money)}.00`
  }
}
export default getMoney