import { apiRequest } from '../shared/apiRequest'
import Toast from '../shared/toast'
import { setInvoiceSelected } from './components/customer/invoices'
import { FILTER_BY, CUSTOMER } from '../shared/constants/filters'
import { searchInvoices, getInvoices } from './components/customer/invoices'

const CONNECTION_ERROR = 'Unable to connect with the server. Please try later'

export const SET_ACTIVITY = 'SET_ACTIVITY'
export const setActivity = (isPerformingAnAction) => ({
  type: SET_ACTIVITY,
  isPerformingAnAction
})

export const SET_SELECTED_CUSTOMER = '@indigo/app/SET_SELECTED_CUSTOMER'
export const setSelectedCustomer = (customer) => {
  return {
    type: SET_SELECTED_CUSTOMER,
    payload: {
      customer
    }
  }
}

export const handleSendEmail = (email, inData) => {
  let result = ''

  switch (email) {
  case 'jcherrera@casestack.com':
    result = email.replace('@casestack.com', '@belatrixsf.com')
    break
  case 'lblanco@casestack.com':
    result = email.replace('@casestack.com', '@belatrixsf.com')
    break
  case 'davrodriguez@casestack.com':
    result = email.replace('@casestack.com', '@belatrixsf.com')
    break
  case 'cafernandez@casestack.com':
    result = email.replace('@casestack.com', '@belatrixsf.com')
    break
  case 'cavendano@casestack.com':
    result = email.replace('@casestack.com', '@belatrixsf.com')
    break
  default:
    result = email
    break
  }

  return async (dispatch, getState) => {
    const { invoicesReducer, customersReducer, appReducer } = getState()

    dispatch(setInvoiceSelected())

    const customer = customersReducer.selectedCustomer || appReducer.selectedCustomer

    let invoiceSelected = invoicesReducer.invoiceSelected

    const filter = invoicesReducer.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : invoicesReducer.filterValue


    customer.invoices = invoicesReducer.invoices[filter].data.filter(invoice => {
      if (filter === CUSTOMER.FOUND) {
        if (invoice.Accounting_Code === invoicesReducer.filterValue) return invoice.checked
      } else {
        return invoice.checked
      }
    })

    if (invoiceSelected && invoiceSelected.Invoice_Number) {
      customer.invoices = [
        invoiceSelected
      ]
    }

    let data = {
      customer,
      templates: ['invoice-pdf'],
      email: result,
      action: 'Email'
    }
    if (inData) {
      data = {
        ...data,
        to: inData.to,
        cc: inData.cc || [],
        customMessage: inData.message || ''
      }
    }
    let message = ''
    if (customer.invoices.length === 1) {
      message = 'One invoice sent'
    } else {
      message = `${customer.invoices.length} invoices sent`
    }

    try {
      dispatch(setActivity(true))
      await apiRequest('post', 'api/v1/emails/send', data)
        .then(async (res) => {
          if (invoicesReducer.lastSearchedInvoices)
            await dispatch(searchInvoices(invoicesReducer.lastSearchedInvoices))
          if (customer && customer.Accounting_Code)
            await dispatch(getInvoices(customer.Accounting_Code, false, true))
          Toast.callToast(3000, message, false)
          
          return res.data          
        })
      dispatch(setActivity(false))


    } catch (error) {
      dispatch(setActivity(false))
      Toast.callToast(3000, CONNECTION_ERROR, false)
    }
  }
}

export const sendToPrintQueue = () => {
  return async (dispatch, getState) => {
    const { invoicesReducer, customersReducer, appReducer } = getState()

    const customer = customersReducer.selectedCustomer || appReducer.selectedCustomer

    let invoiceSelected = invoicesReducer.invoiceSelected

    const filter = invoicesReducer.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : invoicesReducer.filterValue

    customer.invoices = invoicesReducer.invoices[filter].data.filter(invoice => {
      if (filter === CUSTOMER.FOUND) {
        if (invoice.Accounting_Code === invoicesReducer.filterValue) return invoice.checked
      } else {
        return invoice.checked
      }
    })

    if (invoiceSelected && invoiceSelected.Invoice_Number) {
      customer.invoices = [
        invoiceSelected
      ]
    }

    let data = {
      customer,
      templates: ['invoice-pdf'],
      action: 'Print'
    }

    try {
      dispatch(setActivity(true))
      const result = await apiRequest('post', 'api/v1/printQueue/send', data)
      dispatch(setActivity(false))

      //Transfer completed: 226
      if (result.data.code === 226) {
        Toast.callToast(3000, 'Added to Print Queue', false)
      }
      else {
        Toast.callToast(3000, CONNECTION_ERROR, false)
      }
    }
    catch (e) {
      dispatch(setActivity(false))
      Toast.callToast(3000, CONNECTION_ERROR, false)
    }
  }
}

export const HANDLE_DOWNLOAD_DOCUMENT = '@indigo/app/HANDLE_DOWNLOAD_DOCUMENT'
export const handleDownloadDocument = () => async (dispatch, getState) => {
  try {
    const { appReducer, invoicesReducer, customersReducer } = getState()

    let customerSelected = appReducer.selectedCustomer || customersReducer.selectedCustomer

    let invoiceSelected = invoicesReducer.invoiceSelected

    const filter = invoicesReducer.filterBy === FILTER_BY.CUSTOMER ? CUSTOMER.FOUND : invoicesReducer.filterValue

    customerSelected.invoices = invoicesReducer.invoices[filter].data.filter(invoice => {
      if (filter === CUSTOMER.FOUND) {
        if (invoice.Accounting_Code === invoicesReducer.filterValue) return invoice.checked
      } else {
        return invoice.checked
      }
    })

    if (invoiceSelected && invoiceSelected.Invoice_Number) {
      customerSelected.invoices = [
        invoiceSelected
      ]
    }

    let dataToSend = {
      'templates': ['invoice-pdf'],
      'customer': customerSelected
    }

    dispatch(setActivity(true))
    const response = await apiRequest(
      'POST',
      'api/v1/documents/compile',
      dataToSend
    )
    dispatch(setActivity(false))

    const documentURL = response.data.url
    const url = documentURL
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'file.pdf')
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    dispatch(setActivity(false))
    // eslint-disable-next-line no-console
    console.log('Error in PDF', error)
  }
}
