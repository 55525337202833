import React, { Component } from 'react'
import { connect } from 'react-redux'
import NavbarContainer from './../../components/shared/Navbar'
import { setOpenSearchModal } from '../../actions/route'
import { clearCustomerDetails } from './../../actions/components/customer/invoices'
import { withRouter } from 'react-router-dom'

class Navbar extends Component {
  constructor(props) {
    super(props)

    this.generateTitle = this.generateTitle.bind(this)
  }

  generateTitle() {
    let title = ''
    
    switch (this.props.location.pathname) {
    case '/':
      title = 'Customers'
      break

    case '/invoices/search-results':
      title = 'search results'
      break
    
    default:
      title = this.props.customer && this.props.customer.Name
      break
    }
    
    return title

  }

  render() {
    return (
      <NavbarContainer 
        {...this.props}
        title={this.props.title}
      />
    )
  }
}


const mapStateToProps = (state) => ({
  customer: state.customersReducer.selectedCustomer,
})

const mapDispatchToProps = (dispatch) => ({
  handleSearchButton: () => dispatch(setOpenSearchModal()),
  clearCustomerDetails: ()=> dispatch(clearCustomerDetails())
})

export default withRouter(connect(
  mapStateToProps, mapDispatchToProps
)(Navbar))