import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  setDetailedInvoice,
  updateDetailedInvoice,
  clearDetailedInvoice
} from '../../../actions/components/searchResults/searchResults.actions'
import {
  setFilter,
  searchInvoices,
  handleDisputing
} from '../../../actions/components/customer/invoices'
import { setSelectedCustomer } from '../../../actions/app.actions'
import { onFetchContactsByCustomer } from '../../../actions/contacts.actions'
import SearchResultsView from '../../../components/searchResults/SearchResultsView'
import { clearCustomerDetails } from '../../../actions/components/customer/invoices'
import { CUSTOMER } from '../../../shared/constants/filters'
import { AuthConsumer } from '../../../auth/AuthContext'

class SearchResults extends Component {
  constructor(props) {
    super(props)

    this.getCustomersFromInvoices = this.getCustomersFromInvoices.bind(this)
    this.filterByCustomer = this.filterByCustomer.bind(this)
    this.changeDisputingStatus = this.changeDisputingStatus.bind(this)
    this.calculateInvoicesByCustomer = this.calculateInvoicesByCustomer.bind(this)
    this.handleReturn = this.handleReturn.bind(this)
  }

  async componentDidMount() {
    const invoicesSearched = window.localStorage.getItem('searchValues')
    const filteredInvoices = invoicesSearched.trim().split(' ').filter(invoice => invoice !== '')
    
    this.props.searchInvoices(filteredInvoices)
  }

  componentDidUpdate(prevProps) {
    const { searchedInvoices, searchInvoices } = this.props
    if (searchedInvoices !== null && prevProps.searchedInvoices !== this.props.searchedInvoices) {
      const filteredInvoices = searchedInvoices.trim().split(' ')
        .filter(invoice => invoice !== '')
      searchInvoices(filteredInvoices)
    }
  }


  filterByCustomer(filterBy, filterCustomer) {

    const { setSelectedCustomer, onFetchContactsByCustomer, setFilter } = this.props

    if (filterCustomer.Accounting_Code) {
      setSelectedCustomer(filterCustomer)
      onFetchContactsByCustomer(filterCustomer.Accounting_Code)
      setFilter(filterBy, filterCustomer.Accounting_Code)
    } else {
      setSelectedCustomer(CUSTOMER.NOT_FOUND)
      setFilter(filterBy, filterCustomer)
    }
  }

  changeDisputingStatus(isDisputing) {
    this.props.handleDisputing(isDisputing)
    this.props.updateDetailedInvoice('Disputing', isDisputing)
  }

  getCustomersFromInvoices() {
    const { foundInvoices } = this.props

    let customers = []

    if (foundInvoices.length > 0) {
      foundInvoices.forEach(invoice => {
        if (customers.map(customer => customer.Accounting_Code).indexOf(invoice.Accounting_Code) === -1) {
          customers.push(
            {
              Customer_Name: invoice.Customer_Name,
              Accounting_Code: invoice.Accounting_Code,
              Customer_Amount_Due: invoice.Customer_Amount_Due
            }
          )
        }
      })
  
      customers = customers.sort(function (a, b) {
        if (a.Customer_Name > b.Customer_Name) {
          return 1
        }
        if (a.Customer_Name < b.Customer_Name) {
          return -1
        }
  
        return 0
      })  
    } 

    return customers
    
  }

  calculateInvoicesByCustomer() {
    const { foundInvoices, notFoundInvoices, filterValue } = this.props

    if (foundInvoices && filterValue !== CUSTOMER.NOT_FOUND) {
      return foundInvoices.filter(invoice => invoice.Accounting_Code === filterValue).length
    }

    return notFoundInvoices.length
  }

  componentWillUnmount() {
    this.props.clearDetailedInvoice()
    this.props.clearCustomerDetails()
  }

  handleReturn() {
    this.props.clearDetailedInvoice()
    this.props.clearCustomerDetails()
  }

  render() {
    return (
      <SearchResultsView
        currentUser={this.props.currentUser}
        changeDisputingStatus={this.changeDisputingStatus}
        filterByCustomer={this.filterByCustomer}
        customers={this.getCustomersFromInvoices()}
        handleReturn={this.handleReturn}
        filterValue={this.props.filterValue}
        counter={this.calculateInvoicesByCustomer()}
        {...this.props}
      />
    )
  }
}

const mapStateToProps = ({ appReducer, searchResultsReducer, invoicesReducer }) => {
  return {
    selectedCustomer: appReducer.selectedCustomer,
    searchedInvoices: invoicesReducer.searchedInvoices,
    detailedInvoice: searchResultsReducer.detailedInvoice,    
    foundInvoices: invoicesReducer.invoices[CUSTOMER.FOUND].data,
    notFoundInvoices: invoicesReducer.invoices[CUSTOMER.NOT_FOUND].data,
    filterBy: invoicesReducer.filterBy,
    filterValue: invoicesReducer.filterValue,    
  }
}

const mapDispatchToProps = {
  setSelectedCustomer,
  setFilter,
  setDetailedInvoice,
  clearDetailedInvoice,
  searchInvoices,
  onFetchContactsByCustomer,
  clearCustomerDetails,
  handleDisputing,
  updateDetailedInvoice,
}

const SearchResultsWithUser = props => (
  <AuthConsumer>
    {currentUser => (
      <SearchResults {...props} 
        currentUser={currentUser}/>
    )}
  </AuthConsumer>
)

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultsWithUser)