/* eslint-disable max-len */

import React from 'react'
import styled from 'styled-components/macro'

const SVG = styled.svg`
  width: 16px;
  height: 16px;
  fill: #6887b4;
  pointer-events: none;
`

const Angle = () => (
  <SVG id="angleSVG" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8">
    <path d="M8,12a1.41,1.41,0,0,1-1-.38L.29,5.55A.9.9,0,0,1,.23,4.29a.89.89,0,0,1,1.25-.06L8,10.12l6.51-5.89a.89.89,0,1,1,1.2,1.32L9,11.63A1.44,1.44,0,0,1,8,12Zm-.21-1.69Zm.42,0Z" transform="translate(0 -4)"/>
  </SVG>
)

export default Angle