import React from 'react'
import Typography from '../shared/Typography'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  margin-left: 8px;
  margin-right: 8px;
  &>img{
    margin-left:13px;
    margin-top: -1px;
    height:24px;
    width: 24px;
  }
`

const LoadingMessage = (props) => {
  return (
    <Wrapper>
      <Typography element="p" weigth="200"  color="#ffffff">
        {props.message}      
      </Typography>
      {props.children}
    </Wrapper>
  )
}

export default LoadingMessage

