import React from 'react'
import PropTypes from 'prop-types'
import Badge from './../components/shared/Badge'
import Typography from './../components/shared/Typography'

const PrintBadge = ({status}) => {
  let color
  let show = true
  switch (status) {
  case 'Current':
    color = 'rgba(248, 192, 19, 0.2)'
    break
  case 'Past Due':
    color = 'rgba(209, 73, 91, 0.2)'
    break
  case 'Closed':
    color = 'rgba(57, 146, 105, 0.2)'
    break
  default:
    show = false
    break
  }

  return(
    (show) ? (
      <Badge color={color}>
        <Typography element="p">
          {status}
        </Typography>
      </Badge>
    ) : ('')
  )
}

PrintBadge.propTypes = {
  status: PropTypes.string
}

export default PrintBadge