import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Notes from './Notes'
import Switch from '../../shared/Switch'
import Typography from '../../shared/Typography'
import Calendar from '../../../assets/svgs/Calendar'
import Order from '../../../assets/svgs/Order'
import Money from '../../../assets/svgs/Money'
import Flag from '../../../assets/svgs/Flag'
import PrintBadge from './../../../shared/PrintBadge'
import Activity from '../../../assets/svgs/Activity'
import ExclamationMark from '../../../assets/svgs/ExclamationMark'
import getMoney from './../../../shared/utils/getMoney'
import './styles.scss'
import styled from 'styled-components/macro'

const Title = styled.td`
  display: flex;  
  align-items: center;

  p{
    color: #888;
    font-weight: 600;
  }  
`

const InvoiceDetails = ({ invoice, changeDisputingStatus }) => {
  return (
    <React.Fragment>
      <table className="customer-details">
        <tbody>
          <tr>
            <Title>
              <Calendar />
              <Typography>
                Date
              </Typography>
            </Title>
            <td>
              {(invoice && invoice.Invoice_Date) ? (
                <Typography>
                  {moment(invoice.Invoice_Date).format('MMM D, YYYY')}
                </Typography>
              ) : ''}
            </td>
          </tr>
          <tr>
            <Title>
              <Money />
              <Typography>
                Original Amt Due
              </Typography>
            </Title>
            <td>
              {(invoice.Total_Amount || invoice.Total_Amount === '0') ? (
                <Typography>
                  ${getMoney(invoice.Total_Amount)}
                </Typography>
              ) : ''}
            </td>
          </tr>
          <tr>
            <Title>
              <Activity />
              <Typography>
                Status
              </Typography>
            </Title>
            <td>
              <PrintBadge status={invoice.Status}/>
            </td>
          </tr>
          <tr>
            <Title>
              <Money />
              <Typography>
                Currently Due
              </Typography>
            </Title>
            <td>
              {(invoice.Amount_Due || invoice.Amount_Due === '0') ? (
                <Typography>
                  ${getMoney(invoice.Amount_Due)}
                </Typography>
              ) : ''}
            </td>
          </tr>
          <tr>
            <Title>
              <Order />
              <Typography>
                Order
              </Typography>
            </Title>
            <td>
              <Typography>
                {invoice.Reference_Number}
              </Typography>
            </td>
          </tr>
          <tr>
            <Title>
              <Flag />
              <Typography>
                Claim
              </Typography>
            </Title>
            <td>
              <Typography></Typography>
            </td>
          </tr>
          <tr>
            <Title>
              <ExclamationMark />
              <Typography>
                Disputing
              </Typography>
            </Title>
            <td>              
              {invoice && (
                <Switch
                  isDisabled={invoice.Status === 'Closed'}
                  handleChange={() => changeDisputingStatus(!invoice.Disputing)}
                  active={invoice.Disputing}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      {(invoice && invoice.Invoice_Number) ? (
        <Notes invoiceNumber={invoice.Invoice_Number} />
      ) : ''}
    </React.Fragment>
  )
}

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  changeDisputingStatus: PropTypes.func
}

export default InvoiceDetails