import React, { Component } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { LoginApiRequestConsumer } from './../LoginApiRequest'
import { AuthProvider } from '../AuthContext'
import {UrlApi} from './../../envVar'
import  { connect } from 'react-redux'
import {onSetUser} from '../../actions/users.actions'

export class AuthManager extends Component {
  constructor (props) {
    super(props)
    this.state = {userToken: null, currentUserGroups: null, user: null, currentUser: null}
    this.currentRequestInterceptor = null
    this.currentResponseInterceptor = null
    this.login = this.login.bind(this)
    this.logout = this.logout.bind(this)
    this.requestInterceptor = this.requestInterceptor.bind(this)
    this.requestInterceptorError = this.requestInterceptorError.bind(this)
    this.responseInterceptor = this.responseInterceptor.bind(this)
    this.responseInterceptorError = this.responseInterceptorError.bind(this)

    const userToken = window.localStorage.getItem('userToken')
    
    if (userToken) {
      this.state = {
        userToken,
        currentUserGroups: JSON.parse(window.localStorage.getItem('currentUserGroups'))
      }
      this.registerInterceptor()
    } 
  }

  componentDidMount () {
    return this.loadUser()
  }

  componentWillUnmount () {
    if (this._asyncRequest) {
      this._asyncRequest.cancel()
    }
    this.removeInterceptors()
  }

  async loadUser () {
    this._asyncRequest = this.props.apiRequest('users/me')
      .then(currentUser => {
        this._asyncRequest = null
        this.props.onSetUser(currentUser.data)
        this.setState({currentUser})
        return currentUser
      })
    return this._asyncRequest
  }

  isLoggedIn () {
    return !!this.state.userToken
  }

  requestInterceptor (config) {
    config.headers.authorization = `Bearer ${this.state.userToken}`
    return config
  }

  requestInterceptorError (error) { return Promise.reject(error) }

  responseInterceptor (response) { return response }

  responseInterceptorError (error) {
    if (error.response && error.response.status === 401) {
      return this.logout()
    }
    return Promise.reject(error)
  }

  registerInterceptor () {
    this.currentRequestInterceptor = axios.interceptors.request
      .use(this.requestInterceptor, this.requestInterceptorError)
    this.currentResponseInterceptor = axios.interceptors.response
      .use(this.responseInterceptor, this.responseInterceptorError)
  }

  removeInterceptors () {
    axios.interceptors.request.eject(this.currentRequestInterceptor)
    axios.interceptors.response.eject(this.currentResponseInterceptor)
    this.currentRequestInterceptor = null
    this.currentResponseInterceptor = null
  }

  login (query) {
    window.localStorage.setItem('userToken', query.token)
    window.localStorage.setItem('currentUserGroups', JSON.stringify(query.groups))
    this.setState({
      userToken: query.token,
      currentUserGroups: query.groups
    })
    this.registerInterceptor()
  }

  async logout () {
    let token = window.localStorage.getItem('userToken')
    window.localStorage.removeItem('userToken')
    window.localStorage.removeItem('currentUserGroups')
    if (this.isLoggedIn()) {
      this.setState({
        userToken: null,
        currentUserGroups: null
      })
    }
    this.removeInterceptors()
    window.location.href = `${UrlApi}/api/v1/users/logout?token=${token}`
  }

  render () {
    return (
      <AuthProvider 
        value={{...this.state,
          login: this.login,
          logout: this.logout,
          isLoggedIn: this.isLoggedIn,
          currentUserData: (this.state.currentUser)?(this.state.currentUser.data):(this.state.currentUser)}}>
        {this.props.children}
      </AuthProvider>
    )
  }
}

AuthManager.propTypes = {
  apiRequest: PropTypes.func,
  children: PropTypes.object
}
const mapDispatchToProps = {
  onSetUser
}

const AuthManagerWithLoginApiRequestConsumer = (props) => {
  return (
    <LoginApiRequestConsumer>
      { ApiRequest => (
        <AuthManager {...props} apiRequest={ApiRequest} />
      )}
    </LoginApiRequestConsumer>
  )
}
export default connect( null, mapDispatchToProps)(AuthManagerWithLoginApiRequestConsumer)
