import React, { Component } from 'react'
import { Badge as BootstrapBadge } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Typography from './Typography'

export const StyledBadge = styled (BootstrapBadge)`
    &.badge {
        border-radius: 12px;
        width: ${props => props.children.type === Typography ? 'max-content' : '38px' };
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${props => props.color};
        margin: 4px 4px;
        margin-left: 0px;

        h1, h2, h3, h4, p {
          color: #252839;
          margin: 0px 12px !important;
        }

        svg {
          width: 14px;
          fill: #252839;
        }
    }
`

class Badge extends Component {
  render() {
    return (
      <StyledBadge
        icon={this.props.icon}
        color={this.props.color}
      >
        {this.props.children}
      </StyledBadge>
    )
  }
}

Badge.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  children: PropTypes.object
}

export default Badge