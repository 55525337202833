import React from 'react'
import getInitialLetters from '../../../../../shared/utils/getInitialLetters'
import Download from '../../../../../assets/svgs/Download'
import Money from '../../../../../assets/svgs/Money'
import Plane from '../../../../../assets/svgs/Plane'
import ExclamationMark from '../../../../../assets/svgs/ExclamationMark'
import Print from '../../../../../assets/svgs/Print'
import Tooltip from '../../../../shared/Tooltip'

export const ParseName = (name) => {
  if (name === 'auto') {
    return <span className='user-name'>automatically</span>
  } else if (name) {
    return <Tooltip toolTipText={name}>
      <span className='user-name'>by {getInitialLetters(name)}</span>
    </Tooltip>
  } else {
    return ''
  }
}

export const modifyEmailsDueToLength = emailArray => {
  return emailArray.map(email => {
    let local = email.split('@')[0]

    if (local.length > 24) {
      return `${local}...`
    } else {
      return email
    }
  })
}

export const ParseSubjects = (subjects) => {
  if (subjects && subjects.length > 0) {
    let count = 0

    if (subjects.length > 2) {
      if (subjects.length === 3) {
        count = '& another'
      } else {
        count = `& ${subjects.length - 2} others`
      }
      subjects = subjects.slice(0, 2)
    } else {
      count = ''
    }

    let modifiedSubjects = modifyEmailsDueToLength(subjects)

    let tooltipText = `to <br/>${modifiedSubjects.join().split(',').join(', ')} ${count}`

    return <Tooltip toolTipText={tooltipText}><span>Sent </span></Tooltip>
  } else {
    return 'Sent '
  }
}

export const TooltipPayments = (amount, credit) => {
  if (amount && credit) {
    return <Tooltip toolTipText={`check #${credit}`}><span>${amount} payment</span></Tooltip>
  }
}

export const TooltipCredits = (amount, credit) => {
  if (amount && credit) {
    return <Tooltip toolTipText={`check #${credit}`}><span>${amount} credit</span></Tooltip>
  }
}

export const IconsList = (action) => ({
  Print: {
    icon: <Print />,
    backgroundColor: 'rgba(104, 135, 180, 0.2)',
    iconColor: '#6887b4',
    label: <React.Fragment>Added to queue {ParseName(action.User_Name)}</React.Fragment>
  },
  Email: {
    icon: <Plane />,
    backgroundColor: 'rgba(104, 135, 180, 0.2)',
    iconColor: '#6887b4',
    label: <React.Fragment>{ParseSubjects(action.Sent_To)} {ParseName(action.User_Name)}</React.Fragment>
  },
  Downloaded: {
    icon: <Download />,
    backgroundColor: 'rgba(104, 135, 180, 0.2)',
    iconColor: '#6887b4',
    label: <React.Fragment>Downloaded {ParseName(action.User_Name)}</React.Fragment>
  },
  Disputing: {
    icon: <ExclamationMark />,
    backgroundColor: 'rgba(104, 135, 180, 0.2)',
    iconColor: '#6887b4',
    label: <React.Fragment>Marked as disputing {ParseName(action.User_Name)}</React.Fragment>
  },
  Credit: {
    icon: <Money />,
    backgroundColor: 'rgba(57, 146, 105, 0.2)',
    iconColor: '#399269',
    label: <React.Fragment>{TooltipCredits(action.Amount, action.Credit_Id)}</React.Fragment>
  },
  Payment: {
    icon: <Money />,
    backgroundColor: 'rgba(57, 146, 105, 0.2)',
    iconColor: '#399269',
    label: <React.Fragment>{TooltipPayments(action.Amount, action.Credit_Id)}</React.Fragment>
  }
})

export default IconsList