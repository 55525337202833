import React, { Component } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import ContactFormView from './ContactFormView'

class ContactForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      customer: props.customer.Accounting_Code,
      isEmailValid: false,
      wasEdited: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
  }

  componentDidMount() {
    if (this.props.contactToEdit) {
      this.setState({
        firstName: this.props.contactToEdit.First_Name,
        lastName: this.props.contactToEdit.Last_Name,
        email: this.props.contactToEdit.Email,
        isEmailValid: false
      })
    }
  }

  handleInput(e) {
    let isEmailValid = this.state.isEmailValid
    //eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if(e.target.name !== 'email'){
      const nameRegex = /\d/
      if(nameRegex.test(e.target.value)) return
    }

    isEmailValid = regex.test(this.state.email)
    
    if (e.target.name === 'email') {
      isEmailValid = regex.test(e.target.value)
    }

    this.setState({
      [e.target.name]: e.target.value,
      isEmailValid,
      wasEdited: true
    })
  }

  handleSave() {
    this.props.onSave(this.state)
    this.props.onCancel()
  }

  handleEdit() {
    this.props.onEdit({ ...this.state, _id: this.props.contactToEdit._id })
    this.props.onCancel()
  }

  render() {
    const { firstName, lastName, email, isEmailValid, wasEdited } = this.state
    const { contactToEdit, onCancel } = this.props

    return (
      <ContactFormView
        contactToEdit={contactToEdit}
        firstName={firstName}
        lastName={lastName}
        email={email}
        handleInput={this.handleInput}
        isEmailValid={isEmailValid}
        onCancel={onCancel}
        onSave={debounce(this.handleSave, 1000)}
        onEdit={this.handleEdit}
        wasEdited={wasEdited}
      />
    )
  }
}

ContactForm.propTypes = {
  customer: PropTypes.object,
  contactToEdit: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onSave: PropTypes.func
}

export default ContactForm