/* eslint react/display-name: 0 */
import React from 'react'
import moment from 'moment'
import Typography from '../../shared/Typography'
import Badge from '../../shared/Badge'
import Money from '../../../assets/svgs/Money'
import Flag from '../../../assets/svgs/Flag'
import ExclamationMark from '../../../assets/svgs/ExclamationMark'
import getMoney from '../../../shared/utils/getMoney'
import { lastActivity } from '../../../shared/utils/lastActivity'
import Tooltip from '../../shared/Tooltip'
import styled from 'styled-components'


import PrintBadge from './../../../shared/PrintBadge'

const lastActivityMethod = {
  Print: 'Queued',
  Email: 'Sent',
  Downloaded: 'Downloaded',
  Disputing: 'Disputing',
  Credit: '',
  Payment: ''
}
const Wrapper = styled.div`
  width: 40px;
  margin-right: 8px; 
`
const BadgesWrapper = styled.div`
  display: flex;
  
`
const generateTextMoneyToolTip = (credits, payments) => {

  if (credits > 0 && payments > 0) {
    return `$${getMoney(credits)} credit,<br/>$${getMoney(payments)} paid`
  } else if (credits === 0 && payments > 0) {
    return `$${getMoney(payments)} paid`
  } else if (credits > 0 && payments === 0) {
    return `$${getMoney(credits)} credit`
  }
}

const renderIncomingMoneyBadge = (credits, payments) => {
  if (credits > 0 || payments > 0) {
    let text = generateTextMoneyToolTip(credits, payments)
    return (
      <React.Fragment>
        <Tooltip toolTipText={text}>
          <Wrapper>
            <Badge color="rgba(57, 146, 105, 0.2)">
              <Money />
            </Badge>
          </Wrapper>
        </Tooltip>
      </React.Fragment>
    )
  } else {
    return null
  }
}

const tableColumns = (hideLastAction) => {
  return [
    {
      id: 'Invoice_Number',
      label: '#',
      isSortable: false,
      headerClass: 'invoice-number-header',
      classNames: ['invoice-number-cell'],
      hideWhen: null,
      renderItem: (item) => {
        if (item.Invoice_Number) {
          return (
            <Typography element="p" weight="600">
              {item.Invoice_Number}
            </Typography>
          )
        }
      }
    },
    {
      id: 'badges',
      label: '',
      isSortable: false,
      headerClass: 'badges-header',
      classNames: ['invoice-icons-cell'],
      hideWhen: null,
      renderItem: (item) => {
        return (
          <BadgesWrapper>
            {renderIncomingMoneyBadge(item.Total_Credits, item.Total_Payments)}
            {item.Disputing && (
              <Wrapper>
                <Badge color="rgba(248, 192, 19, 0.2)">
                  <ExclamationMark />
                </Badge>
              </Wrapper>
            )}
          </BadgesWrapper>
        )
      }
    },
    {
      id: 'Invoice_Date',
      label: 'Date',
      isSortable: true,
      hideWhen: null,
      headerClass: ['invoice-date-header'],
      renderItem: (item) => {
        if (item.Invoice_Date) {
          return (
            moment(item.Invoice_Date).format('MMM D, YYYY')
          )
        }
      }
    },
    {
      id: 'status',
      label: 'Status',
      isSortable: false,
      hideWhen: null,
      headerClass: ['status-header'],
      renderItem: (item) => {
        return (
          <PrintBadge status={item.Status} />
        )
      }
    },
    {
      id: 'due',
      label: 'Due',
      isSortable: false,
      align: 'right',
      headerClass: 'due-header',
      classNames: ['due-col'],
      hideWhen: null,
      renderItem: (item) => {
        if (item.Amount_Due || item.Amount_Due === '0') {
          return (
            <Typography element="p">
              ${getMoney(item.Amount_Due)}
            </Typography>
          )
        }
      }
    },
    {
      id: 'last-action',
      label: 'Last action',
      isSortable: false,
      align: 'right',
      headerClass: 'last-action-header',
      classNames: ['options-cell'],
      hideWhen: hideLastAction,
      renderItem: (item) => {
        if (item.Last_Action) {
          return (
            <div className="last-action display-always">
              {item.Last_Action._id &&
                <React.Fragment>
                  <Typography element="span">
                    {lastActivity(item.Last_Action.Action_Date)}
                  </Typography>
                  <Typography element="span" color="#888888">
                    {lastActivityMethod[item.Last_Action.Action_Method]}
                  </Typography>
                </React.Fragment>
              }
            </div>
          )
        }

        return (
          <Typography element="span" color="#888888">
            No actions made yet
          </Typography>
        )
      }
    }
  ]
}

export default tableColumns