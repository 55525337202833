import React from 'react'
import ReactDOM from 'react-dom'
import RouteApp from './routeApp'
import AuthManager from './auth/AuthManager'
import { BrowserRouter as Router } from 'react-router-dom'
import { LoginApiRequest } from './auth/LoginApiRequest'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import './styles.scss'
import { Provider } from 'react-redux'
import store from './store-index'
import { ThemeProvider, theme } from 'cs-reusable-component-library-exporter'

ReactDOM.render((
  <LoginApiRequest>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthManager>
          <Router>
            <RouteApp />
          </Router>
        </AuthManager>
      </Provider>
    </ThemeProvider>
  </LoginApiRequest>
), document.getElementById('root'))