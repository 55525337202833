import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { FILTER_BY, STATUS, CUSTOMER } from '../../../../shared/constants/filters' 
import InvoicesByCustomerView from '../../../../components/searchResults/invoicesByCustomer/InvoicesByCustomerView'
import {
  setSelectAllInvoices,
  setSelectOneInvoice,
  toggleCustomEmailModal,
  setFilter,
  setInvoiceSelected
} from '../../../../actions/components/customer/invoices'
import { setDetailedInvoice } from '../../../../actions/components/searchResults/searchResults.actions'
import { handleDownloadDocument, handleSendEmail } from '../../../../actions/app.actions'
import { AuthConsumer } from '../../../../auth/AuthContext'

class InvoicesByCustomer extends Component {
  constructor(props) {
    super(props)

    this.handleSendEmail = this.handleSendEmail.bind(this)
    this.handleClickOnTitle = this.handleClickOnTitle.bind(this)
    this.calculateSelectedInvoices = this.calculateSelectedInvoices.bind(this)
    this.filterInvoicesByCustomer = this.filterInvoicesByCustomer.bind(this)    
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState || (prevState.currentUser !== nextProps.currentUser)) {
      return {
        currentUser: (nextProps.currentUser)
      }
    }
    return null
  }

  handleSendEmail(data) {
    if (typeof (this.props.currentUser.currentUser) === 'object') {
      this.props.handleSendEmail(this.props.currentUser.currentUser.data.user_id, data)
    }
  }

  handleClickOnTitle() {
    const { setFilter, history, selectedCustomer } = this.props

    setFilter(FILTER_BY.STATUS, STATUS.OPEN)
    history.push(`/customer/${selectedCustomer.Accounting_Code}/invoices`)
  }

  calculateSelectedInvoices() {   
    const { invoicesFound, filterValue } = this.props 
    
    if (filterValue) {
      return invoicesFound.filter(filteredInvoice => filteredInvoice.checked).length      
    }
  }

  filterInvoicesByCustomer(invoices, filterValue) {    
    if (filterValue) {
      return invoices.filter(invoice => invoice.Accounting_Code === filterValue)
    }
  }

  render() {
    return (
      <InvoicesByCustomerView 
        {...this.props}
        invoices={this.filterInvoicesByCustomer(this.props.invoicesFound, this.props.filterValue)}      
        handleSendEmail={this.handleSendEmail}
        handleClickOnTitle={this.handleClickOnTitle}
        calculateSelectedInvoices={this.calculateSelectedInvoices}
      />
    )
  }
}

const mapStateToProps = ({appReducer, invoicesReducer, contactsReducer, searchResultsReducer, customersReducer}) => {
  return {
    filterValue: invoicesReducer.filterValue,
    invoicesFound: invoicesReducer.invoices[CUSTOMER.FOUND].data,
    notFoundInvoices: invoicesReducer.invoices[CUSTOMER.NOT_FOUND].data,
    detailedInvoice: searchResultsReducer.detailedInvoice,
    selectedCustomer: appReducer.selectedCustomer,
    contacts: contactsReducer.contacts,
    isCustomEmailModalOpen: invoicesReducer.isCustomEmailModalOpen
  }
}

const mapDispatchToProps = {
  setSelectAllInvoices,
  setSelectOneInvoice,  
  setDetailedInvoice,
  handleDownloadDocument,
  handleSendEmail,
  toggleCustomEmailModal,
  setFilter,
  setInvoiceSelected
}

const InvoicesByCustomerWithUser = (props)=>(
  <AuthConsumer>
    {currentUser=>(
      <InvoicesByCustomer {...props} currentUser={currentUser}/>
    )}
  </AuthConsumer>
)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesByCustomerWithUser))



