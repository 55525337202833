import React from 'react'
import styled from 'styled-components'

const ShadowDiv = styled.div`
  width: ${props => props.width ? props.width : '10px'};
  height: ${props => props.height ? props.height : '10px'};
  border-radius: ${props => props.borderRadius ? props.borderRadius : '4px'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#e2e2e2' };
  opacity: ${props => props.opacity ? props.opacity : '0.5'};
  min-height: ${props => props.minHeight ? props.minHeight: props.height};
  min-width: 200px;
  margin-top: 24px;
`
const Shadow = ({width, height, backgroundColor, opacity, borderRadius}) => {
  return (
    <ShadowDiv
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    opacity={opacity}
    borderRadius={borderRadius}
    />         
  )
}



export default Shadow