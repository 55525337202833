import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActionsMarkup from './actionsView'
import { apiRequest } from './../../../../shared/apiRequest'

class ActionsHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filterSelected: 'all',
      labelSelected: 'All',
      actionsList: []
    }

    this.handleFilter = this.handleFilter.bind(this)
  }

  componentDidMount(){
    this.handleFilter()
  }

  componentDidUpdate(prevProps) {
    const { invoice, selectedTab } = this.props
  
    if ((prevProps.selectedTab === 'DETAILS' && selectedTab === 'ACTIVITY') || invoice.Invoice_Number !== prevProps.invoice.Invoice_Number) {      
      this.handleFilter()      
    }
  }

  async handleFilter(name = 'all', label = 'All') {
    try {
      const response = await apiRequest(
        'GET',
        `api/v1/actions/${this.props.invoice.Invoice_Number}/${name}`
      )

      this.setState({ 
        filterSelected: name, 
        labelSelected: label, 
        actionsList: response.data.map(action => action) 
      })

    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <ActionsMarkup
        handleFilter={this.handleFilter}
        labelSelected={this.state.labelSelected}
        actionsList={this.state.actionsList} 
      />
    )
  }
}

ActionsHistory.propTypes={
  invoice: PropTypes.object,
  selectedTab: PropTypes.string
}

export default ActionsHistory