import React from 'react'
import PropTypes from 'prop-types'
import Menu from '../../../shared/NewMenu'
import Typography from '../../../shared/Typography'
import ArrowDownward from '../../../../assets/svgs/ArrowDownward'
import ActionCard from './actionCard'

export const menuItems = (handleFilter) => (
  [
    {
      id: 1, text: 'All',
      onClick: () => handleFilter('all', 'All')
    },
    {
      id: 2, text: 'Credits & payments',
      onClick: () => handleFilter('payments', 'Credits & payments')
    },
    {
      id: 3, text: 'Invoice actions',
      onClick: () => handleFilter('actions', 'Invoice actions')
    }
  ]
)

export const ActionsView = ({ handleFilter, labelSelected, actionsList }) => {
  return (
    <div>
      <div className='actions-filter'>
        <Typography element='span'>SHOW</Typography><Menu options={menuItems(handleFilter)}>
          <span className='actions-dropdown'>
            {labelSelected} <ArrowDownward orientation='down' />
          </span>
        </Menu>
      </div>
      <div className='actions-history'>
        <div className='actions'>
          <ActionCard actionsList={actionsList} />
        </div>
      </div>
    </div>
  )
}

ActionsView.propTypes = {
  handleFilter: PropTypes.func,
  labelSelected: PropTypes.string,
  actionsList: PropTypes.array
}

export default React.memo(ActionsView)