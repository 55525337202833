import React, { Component } from 'react'
import TableRowView from './TableRowView'

class TableRow extends Component {

  render() {
    return (
      <TableRowView 
        {...this.props}
      />
    )
  }
}

export default TableRow